import { Route, Routes } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage";
import { LoginPage } from "./pages/LoginPage";
import { UserHomePage } from "./pages/user/UserHomePage";
import { AdminHomePage } from "./pages/admin/AdminHomePage";
import { IncasariHomePage } from "./pages/incasari/IncasariHomePage";
import { useEffect } from "react";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { NotFoundPage } from "./pages/NotFoundPage";
import { CustomErrorPage } from "./pages/Error500";
import CookieConsent from "react-cookie-consent";
import { ContactPage } from "./pages/ContactPage";
import { ClientiCasniciPage } from "./pages/ClientiCasniciPage";
import { ConsultareConsum } from "./pages/ConsultareConsum";
import { NoutatiPage } from "./pages/NoutatiPage";
import { NoutatePage } from "./pages/NoutatePage";
import { ClientiBusinessPage } from "./pages/ClientiBusinessPage";
import { ServiciiOnlinePage } from "./pages/ServiciiOnlinePage";
import { CookiesPage } from "./pages/CookiesPage";
import { ProtectiaDatelorPage } from "./pages/ProtectiaDatelorPage";
import { TermeniSiConditiiPage } from "./pages/TermeniSiConditiiPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { OfertaPage } from "./pages/OfertaPage";
import { OfertaPersoanaFizicaPage } from "./pages/OfertaPersoanaFizicaPage";
import { OfertaPersoanaJuridicaPage } from "./pages/OfertaPersoanaJuridicaPage";
import { IntrebariFrecventePage } from "./pages/IntrebariFrecventePage";
import { ReglementareTarifePage } from "./pages/ReglementareTarifePage";
import { ReluareFurnizariGazePage } from "./pages/ReluareFurnizariGazePage";
import { FacturaExplicataPage } from "./pages/FacturaExplicataPage";
import { ModalitatiDePlataPage } from "./pages/ModalitatiDePlataPage";
import { ContClientPage } from "./pages/ContClientPage";
import { ReclamatiiPage } from "./pages/ReclamatiiPage";
import { GazeNaturalePage } from "./pages/GazeNaturalePage";
import { DesprePage } from "./pages/DesprePage";
import { LegislatiePage } from "./pages/LegislatiePage";
import { CumSeFactureazaConsumulDeGazePage } from "./pages/CumSeFactureazaConsumulDeGazePage";
import { DreptulLaInformarePage } from "./pages/DreptulLaInformarePage";
import { DrepturiObligatiiClientFinalPage } from "./pages/DrepturiObligatiiClientFinalPage";
import { DrepturiObligatiiFurnizorPage } from "./pages/DrepturiObligatiiFurnizorPage";
import { SchimbareaFurnizoruluiPage } from "./pages/SchimbareaFurnizoruluiPage";
import { SolutionareaNeintelegilorPrecontractualePage } from "./pages/SolutionareaNeintelegilorPrecontractualePage";
import { SolutionareaPlangerilorPage } from "./pages/SolutionareaPlangerilorPage";

function App() {
  const removeFromLocalStorage = () => {
    localStorage.removeItem("selectedTab");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", removeFromLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", removeFromLocalStorage);
    };
  }, []);

  return (
    <div className="App">
      <div className="content">
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Folosim cookie-uri pentru a îmbunătăți experiența de navigare și
          pentru a analiza traficul pe site. Prin navigarea pe acest site, iti
          exprimi acordul asupra folosirii cookie-urilor. Pentru mai mule
          informații, consulta pagina cookies.
        </CookieConsent>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/clienti-casnici" element={<ClientiCasniciPage />} />
          <Route path="/clienti-business" element={<ClientiBusinessPage />} />
          <Route path="/servicii-online" element={<ServiciiOnlinePage />} />
          <Route path="/consultare-consum" element={<ConsultareConsum />} />
          <Route path="/politica-cookies" element={<CookiesPage />} />
          <Route path="/cont-client" element={<ContClientPage />} />
          <Route path="/reclamatii" element={<ReclamatiiPage />} />
          <Route
            path="/cum-se-factureaza-consumul-gaze-naturale"
            element={<CumSeFactureazaConsumulDeGazePage />}
          />
          <Route path="/gaze-naturale" element={<GazeNaturalePage />} />
          <Route
            path="/solutionarea-plangerilor"
            element={<SolutionareaPlangerilorPage />}
          />
          <Route path="/despre" element={<DesprePage />} />
          <Route path="/legislatie" element={<LegislatiePage />} />
          <Route path="/protectia-datelor" element={<ProtectiaDatelorPage />} />
          <Route
            path="/dreptul-la-informare"
            element={<DreptulLaInformarePage />}
          />
          <Route
            path="/drepturi-si-obligatii-client-final"
            element={<DrepturiObligatiiClientFinalPage />}
          />
          <Route
            path="/drepturi-si-obligatii-furnizor"
            element={<DrepturiObligatiiFurnizorPage />}
          />
          <Route
            path="/schimbarea-furnizorului"
            element={<SchimbareaFurnizoruluiPage />}
          />
          <Route
            path="/solutionarea-neintelegilor-precontractuale"
            element={<SolutionareaNeintelegilorPrecontractualePage />}
          />
          <Route path="/resetare-parola" element={<ForgotPasswordPage />} />
          <Route
            path="/reluare-furnizare-gaze"
            element={<ReluareFurnizariGazePage />}
          />
          <Route path="/factura-explicata" element={<FacturaExplicataPage />} />
          <Route
            path="/modalitati-de-plata"
            element={<ModalitatiDePlataPage />}
          />
          <Route
            path="/reglementare-tarife"
            element={<ReglementareTarifePage />}
          />
          <Route
            path="/intrebari-frecvente"
            element={<IntrebariFrecventePage />}
          />
          <Route path="/oferta" element={<OfertaPage />} />
          <Route
            path="/oferta/persoana-fizica"
            element={<OfertaPersoanaFizicaPage />}
          />
          <Route
            path="/oferta/persoana-juridica"
            element={<OfertaPersoanaJuridicaPage />}
          />
          <Route
            path="/termeni-si-conditii"
            element={<TermeniSiConditiiPage />}
          />
          <Route path="/noutati" element={<NoutatiPage />} />
          <Route path="/noutati/:id" element={<NoutatePage />} />
          <Route
            path="/admin/home"
            element={
              <ProtectedRoute roleName="ROLE_ADMIN">
                <AdminHomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/incasari/home"
            element={
              <ProtectedRoute roleName="ROLE_INCASARI">
                <IncasariHomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/home"
            element={
              <ProtectedRoute roleName="ROLE_USER">
                <UserHomePage />
              </ProtectedRoute>
            }
          />
          <Route path="/error500" element={<CustomErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
