import { Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import legal from "../assets/legal.jpg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const InformatiiGenerale: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "85%",
          margin: "0 auto",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          mb: "10px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Informații generale
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px" }}
            >
              Am nevoie de informații suplimentare. Cum pot lua legătură cu voi?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Dacă ai nevoie de informații suplimentare, poți lua legătura cu
              noi:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                component={"span"}
              >
                la numărul de telefon{" "}
                <span
                  onClick={() => (window.location.href = "tel:+40374627726")}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  0374 627 726
                </span>
                , (apel taxabil, conform tarifelor stabilite de operatorii
                naționali de telefonie)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", whiteSpace: "nowrap", mb: "15px" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Vizitează-ne la sediul central sau la punctul de lucru alocat
                zonei tale.
              </Typography>
            </Box>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Care este termenul de plată al facturii de gaz?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Termenul de plată al facturii de gaz este de 30 de zile de la data
              emiterii acesteia.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Ce înseamnă cod client și CLC?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component={"div"}
              fontWeight={"bold"}
              display={"flex"}
              whiteSpace={"nowrap"}
            >
              Cod client
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ ml: "5px" }}
              >
                - Conține 3 sau 4 cifre și este un cod unic de identificare
                alocat fiecărui client.
              </Typography>
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component={"div"}
              fontWeight={"bold"}
              display={"flex"}
              whiteSpace={"nowrap"}
              sx={{ mb: "15px" }}
            >
              Cod Loc Consum (CLC)
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ ml: "5px" }}
              >
                - este un cod unic alocat pentru fiecare adresă la care ai un
                contract de furnizare gaze naturale.
              </Typography>
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Cum actualizez datele personale?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Dacă ți-ai schimbat adresa de corespondență, numărul de telefon,
              adresa de e-mail sau alte date relevante, te rugăm să urmezi pașii
              de aici, iar noi îți vom actualiza datele personale în maxim 48 de
              ore.
            </Typography>
          </>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={legal}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
