import axios from "axios";
import { AnuntEntity } from "../types";

const URL = "https://secure.euro7.ro:8443/api/anunturi";

export abstract class AnuntService {
  public static async findById(id: number): Promise<AnuntEntity> {
    return new Promise((resolve) => {
      axios.get(URL + "/" + id).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async getRecent(): Promise<AnuntEntity[]> {
    return new Promise((resolve) => {
      axios.get(URL + "/recent").then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async createAnunt(anunt: AnuntEntity): Promise<AnuntEntity> {
    return new Promise((resolve) => {
      axios.post(URL + "/create", anunt).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async deleteAnunt(id: number): Promise<void> {
    return new Promise((resolve) => {
      axios.post(URL + "/delete/" + id).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async getAll(): Promise<AnuntEntity[]> {
    return new Promise((resolve) => {
      axios.get(URL).then((response) => {
        resolve(response.data);
      });
    });
  }
}
