import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";

export const LandingCard = (props: {
  image: string;
  title: string;
  body: string;
  buttonText: string;
  location: string;
}) => {
  const { image, title, body, buttonText, location } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "relative",
        width: "455.5px",
        height: "227.75px",
        "@media (max-width: 1000px)": {
          mr: "unset",
        },
        "@media (max-width: 1600px)": {
          width: "318px",
        },
        "@media (max-width: 1450px)": {
          width: "275px",
        },
      }}
    >
      <Box
        sx={{
          height: "227.75px",
          width: "318.85px",
          "@media (max-width: 1600px)": {
            width: "275px",
          },
          "@media (max-width: 1450px)": {
            width: "200px",
          },
        }}
      >
        <img
          src={image}
          alt="card1"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "318.85px",
          height: "200px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          position: "absolute",
          top: "50%" /* Place the element vertically in the middle */,
          left: {
            xs: "calc(10% + 20px)",
            sm: "15%",
            md: "15%",
            lg: "20%",
            xl: "30%",
          },
          transform: "translateY(-50%)" /* Adjust for vertical alignment */,
          textAlign: "left",
          borderRadius: "10px",
          "@media (max-width: 500px)": {
            left: "15%",
          },
          "@media (max-width: 1600px)": {
            width: "275px",
          },
          "@media (max-width: 1150px)": {
            width: "250px",
          },
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            fontFamily: "Catesque",
            color: "#0054a6",
            padding: "25px 25px 0px 25px",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{
            fontFamily: "Catesque",
            padding: "25px",
          }}
        >
          {body}
        </Typography>
        <Box sx={{ pl: "25px", pb: "5px" }}>
          <Button
            variant="contained"
            onClick={() => navigateToPage(navigate, location)}
            sx={{
              margin: "0 auto",
              backgroundColor: "#0054a6",
              justifyContent: "flex-start",
              position: "relative",
              fontFamily: "Catesque",
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
