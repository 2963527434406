import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import Legislatie from "../assets/Legislatie.jpg";
import CheckBox from "@mui/icons-material/CheckBox";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const LegislatiePage: React.FC = () => {
  const handlePdfClick = (event: any, path: string) => {
    event?.preventDefault();
    window.open(`${process.env.PUBLIC_URL}/documente/${path}`, "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Legislatie}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Legislație" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "3300px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "50px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "45px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Legislație Primară
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              1. LEGEA ENERGIEI ELECTRICE SI A GAZELOR NATURALE NR. 123 DIN 2012
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              2. LEGEA NR 99 DIN 2016 PRIVIND ACHIZIȚIILE SECTOIALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              3. LEGEA NR. 121 DIN 2014 PRIVIND EFICIENTA ENERGETICA
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              4. LEGEA NR. 193 DIN 2000 PRIVIND CLAUZELE ABUZIVE DIN CONTRACTELE
              INCHEIATE INTRE PROFESIONISTI SI CONSUMATORI
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              5. LEGEA NR. 226 DIN 2021 PRIVIND STABILIREA MASURILOR DE
              PROTECTIE SOCIALA PENTRU CONSUMATORUL VULNERABIL DE ENERGIE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              6. H.G. 638/2007 PRIVIND DESCHIDEREA INTEGRALA A PIETEI DE ENERGIE
              ELECTRICA SI GAZE NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              7. H.G. 1073/2021 PENTRU APROBAREA NORMELOR METODOLOGICE DE
              APLICARE A LEGII NR. 226 DIN 2021 PRIVIND CONSUMATORUL VULNERABIL
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              8. O.U.G. 2/2001 PRIVIND REGIMUL JURIDIC AL CONTRAVENTIILOR
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              9. O.G. 3/2022 PENTRU MODIFICAREA SI COMPLETAREA ORDONANTEI
              GUVERNULUI NR. 118 DIN 2021
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              10. O.G. 27/2022 PRIVIND MASURILE APLICABILE CLIENTILOR FINALI DIN
              PIATA DE ENERGIE IN PERIOADA 1 APRILIE 2022 -31 MARTIE 2023
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              11. O.U.G. 118/2021 PRIVIND STABILIREA UNEI SCHEME DE COMPENSARE
              PENTRU CONSUMUL DE ENERGIE ELECTRICA ȘI GAZE NATURALE
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "45px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Legislație Secundară
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              1. ORDIN ANRE NR. 142 DIN 2021 PENTRU APROBAREA PROCEDURII DE
              REZOLVARE A RECLAMATIILOR-SESIZARILOR-DIVERGENTELOR PRIVIND
              MASURAREA CANTITATILOR DE G. N.
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              2. ORDIN ANRE NR. 179 DIN 2015 PENTRU APROBAREA PROCEDURII PRIVIND
              VERIFICARILE SI REVIZIILE TEHNICE ALE INSTALATIILOR DE UTILIZARE A
              GAZELOR NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              3. ORDIN ANRE NR. 3 DIN 2022 PENTRU APROBAREA REGULAMENTULUI
              PRIVIND ORGANIZAREA SI FUNCTIONAREA PLATFORMEI ONLINE DE
              SCHIMBAREA A FURNIZORULUI
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              4. ORDIN ANRE NR. 5 DIN 2013 PENTRU APROBAREA METODOLOGIEI DE
              MONITORIZARE A PIETEI GAZELOR NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              5. ORDIN ANRE NR. 16 DIN 2020 PENTRU APROBAREA METODOLOGIEI DE
              REGULARIZARE A DIFERENTELOR DINTRE ALOCARI SI CANTITATILE DE GAZE
              DISTRIBUITE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              6. ORDIN ANRE NR. 21 DIN 2020 PENTRU APROBAREA REGULAMENTULUI
              PRIVIND SEPARAREA CONTABILA A ACTIVITATILOR DESFASURATE DE CATRE
              TITULARII DE LICENTE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              7. ORDIN ANRE NR. 54 DIN 2014 PRIVIND UNELE MASURI PENTRU
              DEZVOLTAREA PIETII GAZELOR NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              8. ORDIN ANRE NR. 128 DIN 2020 PENTRU APROBAREA PROCEDURII PRIVIND
              SOLUTIONAREA NEINTELEGERILOR APARUTE LA INCHEIEREA CONTRACTELOR IN
              SECTORUL ENERGIEI
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              9. ORDIN ANRE NR. 90 DIN 2018 PRIVIND APROBAREA CONDITIILOR-CADRU
              DE VALABILITATE ASOCIATE LICENTEI PENTRU ACTIVITATEA DE FURNIZARE
              DE GNL
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              10. ORDIN ANRE NR. 105 DIN 2018 PENTRU APROBAREA REGULILOR
              GENERALE PRIVIND PIETELE CENTRALIZATE DE GAZE NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              11. ORDIN ANRE NR. 106 DIN 2014 PRIVIND MODALITATILE DE INFORMARE
              A CLIENTILOR FINALI DE CATRE FURNIZORII DE GAZE NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              12. ORDIN ANRE NR. 117 DIN 2008 PENTRU APROBAREA METODOLOGIEI
              PRIVIND RAPORTAREA INFORMATIILOR REFERITOARE LA CONSUMATORII
              INDUSTRIALI DE GAZE NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              13. ORDIN ANRE NR. 173 DIN 2020 PENTRU APROBAREA REGULAMENTULUI
              PRIVIND FURNIZAREA DE ULTIMA INSTANTA A GAZELOR NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              14. ORDIN ANRE NR. 221 DIN 2018 PRIVIND OBLIGATIA PARTICIPANTILOR
              LA PIATA DE GAZE NATURALE DE A TRANZACTIONA PE PIETELE
              CENTRALIZATE DIN ROMANIA
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              15. ORDIN ANRE NR. 1 DIN 2015 PRIVIND INSTITUIREA REGISTRULUI
              NATIONAL AL PARTICIPANTILOR LA PIATA ANGRO DE ENERGIE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              16. ORDIN ANRE NR. 29 DIN 2016 PENTRU APROBAREA REGULAMENTULUI
              PRIVIND FURNIZAREA GAZELOR NATURALE LA CLIENTII FINALI
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              17. ORDIN ANRE NR. 40 DIN 2019 PENTRU APROBAREA CONTRACTULUI-CADRU
              DE VANZARE-CUMPARARE A GAZELOR NATURALE INCHEIATE INTRE
              PRODUCATORI SI FURNIZORI
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              18. ORDIN ANRE NR. 64 DIN 2018 PRIVIND APROBAREA CONDITIILOR-CADRU
              DE VALABILITATE ASOCIATE LICENTEI PENTRU ACTIVITATEA DE FURNIZARE
              DE GAZE NATURALE
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              19. ORDIN ANRE NR. 83 DIN 2021 PRIVIND APROBAREA STANDARDULUI DE
              PERFORMANTA PENTRU ACTIVITATEA DE FURNIZARE A ENERGIEI ELECTRICE
              SI GAZELOR NATURALE
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "45px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Documente Utile
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/oferta.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "oferta.pdf")}
            >
              Oferta furnizare gaze naturale consumatorii casnici 01.09.2023
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Ordinul-ANRE-nr-16-2015.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "Ordinul-ANRE-nr-16-2015.pdf")}
            >
              ORDINUL nr. 16 din 18 martie 2015 a Procedurii-cadru privind
              obligaţia furnizorilor
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/RAPORT SOLUTIONARE PLANGERI AN 2022.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "RAPORT SOLUTIONARE PLANGERI AN 2022.pdf")
              }
            >
              RAPORT SOLUTIONARE PLANGERI SEMESTRUL I 2022
            </Typography>
          </Box>
          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Notificare-noncasnici-01-01-2023.pdf`} // Adjust the document link accordingly
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "Notificare-noncasnici-01-01-2023.pdf")
              }
            >
              Notificare majorare noncasnici preț GN începând cu 01.01.2023
            </Typography>
          </Box>

          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/notificare-casnici-01-10-2023.pdf`} // Adjust the document link accordingly
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "notificare-casnici-01-10-2023.pdf")
              }
            >
              Notificare casnici modificare preț GN începând cu 01.10.2023
            </Typography>
          </Box>

          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/informare-citire-index.pdf`} // Adjust the document link accordingly
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "informare-citire-index.pdf")}
            >
              Informare citire lunară grupuri de măsurare locuri de consum
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Raport privind indicatorii de performanta pentrul serviciul de furnizare anul 2021.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(
                  e,
                  "Raport privind indicatorii de performanta pentrul serviciul de furnizare anul 2021.pdf"
                )
              }
            >
              Raportul anual pe 2021 cu privire la nivelul de realizare al
              indicatorilor de performanta pe gaze naturale
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Raport-solutionare-plangeri-sem II-2022.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "Raport-solutionare-plangeri-sem II-2022.pdf")
              }
            >
              RAPORT SOLUTIONARE PLANGERI SEMESTRUL II 2022
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/ORDINUL ANRE NR 7_2022 REGULAMENTUL  DE RACORDARE LA SISTEMUL DE DISTRIBUTIE.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(
                  e,
                  "ORDINUL ANRE NR 7_2022 REGULAMENTUL  DE RACORDARE LA SISTEMUL DE DISTRIBUTIE.pdf"
                )
              }
            >
              ORDIN Nr. 7 pentru aprobarea Regulamentului privind racordarea la
              sistemul de distribuţie a gazelor naturale
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/GHID EXPLICATIV FACTURA.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "GHID EXPLICATIV FACTURA.pdf")}
            >
              GHID EXPLICATIV FACTURA
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/RAPORT SOLUTIONARE PLANGERI AN 2022.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "RAPORT SOLUTIONARE PLANGERI AN 2022.pdf")
              }
            >
              RAPORT SOLUTIONARE PLANGERI AN 2022
            </Typography>
          </Box>

          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/contract-furnizare-noncasnici.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "contract-furnizare-noncasnici.pdf")
              }
            >
              Contract furnizare pentru furnizarea gazelor naturale la clienții
              noncasnicii
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/RAPORT PRIVIND ACVTIVITATEA DE INFORMARE A CLIENTILOR FINALI PENTRU ANUL 2022.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(
                  e,
                  "RAPORT PRIVIND ACVTIVITATEA DE INFORMARE A CLIENTILOR FINALI PENTRU ANUL 2022.pdf"
                )
              }
            >
              RAPORT PRIVIND ACVTIVITATEA DE INFORMARE A CLIENTILOR FINALI
              PENTRU ANUL 2022
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/REGISTRU CU SOLICITARI CONFORM ORDIN 18 din 2021.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(
                  e,
                  "REGISTRU CU SOLICITARI CONFORM ORDIN 18 din 2021.pdf"
                )
              }
            >
              REGISTRU CU SOLICITARI CONFORM ORDIN 18/2021
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Profile de consum OD.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "Profile de consum OD.pdf")}
            >
              Profile de consum OD
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Putere calorifica zilnica.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "Putere calorifica zilnica.pdf")
              }
            >
              Putere calorifica zilnica
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Putere calorifica lunara.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handlePdfClick(e, "Putere calorifica lunara.pdf")}
            >
              Putere calorifica lunara
            </Typography>
          </Box>
          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/contract-furnizare-casnici.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "contract-furnizare-casnici.pdf")
              }
            >
              Contract furnizare pentru furnizarea gazelor naturale la clienții
              casnicii 01.10.2023
            </Typography>
          </Box>

          {/* ! */}
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/tarife-reglementate-01-04-2023.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "tarife-reglementate-01-04-2023.pdf")
              }
            >
              Tarife reglementate pentru prestarea serviciului de distribuție
              pentru furnizarea reglementată a gazelor naturale de la 01.04.2023
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/Tarife si servicii conexe.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(e, "Tarife si servicii conexe.pdf")
              }
            >
              Tarife si servicii conexe
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"a"}
              href={`${process.env.PUBLIC_URL}/documente/raport-activitate-finala.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handlePdfClick(
                  e,
                  "raport-activitate-finala.pdf"
                )
              }
            >
              RAPORT PRIVIND ACTIVITATEA DE INFORMARE A CLIENTILOR FINALI PENTRU
              ANUL 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
