import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { navigateToPage } from "../../utils";
export function CardReview(props: {
  image: string;
  title: string;
  text: string;
  location: string;
}) {
  const { image, title, text } = props;
  const navigate = useNavigate();
  return (
    <Card
      elevation={0}
      sx={{
        overflow: "initial",
        width: 304,
        backgroundColor: "transparent",
      }}
    >
      <CardMedia
        image={image}
        sx={{
          width: "100%",
          height: 0,
          paddingBottom: "56.25%",
          backgroundColor: "rgba(0 0 0 / 0.08)",
        }}
      />
      <CardContent
        sx={{
          boxShadow:
            "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
          position: "relative",
          padding: 3,
          margin: "-24% 16px 0",
          backgroundColor: "#fff",
          borderRadius: "4px",
          textAlign: "left",
          height: "250px",
        }}
      >
        <Box
          component="h3"
          sx={{
            mb: 3,
            fontWeight: "bold",
            fontSize: "1.35824rem",
            lineHeight: 1.45,
            color: "#0054a6",
            fontFamily: "Catesque",
          }}
        >
          {title}
        </Box>
        <Typography color={"textSecondary"} variant={"body2"}>
          {text}
        </Typography>
        {title === "Informații Utile" ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Button
                  sx={{ fontFamily: "Catesque" }}
                  onClick={() => navigateToPage(navigate, "/clienti-casnici")}
                >
                  Clienti Casnici
                </Button>
                <Button
                  sx={{ fontFamily: "Catesque" }}
                  onClick={() => navigateToPage(navigate, "/clienti-business")}
                >
                  Clienti Business
                </Button>
              </React.Fragment>
            }
          >
            <Button
              sx={{
                display: "block",
                position: "absolute",
                bottom: 5,
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "center",
                fontFamily: "Catesque",
                color: "#0054a6",
                letterSpacing: "2px",
                ":hover": {
                  cursor: "pointer",
                },
                fontWeight: 200,
                fontSize: 12,
              }}
            >
              Vezi
            </Button>
          </HtmlTooltip>
        ) : (
          <Typography
            variant={"overline"}
            onClick={() => navigateToPage(navigate, props.location)}
            sx={{
              display: "block",
              position: "absolute",
              bottom: 5,
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              fontFamily: "Catesque",
              color: "#0054a6",
              letterSpacing: "2px",
              ":hover": {
                cursor: "pointer",
              },
              fontWeight: 200,
              fontSize: 12,
            }}
          >
            Vezi
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
