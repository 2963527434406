import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import CheckBox from "@mui/icons-material/CheckBox";
import dreptulLaInformare from "../assets/dreptulLaInformare.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const DreptulLaInformarePage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={dreptulLaInformare}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Dreptul La Informare" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1300px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            În calitate de client al gazelor naturale, ai dreptul de a cere și
            de a primi gratuit, înainte de semnarea contractului de furnizare, o
            copie a acestuia și a condițiilor de furnizare a serviciului.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Înainte de semnarea contractului de furnizare, suntem obligați să
            îți punem la dispoziție, următoarele informații:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Etapele și documentele necesare pentru încheierea unui contract de
              furnizare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Ofertele pe care le poți alege
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Contractul corespunzător ofertei selectate
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Prețurile și tarifele practicate
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Înainte de a semna contractul primit, te rugăm să verifici dacă sunt
            incluse următoarele informații:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Identitatea și adresa noastră în calitate de furnizor;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Descrierea serviciului furnizat, nivelurile de calitate ale
              serviciului oferit și continuitatea în alimentare, precum și data
              de începere a derulării contractului;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Prețul/tariful aplicabil;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Durata contractului, condițiile de reînnoire/prelungire a acestuia
              și de întrerupere temporară a alimentării cu gaze naturale,
              dreptul de denunțare unilaterală a contractului;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Compensațiile/despăgubirile și modalitatea de rambursare
              aplicabilă în caz de nerespectare a nivelurilor de calitate a
              serviciului prevăzut prin contract
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Modalitățile de inițiere a procedurilor de soluționare a
              litigiilor și informații privind procedura de soluționare a
              plângerilor clienților finali;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Penalitățile pe care trebuie să le plătești în cazul nerespectării
              prevederilor contractuale.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            În calitate de furnizor, suntem obligați să te notificăm cu privire
            la orice schimbări ale prețurilor, modalităților de facturare, de
            încasare sau a altor condiții din contract, într-un mod direct și în
            timp util, într-o manieră transparentă și ușor de înțeles.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            Dacă nu ești de acord cu modificările anunțate, ai dreptul de a
            renunța la contract, respectând prevederile contractuale și legale
            în vigoare.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            În situația în care dreptul tău de a fi informat este încălcat, te
            poți adresa Autorității Naționale de Reglementare în Domeniul
            Energiei.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
