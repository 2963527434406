import { Title } from "../components/Title";
import { Box, Button, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import card1 from "../assets/card1.jpeg";
import contclient from "../assets/cont-client.jpg";
import CheckBox from "@mui/icons-material/CheckBox";
import card2 from "../assets/card2.jpg";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";
import { JumbotronTitle } from "../components/JumbotronTitle";
export const ContClientPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={contclient}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Cont Client" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1200px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box sx={{ width: "100%", height: `calc(40%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={card1}
              height={"250px"}
              width={"380px"}
              alt={"tarife1"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "9px",
                    height: "100px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Creează-ți cont online pentru acces rapid la facturi, istoric
                  și plăți online oriunde.
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Dacă vrei să te înregistrezi ai nevoie de:
              </Typography>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Nume și Prenume
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Email
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Detalii Loc Consum
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(40%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "9px",
                    height: "100px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Urmează pașii de mai jos pentru a te înregistra în mai puțin
                  de câteva minute:
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Apasă butonul “Înregistrare”.
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Alege tipul de client (casnic sau business).
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Completează formularul de înregistrare.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: "20px" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  După ce accepți termenii și condițiile, un administrator va
                  verifica formularul si veți primi un email cu datele de
                  logare.
                </Typography>
              </Box>
              <Button
                onClick={() => navigateToPage(navigate, "/login")}
                sx={{
                  width: "40%",
                  background: "#0054a6",
                  color: "white",
                  fontFamily: "Catesque",
                }}
              >
                Intră în Cont{" "}
              </Button>
            </Box>
            <Box
              component={"img"}
              src={card2}
              height={"250px"}
              width={"380px"}
              alt={"tarife2"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(20%)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              height: "125px",
              background: "#dee2e6",
              margin: "0 auto",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "left",
              padding: "25px",
              borderRadius: "25px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              După ce te înregistrezi și ești acceptat, pentru a afla
              informațiile de care ai nevoie, te rugăm schimbă-ți parola și
              completează datele personale. În acest fel, vei avea acces la
              toate facilitățile contului tău.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
