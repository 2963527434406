import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import dreptFurnizor from "../assets/dreptFurnizor.jpg";

export const DrepturiObligatiiFurnizorPage: React.FC = () => {
  // const handlePdfClick = (event: any) => {
  //   event?.preventDefault();
  //   window.open(
  //     `${process.env.PUBLIC_URL}/Drepturi și obligații ale clientului final de gaze naturale.pdf`,
  //     "_blank"
  //   );
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={dreptFurnizor}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "35%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Drepturi și Obligațiile Furnizorului
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1200px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Principalele drepturi ale furnizorului tău de gaze naturale sunt
              următoarele:
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Vom încasa contravaloarea consumului de gaze naturale conform
            condițiilor stabilite în contractul de furnizare încheiat cu tine.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            În cazul furnizării de gaze naturale în regim de ultimă instanță
            (FUI) la locul de consum, contravaloarea acestora va fi încasată
            conform reglementărilor aplicabile.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            În situația întârzierii la plată a facturii, putem să percepem
            dobânzi penalizatoare.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Vom examina cererea ta și vom decide dacă este oportun să îți oferim
            o alternativă de plată a facturii în cazul în care întâmpini
            dificultăți financiare. De asemenea, putem negocia un plan de
            eșalonare a plății (plata în rate) a sumelor pe care le datorezi.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            În situația neplății, avem dreptul să solicităm operatorului
            (distribuitorului) să întrerupă/limiteze/reia alimentarea cu gaze
            naturale la locul de consum conform prevederilor contractului de
            furnizare și ale legislației în vigoare.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Vom încasa de la tine tariful perceput de distribuitor (operator)
            pentru reluarea alimentării cu gaze naturale la locul de consum.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Principalele obligații ale furnizorului tău de gaze naturale sunt
              următoarele:
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Este necesar să publicăm pe pagina noastră de internet informații
            actualizate despre contract, condițiile generale de furnizare a
            gazelor naturale, precum și prețurile/tarifele practicate și
            condițiile generale de acces și utilizare a serviciilor oferite.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Vom desfășura activitatea de furnizare a gazelor naturale pe baza
            contractelor de furnizare încheiate cu tine, în care vor fi
            stabilite condiții/clauze contractuale echitabile și transparente,
            respectând cerințele minime contractuale prevăzute de reglementările
            în vigoare.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Ne vom asigura că preluăm datele de consum înregistrate de
            contorul/contorurile de la locul/locurile de consum menționate în
            contractul de furnizare a gazelor naturale de la distribuitorul
            (operatorul) corespunzător. Vom factura contravaloarea consumului de
            gaze naturale furnizate la locul de consum în regim de ultimă
            instanță conform reglementărilor în vigoare.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Detalii privind drepturile și obligațiile furnizorului tău de gaze
            naturale, găsești în Ord.29/2016.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
