import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import solutionarea from "../assets/solutionarea.jpg";

export const SolutionareaNeintelegilorPrecontractualePage: React.FC = () => {
  // const handlePdfClick = (event: any) => {
  //   event?.preventDefault();
  //   window.open(
  //     `${process.env.PUBLIC_URL}/Modalități de soluționare a disputelor apărute pe parcursul derulării contractului.pdf`,
  //     "_blank"
  //   );
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={solutionarea}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "35%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Soluționarea Neînțelegerilor Precontractuale
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1100px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Modalități de soluționare a neînțelegerilor precontractuale
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Autoritatea Națională de Reglementare în Domeniul Energiei (ANRE)
            oferă asistență în rezolvarea neînțelegerile precontractuale din
            sectorul gazelor naturale.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            ANRE a dezvoltat o procedură specializată menită să faciliteze
            soluționarea neînțelegerilor apărute în procesul de încheiere a
            contractelor în domeniul energiei.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Procesul de soluționare a neînțelegerilor implică următoarele etape:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", ml: "25px" }}>
            <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
              a) Inițial, soluționarea la nivelul titularului de licență care
              furnizează oferta de contract.
            </Typography>
            <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
              b) În cazul în care disputa nu poate fi rezolvată la acest nivel,
              se apelează la intervenția Autorității competente, respectiv ANRE.
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Pentru mai multe informații privind modalitățile de soluționare a
            neînțelegerilor precontractuale, puteți consulta Ordinul nr.
            128/2020.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Modalități de soluționare a disputelor apărute pe parcursul
              derulării contractului
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            Pentru a soluționa disputele ce pot apărea pe parcursul derulării
            contractelor în domeniul energiei, Autoritatea Națională de
            Reglementare în Domeniul Energiei (ANRE) oferă asistență în
            rezolvarea acestor conflicte, atât în sectorul angro (furnizori,
            distribuitori) cât și în sectorul cu amănuntul (clienți casnici).
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            În acest scop, a fost elaborat un regulament care are ca obiectiv
            stabilirea unei proceduri de rezolvare a disputelor ce pot surveni
            în cursul derulării unui contract în domeniul energiei.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            Procesul de soluționare a disputelor implică următoarele etape:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", ml: "25px" }}>
            <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
              a) Concilierea la nivelul părților implicate în dispută, într-un
              efort de a ajunge la un acord amiabil.
            </Typography>
            <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
              b) În cazul în care concilierea nu este posibilă sau nu duce la o
              rezolvare satisfăcătoare, disputa poate fi adusă la nivelul ANRE
              pentru soluționare.
            </Typography>
          </Box>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            color={"black"}
            component={"span"}
          >
            Pentru mai multe informații referitoare la modalitățile de
            soluționare a disputelor apărute pe parcursul derulării
            contractului, puteți consulta Ordinul nr. 61/2013 sau{" "}
            <span
              onClick={() =>
                (window.location.href = `${process.env.PUBLIC_URL}/Modalități de soluționare a disputelor apărute pe parcursul derulării contractului.pdf`)
              }
              rel="noopener noreferrer"
              style={{
                color: "#0054a6",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              aici.
            </span>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
