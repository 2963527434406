import { useEffect, useState } from "react";
import { SoldEntity } from "../../types";
import { DataGrid, GridColDef, roRO } from "@mui/x-data-grid";
import moment from "moment";
import { Box, Button, Typography } from "@mui/material";
import { reverseArray } from "../../utils";
import { UserService } from "../../services/UserService";
import { ModalAddSold } from "../../components/incasari/Modals/ModalAddSold";
import { AxiosResponse } from "axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const IncasariSolduri: React.FC = () => {
  const [solduri, setSolduri] = useState<SoldEntity[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const createBlob = (
    axiosResponse: AxiosResponse<any, any>,
    fileName: string
  ) => {
    const blobData = axiosResponse.data;
    // Create a blob URL
    const blob = new Blob([blobData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    // Use a temporary <a> element to initiate the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`); // Name the download file as 'users.xlsx'
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExport = async () => {
    if (solduri !== undefined) {
      try {
        const axiosResponse = await UserService.exportEvidentaSolduri();
        createBlob(axiosResponse, "solduri_evidenta");
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 70,
    },
    {
      field: "suma",
      headerName: "Suma",
      width: 100,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return params.row.suma.toFixed(2);
      },
    },
    {
      field: "codClient",
      headerName: "Cod Client",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 100,
    },
    {
      field: "data",
      headerName: "Dată",
      type: "dateTime",
      align: "left",
      headerAlign: "left",
      width: 150,
      valueGetter: (params) => {
        return moment(params.value, "YYYY-MM-DD HH:mm:ss").toDate();
      },
      renderCell: (params) => {
        return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
      },
    },
    {
      field: "location",
      headerName: "Serie",
      width: 90,
    },
    {
      field: "nrFactura",
      headerName: "Nr. Factura",
      width: 90,
    },
    {
      field: "metoda",
      headerName: "Metoda",
      width: 125,
    },
  ];

  const fetchSolduri = async () => {
    await UserService.getSolduri()
      .then((res) => {
        setSolduri(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSolduri();
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          height: "8vh",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "25px",
        }}
      >
        <Typography
          fontFamily={"Catesque"}
          fontWeight={"bold"}
          fontSize={"32px"}
        >
          Evidenta Solduri
        </Typography>

        <Box sx={{ display: "flex", gap: "20px", width: "20%" }}>
          <Button
            onClick={() => setOpenModal(true)}
            variant={"contained"}
            sx={{
              backgroundColor: "#0054a6",
              fontFamily: "Catesque",
              height: "30%",
            }}
          >
            Adauga Sold
          </Button>
          <FileDownloadIcon
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              padding: "25px",
              transform: "translateY(-50%)",
              color: "#0054a6",
              height: "30px",
              width: "30px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              if (solduri !== undefined) {
                handleExport();
              }
            }}
          />
        </Box>
      </Box>
      <ModalAddSold openModal={openModal} handleCloseModal={handleCloseModal} />
      <Box sx={{ mb: "10vh" }}>
        <Box
          sx={{
            overflowX: "hidden",
            boxShadow: "0 0 16px -8px black",
            borderRadius: "25px",
          }}
        >
          <DataGrid
            rows={reverseArray(solduri)}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  accepted: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              fontFamily: "Catesque",
              backgroundColor: "transparent",
              borderRadius: "25px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E4E5E6",
              },
              "& .MuiDataGrid-columnsContainer": {
                marginLeft: "16px", // Adjust the value as needed
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
