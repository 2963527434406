import { Box, Button, Typography } from "@mui/material";
import { Title } from "../components/Title";
import j5 from "../assets/j5.jpeg";
import SendIcon from "@mui/icons-material/Send";
import { Footer } from "../components/Footer";
import logo2 from "../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";
export const OfertaPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={j5}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            flexShrink: 0,
            zIndex: 1,
          }}
          draggable="false"
          unselectable="on"
        />

        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            padding: "10px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigateToPage(navigate, "/")}
        >
          <img
            height="100px"
            src={logo2}
            alt="logo2"
            className="overlay-logo"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            zIndex: 9999,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Solicită o ofertă personalizată
          </Typography>
          <Typography
            variant="h3"
            component={"h3"}
            fontFamily={"Catesque"}
            color="white"
          >
            În calitate de:{" "}
          </Typography>
          <Box
            sx={{
              width: "800px",
              height: "200px",
              background: "#f2f2f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "25px",
              borderRadius: "25px",
            }}
          >
            <Button
              variant="contained"
              endIcon={
                <SendIcon
                  sx={{ position: "absolute", top: "32%", right: 20 }}
                />
              }
              onClick={() =>
                navigateToPage(navigate, "/oferta/persoana-fizica")
              }
              sx={{
                background: "#0054a6",
                height: "55px",
                width: "250px",
                justifyContent: "flex-start",
                position: "relative",
                fontFamily: "Catesque",
              }}
            >
              Persoană Fizică
            </Button>
            <Button
              variant="contained"
              endIcon={
                <SendIcon
                  sx={{ position: "absolute", top: "32%", right: 20 }}
                />
              }
              onClick={() =>
                navigateToPage(navigate, "/oferta/persoana-juridica")
              }
              sx={{
                background: "#0054a6",
                height: "55px",
                width: "250px",
                justifyContent: "flex-start",
                position: "relative",
                fontFamily: "Catesque",
              }}
            >
              Persoană Juridică
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
