import { Title } from "../components/Title";
import { Box } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import facturaExplicata from "../assets/factura-explicata.jpg";
import factura1 from "../assets/factura1.jpg";
import factura2 from "../assets/factura2.jpg";
import factura3 from "../assets/factura3.jpg";
import factura4 from "../assets/factura4.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const FacturaExplicataPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={facturaExplicata}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Factura Ta Explicată" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1300px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            component={"img"}
            src={factura1}
            alt={"factura1"}
            sx={{ width: "50%", height: "100%" }}
          />
          <Box
            component={"img"}
            src={factura2}
            alt={"factura2"}
            sx={{ width: "50%", height: "100%" }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            component={"img"}
            src={factura3}
            alt={"factura3"}
            sx={{ width: "50%", height: "100%" }}
          />
          <Box
            component={"img"}
            src={factura4}
            alt={"factura4"}
            sx={{ width: "50%", height: "100%" }}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
