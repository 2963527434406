import { Box, Button, Typography } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import revizie from "../assets/revizie.jpg";

export const Revizie: React.FC<{ forBusiness: boolean }> = (props: {
  forBusiness: boolean;
}) => {
  const { forBusiness } = props;

  const handleDownload = () => {
    const url = process.env.PUBLIC_URL + "/revizie.docx";
    const a = document.createElement("a");
    a.href = url;
    a.download = "Verificari si Revizii - EURO SEVEN INDUSTRY.docx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box
      sx={{
        height: forBusiness ? "1200px" : "1300px",
        width: "80%",
        margin: "0 auto",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "25px",
        "@media (max-width: 1300px)": {
          height: "2700px",
        },
      }}
    >
      <Box
        sx={{
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          width: "100%",
          height: "1100px",
          "@media (max-width: 1300px)": {
            height: "2600px",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: "35px",
            mb: "25px",
          }}
        >
          <Box
            sx={{
              width: "6px",
              height: "50px",
              backgroundColor: "#0054a6",
              mr: "15px",
            }}
          />
          <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
            Verificare si Revizie
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "@media(max-width: 1300px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          {forBusiness ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                textAlign: "left",
                justifyContent: "left",
                gap: "10px",
              }}
            >
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                La Euro7, ne angajăm să asigurăm siguranța și eficiența maximă
                în utilizarea gazelor naturale pentru afacerea dumneavoastră.
                Serviciul nostru de verificare și revizie este esențial pentru a
                menține sistemele de gaz la standarde optime de siguranță și
                performanță.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                sx={{ mb: "10px" }}
              >
                {"Procesul nostru:"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                    fontStyle={"italic"}
                  >
                    Programare Flexibilă
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Înțelegem că timpul este esențial pentru afacerea
                  dumneavoastră. Oferim programări flexibile pentru a minimiza
                  orice întrerupere a activității.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                    fontStyle={"italic"}
                  >
                    Experți Calificați
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Echipa noastră este formată din tehnicieni profesioniști și
                  autorizați, echipați să efectueze verificări și revizii
                  conforme cu cele mai înalte standarde.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                    fontStyle={"italic"}
                  >
                    Raportare Detaliată
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  La finalizarea verificării sau reviziei, veți primi un raport
                  detaliat cu toate constatările și recomandările necesare.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                sx={{ mb: "10px", mt: "10px" }}
              >
                Cum să Solicitați Serviciul
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                    fontStyle={"italic"}
                  >
                    Contactați-ne
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Suntem disponibili telefonic sau prin e-mail pentru a discuta
                  și planifica serviciile de verificare și revizie.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                    fontStyle={"italic"}
                  >
                    Planificare Personalizată
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Vom lucra împreună pentru a stabili un plan care să se
                  potrivească nevoilor și programului afacerii dumneavoastră.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "10px" }}
              >
                La Euro7, prioritatea noastră este să vă asigurăm că
                instalațiile de gaz ale afacerii dumneavoastră funcționează în
                condiții de siguranță și eficiență. Contactați-ne astăzi pentru
                a programa o verificare sau revizie și pentru a vă asigura că
                afacerea dumneavoastră este protejată și conformă cu standardele
                de siguranță.
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                textAlign: "left",
                justifyContent: "left",
                gap: "10px",
              }}
            >
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Toți consumatorii sunt obligați să efectueze verificarea și
                revizia tehnică periodică a instalațiilor de utilizare a gazelor
                naturale. Neindeplinirea acestei obligații, în termenele legale,
                atrage în mod direct răspunderea clientului final pentru
                eventualele accidente tehnice sau pagube ce pot surveni în
                exploatarea și utilizarea instalației de utilizare a gazelor
                naturale și poate conduce la sistarea furnizării gazelor
                naturale. Fii precaut pentru a preveni accidentele! Pentru a
                asigura siguranța familiei voastre cat si a bunurilor detinute,
                fiți siguri că respectați măsurile de precauție și sunteți bine
                informați în privința potențialelor riscuri. Conform
                prevederilor legislative cuprinse în Procedura privind
                verificările și reviziile tehnice ale instalațiilor de utilizare
                a gazelor naturale Ordinul ANRE nr. 179/2015
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                {"Verificarea tehnică se efectuează"}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  fontStyle={"italic"}
                >
                  O dată la maximum 2 ani;
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  fontStyle={"italic"}
                >
                  La cererea ta ori de câte ori este necesar;
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "10px" }}
              >
                Revizia tehnică se efectuează:
              </Typography>
              <Box sx={{ display: "flex" }}>
                <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  fontStyle={"italic"}
                >
                  O dată la maximum 10 ani;
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  fontStyle={"italic"}
                >
                  După întreruperea utilizării instalației de utilizare pe o
                  perioadă mai mare de 6 luni;
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <AddBoxOutlinedIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  fontStyle={"italic"}
                >
                  Sau după orice eveniment care ar fi putut afecta instalația de
                  utilizare.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "10px" }}
              >
                Efectuează lucrările doar cu personal autorizat! Improvizațiile
                și lucrările realizate de persoane neautorzizate îți pun viața
                în pericol! Realizarea operațiunilor de verificare și de revizie
                tehnică a instalației de utilizare a gazelor naturale se
                efectuează exclusiv de către operatori economici autorizați de
                ANRE pentru execuția instalațiilor de utilizare (OE), ce pot fi
                selectați de către dvs., în baza unui contract de prestări
                servicii. Lista integrală a operatorilor economici autorizați
                ANRE poate fi consultată de către clientul final pe pagina de
                internet a Autorității Naționale de Reglementare în Domeniul
                Energiei (ANRE), respectiv www.anre.ro. În situația în care
                instalația de utilizare a gazelor naturale deservește mai mulți
                clienți finali, verificarea/revizia tehnică se realizează atât
                pentru instalația individuală de utilizare, cât și pentru
                instalația comună de utilizare.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "10px" }}
              >
                Consultați oferta specială destinată clientilor casnici aflați
                în portofoliul de furnizare Euro7 pentru a vedea efectuări
                verificării și reviziei tehnice periodice a intalațiilor de
                utilizare a gazelor naturale.
              </Typography>
              <Button
                variant="contained"
                onClick={handleDownload}
                sx={{
                  height: "40px",
                  fontFamily: "Catesque",
                  mt: "10px",
                  width: "200px",
                }}
              >
                Descarcă Ofertă
              </Button>
            </Box>
          )}
          <Box
            component="img"
            src={revizie}
            alt="revizie"
            width={500}
            height={400}
            sx={{ mt: "50px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
