import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import tarife from "../assets/tarife.jpg";
import tarife1 from "../assets/tarife1.jpg";
import tarife2 from "../assets/tarife2.jpg";
import tarife3 from "../assets/tarife3.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ReglementareTarifePage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={tarife}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Reglementare Tarife" />
        
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1200px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box sx={{ width: "100%", height: `calc(33.3%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={tarife1}
              height={"250px"}
              width={"380px"}
              alt={"tarife1"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Tarife de Distribuție
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Tariful de distribuție în ceea ce privește gazele naturale
                reprezintă suma asociată serviciilor de distribuție furnizate de
                compania de distribuție către clienții săi.
              </Typography>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Acest tarif cuprinde costurile necesare pentru
                operaționalizarea, întreținerea și extinderea rețelelor de
                distribuție, precum și pentru dezvoltarea infrastructurii
                esențiale care facilitează transportul gazului natural de la
                punctele de intrare în rețea, adesea reprezentate de stațiile de
                reglare a presiunii, până la locuințele sau afacerile
                clienților.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(33.3%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Tarife de Transport
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Tariful de transport în ceea ce privește gazele naturale
                reprezintă contravaloarea serviciilor de transportare a gazelor
                naturale de la producători, operatorii depozitelor de
                înmagazinare sau importatori către punctele de destinație, fie
                ele puncte de consum sau de distribuție.
              </Typography>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                În cadrul teritoriului României, singurul operator de transport
                de gaze naturale este Transgaz, care deține responsabilitatea nu
                numai pentru furnizarea serviciilor de transport, ci și pentru
                activitățile de cercetare și proiectare în domeniul
                transportului gazelor naturale.
              </Typography>
            </Box>
            <Box
              component={"img"}
              src={tarife2}
              height={"250px"}
              width={"380px"}
              alt={"tarife2"}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: `calc(33.3%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={tarife3}
              height={"250px"}
              width={"380px"}
              alt={"tarife3"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Tarife de înmagazinare
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Operatorii responsabili cu înmagazinarea gazelor naturale
                stabilesc, de obicei, un tarif care este aprobat anual de către
                Autoritatea Națională de Reglementare în Domeniul Energiei.
              </Typography>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                În România, există doi operatori de înmagazinare subterană a
                gazelor naturale, și anume Depomureș și Depogaz. Acești
                operatori joacă un rol esențial în garantarea siguranței și
                continuității aprovizionării cu gaze naturale în țară.
              </Typography>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Tariful de înmagazinare, în combinație cu celelalte elemente
                componente, contribuie la formarea prețului final al gazelor
                naturale oferit de furnizorul de servicii energetice.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
