import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import {
  Box,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import { navigateToPage } from "../utils";
import { useNavigate } from "react-router-dom";

export const ActualizeDatePersonale: React.FC = () => {
  const navigate = useNavigate();

  const handleDownload = () => {
    const url = process.env.PUBLIC_URL + "/formularadp.doc";
    const a = document.createElement("a");
    a.href = url;
    a.download = "Formular Actualizare Date - EURO SEVEN INDSUTRY.doc";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box
      sx={{
        height: "600px",
        width: "80%",
        margin: "0 auto",
        textAlign: "left",
        "@media (max-width: 900px)": {
          height: "800px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "420px",
          backgroundColor: "#f2f2f2",
          mt: "50px",
          padding: "25px",
          borderRadius: "25px",
          "@media (max-width: 900px)": {
            height: "750px",
          },
        }}
      >
        <Stack spacing={4}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "25px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Actualizare Date Personale
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Actualizează-ți datele personale cu ușurință, rapid și în siguranță!
            Dacă ai efectuat modificări privind adresa de corespondență, adresa
            de e-mail, numărul de telefon sau alte informații relevante, te
            rugăm să ne anunți urmând pașii de mai jos:
          </Typography>
          <Stepper
            alternativeLabel
            activeStep={2}
            connector={<ColorlibConnector />}
            orientation="horizontal" // Default orientation
            sx={{
              "@media (max-width: 900px)": {
                flexDirection: "column", // Change orientation to vertical on smaller screens
              },
            }}
          >
            {steps.map((label) => (
              <Step
                key={label}
                sx={{
                  "&. MuiStepLabel-label": {
                    fontFamily: "Catesque",
                  },
                }}
              >
                <CustomStepLabel
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    fontFamily: "Catesque",
                    "&. MuiStepLabel-labelContainer": {
                      "&. MuiStepLabel-label": {
                        fontFamily: "Catesque",
                      },
                    },
                  }}
                >
                  {label}
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Este o modalitate simplă de a ne asigura că avem informațiile
            corecte și actualizate pentru tine.
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={handleDownload}
              sx={{
                height: "40px",
                fontFamily: "Catesque",
                mt: "10px",
                width: "200px",
              }}
            >
              Descarcă Acord
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigateToPage(navigate, "/contact")}
              sx={{
                height: "40px",
                fontFamily: "Catesque",
                mt: "10px",
                width: "200px",
              }}
            >
              Contact
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
  "@media (max-width: 900px)": {
    flexDirection: "column", // Change direction to vertical
    alignItems: "center", // Optional: center the connector vertically
    [`& .${stepConnectorClasses.line}`]: {
      width: 3, // Change the width to create a vertical line
      height: "100%", // Make the line take full height
    },
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  fontFamily: "Catesque",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    fontFamily: "Catesque",
    fontSize: "1rem",
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  "1. Descarcă și completează acordul GDPR disponibil.",
  "2. Transmite-ne solicitarea ta împreună cu acordul GDPR semnat.",
  "3. Echipa noastră va verifica și actualiza datele tale în cel mai scurt timp posibil.",
];
