export type UserEntity = {
  id: number;
  username: string; //email
  password: string;
  inactive?: boolean;
  role?: string;
  name?: string;
  codClient?: number;
  address?: string;
  judet?: string;
  localitate?: string | null;
  phone?: string;
  image?: string;
  restDePlataTotal?: number;
  indexVechi?: string;
  indexNou?: string;
  defaultPassword?: Boolean;
};

export type InvoiceEntity = {
  id?: number;
  created_date?: string | null;
  due_date?: string | null;
  price?: number;
  file?: string;
  status?: string;
  location?: string;
  nrFactura?: string;
  codClient?: number | null;
  restDePlata?: number;
  indexNou?: string;
  indexVechi?: string;
  corectie?: boolean;
};

export type PaymentEntity = {
  id?: number;
  date?: string | null;
  amount?: number;
  userId?: number;
  userName?: string;
  codClient?: number;
  invoiceId?: number;
  nrFactura?: number;
  incasariId?: number;
  paymentMethod?: string;
};

export type MeterReadingEntity = {
  id?: number;
  serieContor?: string;
  indexVechi?: string;
  indexNou?: string;
  codClient?: number;
  date?: string | null;
  picture?: string | null;
  accepted?: Boolean;
};

export type NotificationEntity = {
  id?: number;
  content?: string;
  codClient?: number;
  completed?: boolean;
};

export type ConsumEntity = {
  id: number;
  cod: string;
  nrFactura: string;
  dePlata: string;
  indexVechi: string;
  indexNou: string;
  consumT: string;
  perioada: string;
};

export type AnuntEntity = {
  id?: number;
  title: string;
  date?: string;
  content: string;
  picture?: string;
};

export type OfertaEntity = {
  id?: number;
  forWho: string;
  prenume: string;
  nume: string;
  email: string;
  telefon: string;
  localitate: string;
  judet: string;
  adresa: string;
  numeCompanie?: string;
  cui?: string;
  domeniuActivitate?: string;
  consumMediu?: string;
  detalii: string | undefined;
};

export type SoldEntity = {
  id?: number;
  suma?: number;
  codClient?: number;
  data?: string;
  location?: string;
  nrFactura?: number;
  metoda?: string;
};

export enum INVOICE_STATUS {
  SCADENTA = "SCADENTA",
  RESTANTA = "RESTANTA",
  PLATITA = "PLATITA",
}
