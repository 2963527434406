import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import j5 from "../assets/j5.jpeg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ProtectiaDatelorPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={j5}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="GDPR - Protecția Datelor" />
      
      </Box>
      <Box
        sx={{
          width: "60%",
          margin: "0 auto",
          height: "8700px",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          "@media (max-width: 1400px)": {
            width: "80%",
            height: "600px",
          },
          "@media (max-width: 960px)": {
            height: "700px",
            width: "80%",
          },
          "@media (max-width: 500px)": {
            height: "800px",
            width: "90%",
          },
        }}
      >
        <Typography fontFamily="Catesque" fontSize="1rem">
          EURO SEVEN INDUSTRY S.R.L., cu sediul în Bucureşti, sectorul 1, str.
          Horatiu nr. 11, apartament 2 (etaj), înregistrată la Registrul
          Comerțului sub nr. J40/7881/2000, cod fiscal RO13310879, (”Euro Seven”
          sau ”Societatea”) proprietar şi administrator al https://www.euro7.ro,
          respectă dispoziţiile legale în vigoare privind protecția datelor cu
          caracter personal în desfăşurarea întregii sale activităţi şi acordă
          respect, încredere şi confidenţialitate, oferind securitate tuturor
          informaţiilor cu caracter personal colectate pe site-ul nostru.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Euro Seven ține la confidențialitatea informațiilor vizitatorilor
          site-ului https://www.euro7.ro, și a persoanelor ale căror informații
          personale le primește prin utilizarea depunerilor de cereri, formulare
          și a alte servicii conexe.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Această politică de confidențialitate explică modul în care Societatea
          colectează și procesează informațiile personale prin intermediul
          site-ului și nu numai, în desfășurarea activităților sale privind
          operarea și livrarea serviciilor și a produselor asigurandu-vă totdată
          că datele dumneavoastră cu caracter personal sunt prelucrate
          responsabil și în conformitate cu legislația privind protecția datelor
          cu caracter personal care este aplicabilă.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Prin furnizarea informațiilor personale pe site-ul Euro Seven, sunteți
          de acord cu practicile de manipulare a informațiilor descrise în
          această politică de confidențialitate. Vă încurajăm să citiți
          secțiunea intitulată “Drepturile dumneavoastră” de mai jos, pentru a
          înțelege alegerile pe care le aveți cu privire la informațiile
          dumneavoastră personale.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Pentru a facilita parcurgerea documentului, am inclus la sfârșitul
          acestei note un glosar care explică principalele noțiuni folosite
          (e.g. “date cu caracter personal”, “prelucrare”, etc.).
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          I. DATELE NOASTRE DE CONTACT:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dacă aveţi orice comentarii, sugestii, întrebări cu privire la orice
          informaţii din această notă sau cu privire la orice alte aspecte
          referitoare la prelucrarea datelor dumneavoastră pe care o realizăm,
          vă rugăm să nu ezitaţi să contactaţi oricând responsabilul nostru cu
          protecţia datelor. În funcție de preferințele dumneavoastră, ne puteți
          contacta prin oricare dintre canalele de comunicare de mai jos:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Denumire completă: EURO SEVEN INDUSTRY S.R.L
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Adresă sediu social: Bucureşti, sectorul 1, str. Horatiu nr. 11,
          apartament 2 (etaj);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Număr de telefon: 0374 627 726.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Adresă de email: office@euro7.ro
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          II. RESPECTAREA PRINCIPIILOR PRELUCRĂRII:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Euro Seven, în activitatea sa de prelucrare a datelor cu caracter
          personal, se supune întotdeauna următoarelor principii:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Toate datele sunt prelucrate în mod legal, echitabil și transparent
          față de persoana vizată („legalitate, echitate și transparență”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele personale sunt colectate în scopuri determinate, explicite și
          legitime și nu sunt prelucrate ulterior într-un mod incompatibil cu
          aceste scopuri („limitări legate de scop”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Toate datele personale sunt adecvate, relevante și limitate la ceea ce
          este necesar în raport cu scopurile în care sunt prelucrate
          („reducerea la minimum a datelor”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne asigurăm că datele dumneavoastră sunt exacte și, în cazul în care
          este necesar, le actualiză; luăm toate măsurile necesare pentru a se
          asigura că datele cu caracter personal care sunt inexacte, având în
          vedere scopurile pentru care sunt prelucrate, sunt șterse sau
          rectificate fără întârziere („exactitate”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele dumneavoastră personale nu sunt păstrate mai mult timp decât
          este necesar („limitări legate de stocare”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Securitatea adecvată a prelucrării datelor dumenavoastră cu caracter
          personal, inclusiv protecția împotriva prelucrării neautorizate sau
          ilegale și împotriva pierderii, a distrugerii sau a deteriorării
          accidentale, prin luarea de măsuri tehnice sau organizatorice
          corespunzătoare („integritate și confidențialitate”);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Prelucrarea are loc în concordanță cu respectarea drepturile
          dumneavoastră în calitate de persoană vizată;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele dumneavoastră nu sunt transferate în afara Spațiului Economic
          European, decât în cazul în care teritoriul/țara unde urmează a fi
          transferate asigură un nivel adecvat de protecție a datelor cu
          caracter personal.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          III. DATELE CU CARACTER PERSONAL PE CARE LE PRELUCRĂM:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele cu caracter personal referitoare la dumneavoastră pe care le
          vom prelucra sunt datele obținute direct de la dumneavoastră, pe care
          ni le transmiteți în mod voluntar, date tehnice colectate de la toți
          utilizatorii site-ului, informații colectate prin intermediul
          serviciilor noastre, informații pe care le colectăm de la terțe părți,
          care au permisiunea de a face schimb de informații cu noi și includ
          următoarele categorii de date:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Detalii personale: nume; prenume; sex; data naşterii/ vârsta;
          cetăţenie; adresă de domiciliu / reşedinţă, număr de telefon
          mobil/fix, număr de fax; adresă de e-mail, CNP, restul informațiilor
          din actul dumneavoastră de identitate (inclusiv data emiterii, data
          expirării actului, locul naşterii),
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Date privind imaginea: imagini/înregistrări video (în incintele
          noastre unde avem instalate camere CCTV de supraveghere video – acolo
          unde există, acestea sunt indicate prin semne la vedere);
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Contactul dvs. cu noi, cum ar fi o notă sau înregistrarea unui apel pe
          care l-ați făcut către noi, datele transmise prin e-mail sau o
          scrisoare, solicitarea unei oferte, sau alte evidențe ale unui contact
          cu noi;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Date referitoare la plată: adresă de facturare, numărul contului
          bancar sau al cardului bancar/ cod IBAN, numele şi prenumele
          titularului contului bancar sau al cardului bancar (poate fi altul
          decât dumneavoastră daca altcineva a efectuat plata unei facturi in
          numele si pentru dumneavoastra); data de la care cardul bancar este
          valabil; data expirării cardului bancar, cod client;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Opinii şi viziuni (pot include date sensibile), cum ar fi: orice
          opinii şi viziuni pe care ni le transmiteţi sau orice opinii şi
          viziuni pe care le postaţi public despre noi pe reţelele de
          socializare (social media) sau pe care le faceţi cunoscute pe alte
          canale publice;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Vom colecta datele dumneavoastră cu caracter personal obișnuite atunci
          când, de exemplu:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne vizitați la sediul noastru, ne transmiteți CV-uri sau cereri de
          angajare;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne transmiteți o solicitare de colaborare sau un răspuns la
          solicitarea de colaborare, ne transmiteți o cerere de colectare a
          deseurilor;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Solicitați/cumpărați sau utilizați oricare din serviciile/produsele
          noastre;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În vederea încheierii/executării unui contract;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Vă abonați la buletine informative, alerte sau alte servicii oferite
          de noi;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne contactați prin diverse canale pentru a solicita informații în
          legătură cu serviciile noastre; de ex. atunci când alegeți să le
          oferiți prin intermediul site-ului https://www.euro7.ro, inclusiv
          atunci când ne trimiteți un e-mail care pune o întrebare – acesta se
          înregistrează pentru a vă răspunde solicitării;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Vizitați sau navigați pe site-ul nostru;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În situația în care transmitem astfel de date unor terți furnizori sau
          colaboratori ai noștri, în măsura în care avem temeiuri legale, de
          exemplu: avocați, consultanți, contabili, etc.;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Atunci când datele dvs. cu caracter personal sunt publice;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Primim și înregistrăm în mod automat informațiile din jurnalele de
          server din browser-ul dumneavoastră, atunci când utilizați site-ul
          https://www.euro7.ro. Putem folosi o varietate de metode, inclusiv
          “cookie-uri” pentru a colecta aceste informații. Informațiile pe care
          le putem colecta prin aceste metode automatizate pot include, de
          exemplu, adresa dvs. de IP, tipul de browser utilizat, conținutul și
          paginile pe care le accesați pe site-ul nostru, durata și frecvența
          vizitelor dvs. pentru a urmări mișcările dvs. pe site-ul nostru – a se
          vedea secțiunea de Cookie-uri de mai jos.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Respectul nostru pentru datele dumneavoastră include faptul că le
          acordăm atenția umană necesară, prin intermediul personalului nostru.
          În condiţiile actuale, nu faceți obiectul unei decizii a noastre
          bazate exclusiv pe prelucrarea automată a datelor dumneavoastră
          (inclusiv crearea de profiluri) care să producă efecte juridice cu
          privire la dumneavoastră sau care să vă afecteze într-un mod similar
          într-o măsură semnificativă.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          IV. SCOPURILE PENTRU CARE PRELUCRĂM DATELE DUMNEAVOASTRĂ CU CARACTER
          PERSONAL
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Scopurile pentru care prelucrăm date cu caracter personal referitoare
          la dumneavoastră sunt următoarele:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Pentru prestarea serviciilor noastre. Datele dumneavoastră cu caracter
          personal sunt folosite pentru a ne derula activitatea de
          colectare/gestionare a deșeurilor nepericuloase, pentru a încheia
          contracte de transfer de responsabilitati privind realizarea
          obiectivelor anuale de colectare a deșeurilor nepericuloase, pentru a
          da curs cererilor de colectare deșeurilor nepericuloase (caz în care
          trebuie să clarificăm situația de fapt și să efectuăm verificări
          suplimentare în cadru strict legal), pentru a da curs cererilor
          formulate de autoritățile competente care ne supraveghează și
          reglementează activitatea.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Facturare și relații cu clienții. Pentru a vă factura achiziționarea
          serviciilor noastre, pentru a vă contacta în cazul în care datele de
          facturare pe care ni le-ați furnizat sunt eronate, pe cale să expire
          sau nu putem încasa plata, pentru a răspunde la orice întrebări sau
          preocupări pe care le puteți avea în legătură cu serviciile noastre;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Gestionarea sistemelor noastre de comunicaţii şi IT (tehnologia
          informaţiei). Gestionarea sistemelor noastre de comunicaţii;
          gestionarea securităţii noastre IT; realizarea auditurilor de
          securitate asupra reţelelor noastre IT, emiterea de rapoarte către
          instituțiile abilitate sau repararea unor erori de sistem;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Îndeplinirea obligaţiilor noastre legale. Îndeplinirea obligaţiilor
          noastre legale cu privire la arhivare, securitate, raportare, ţinerea
          evidenţelor și a altor obligații pe care legislația ni le impune.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Îmbunătăţirea serviciilor. Identificarea potenţialelor probleme cu
          privire la serviciile noastre în vederea îmbunătățirii acestora,
          soluţionarea sesizărilor/reclamațiilor dumneavoastră înregistrate pe
          site-ul Societății https://www.euro7.ro,- gestionarea corespondenței
          uzuale cu partenerii contractuali etc., controlul accesului
          vizitatorilor în sediul Societății;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Supravegherea spațiilor conform dispozițiilor legale. Sistemele CCTV
          montate pentru supravegherea spațiilor aferente căilor de acces,
          zonelor cu valori, pentru paza și protecția bunurilor și personalului
          aflat în acele spații etc.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          V. TEMEIURILE PE BAZA CĂRORA PRELUCRĂM DATELE DUMNEAVOASTRĂ
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          ”Euro Seven” va prelucra datele dumneavoastră cu caracter personal
          obișnuite în considerarea cel puțin a unuia din următoarele temeiuri:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Consimțământul – în cazul în care nu se aplică vreun alt temei de
          legalitate al prelucrării prevăzut mai jos, Societatea obține
          întotdeauna consimțământul persoanei vizate pentru prelucrarea datelor
          sale cu caracter personal pentru unul sau mai multe scopuri specifice.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Încheierea/executarea unui contract – prelucrarea este necesară pentru
          executarea de către Societate a unui contract la care persoana vizată
          este parte sau pentru a face demersuri la cererea persoanei vizate
          înainte de încheierea unui contract. De exemplu în vederea inițierii
          discuțiilor cu privire la costurile și tipurile de servicii
          solicitate, pentru a putea încheia un contract cu dumneavoastră.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Obligație legală – prelucrarea este necesară în vederea îndeplinirii
          unei obligații legale care îi revine Societății. De exemplu cerințe
          contabile și fiscale care fac obiectul unor politici interne stricte
          cum ar fi perioada de păstrare a documentelor fiscale/contabile. Putem
          prelucra datele dumneavoastră pentru îndeplinirea obligaţiilor noastre
          de arhivare a dosarelor privind serviciile prestate, a obligaţiilor de
          a comunica unor autorităţi publice la cerere, anumite informaţii, sau
          a altor obligaţii legale.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Interes legitim – prelucrarea este necesară în scopul intereselor
          legitime urmărite de Societate. De exemplu în cazul în care prelucrăm
          datele dumneavoastră pentru menținerea securității rețelei,
          îmbunătățirea serviciilor noastre.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Constatarea, exercitarea sau apărarea unui drept al nostru în
          instanță. În situația în care între dumneavoastră și noi apar
          diferende pe care nu le putem soluționa împreună pe cale amiabilă,
          este posibil să prelucrăm datele dumneavoastră pentru constatarea,
          exercitarea sau apărarea unui drept al nostru în instanță.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          VI. CUI TRANSMITEM DATELE DUMNEAVOASTRĂ
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ca regulă generală, nu divulgăm datele dumneavoastră către alte
          companii, organizaţii sau persoane din orice ţară (inclusiv România).
          În anumite situații însă, este posibil să divulgăm datele
          dumneavoastră către alte persoane fizice sau juridice.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Încercăm însă să fim cât de transparenți și specifici cu putință, iar
          în continuare vom prezenta categoriile de astfel de destinatari:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Autorități competente, operatori de servicii de colectare, transport
          etc., firme de contabilitate și audit, avocați, resurse umane,
          parteneri contractuali, furnizori de servicii etc. Ori de câte ori
          facem acest lucru, ne asigurăm că există o justificare temeinică, că
          vom transmite doar datele strict necesare pentru realizarea scopului
          transmiterii și vom încerca să ne asigurăm că destinatarul asigură un
          standard înalt de protecție a datelor cu caracter personal.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În cazul activităților de prelucrare a datelor făcute de un terț
          prestator/furnizor/partener/intermediar pentru Societate în baza unui
          contract de prestări servicii de orice tip încheiat între Euro Seven
          și terțul respectiv (care are calitatea de procesator al datelor cu
          caracter personal din punct de vedere al GDPR), aceste contracte se
          încheie în scris și includ o serie de clauze specifice, prin care
          terții respectivi oferă garanții suficiente pentru punerea în aplicare
          a unor măsuri tehnice și organizatorice adecvate, astfel încât
          prelucrarea să respecte cerințele prevăzute în GDPR și să asigure
          protecția drepturilor persoanei vizate.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Destinatari pentru care ne solicitaţi sau ne daţi acordul în acest
          sens sau persoane care pot demonstra că deţin autoritatea legală de a
          acţiona în numele dumneavoastră;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Orice persoană, agenție, executor judecătoresc sau instanță relevantă
          din România – în măsura necesară pentru constatarea, exercitarea sau
          apărarea unui drept al nostru;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În cazul în care este interesul nostru legitim să facem acest lucru
          pentru a administra, extinde sau dezvolta activitatea comercială, de
          exemplu în cazul în care vindem sau transferăm toate sau o parte
          dintre părțile noastre sociale, activele noastre sau afacerea noastră
          (inclusiv în cazul reorganizării, al dizolvării sau al lichidării
          noastre), situaţie în care datele personale deţinute de noi vor
          constitui unul dintre activele transferate - în această situația
          potențialii achizitori vor fi ținuți de o obligație de
          confidențialitate.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          VII. CÂT TIMP ȘI CUM PĂSTRĂM DATELE DUMNEAVOASTRĂ
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Păstrăm datele dumneavoastră cu caracter personal doar atât timp cât
          ne sunt necesare sau cât prevede legislația în vigoare.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele dumneavoastră care sunt necesare în scopuri legate de prestarea
          serviciilor noastre vor fi stocate pe o durata de 3 ani de la
          incetarea contractului de prestări servicii încheiat cu Societatea.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele legate de plăți/facturare vor fi stocate pe o perioada de 10
          ani, conform Legii nr. 82/1991 privind contabilitatea.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Datele privind supravegherea video pentru asigurarea securității
          bunurilor și persoanelor se vor stoca pe o perioadă de 30 de zile
          calendaristice în conformitate cu legislația în vigoare.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dacă nu există nicio cerință legală, le vom stoca doar atât timp cât
          este necesar pentru prelucrarea datelor în scopurile amintite mai sus.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Cu excepția situațiilor în care legea prevede altfel și a celor
          exemplificate mai sus, de regulă vom prelucra datele dumneavoastră pe
          durata existenței unui contract între dumneavoastră și ”Euro Seven”,
          plus o perioadă de 3 ani de la încetarea acestuia.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Depunem eforturi pentru a ne asigura că datele sunt păstrate în
          siguranță. Pentru stocarea datelor dumneavoastră în format electronic,
          folosim serverele proprii sau ale altor companii specializate în
          arhivarea electronică.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne străduim să utilizăm măsuri organizaţionale, tehnice şi
          administrative rezonabile pentru a proteja datele cu caracter personal
          în cadrul Societății noastre.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Pentru aceasta, am adoptat inclusiv politici dedicate privind măsurile
          fizice și electronice de securitate, pentru a ne proteja sistemele de
          acces neautorizat și alte posibile amenințări la securitatea acestora.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În măsura în care este posibil, vom anonimiza datele care nu ne mai
          sunt necesare într-o manieră care să nu permită identificarea
          persoanelor vizate sau aplicăm pseudonimizarea pentru a limita
          riscurile cu privire la datele personale prelucrate.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          VIII. CARE SUNT DREPTURILE DUMNEAVOASTRĂ ŞI CUM LE PUTEȚI EXERCITA
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Tratăm cu seriozitate și implicare deplină drepturile pe care le aveţi
          în legătură cu prelucrarea pe care o realizăm asupra datelor
          referitoare la dumneavoastră. Pe scurt, drepturile dumneavoastră sunt
          următoarele:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de a fi informat. În momentul în care datele cu caracter
          personal sunt colectate de la dumneavoastră sau obținute dintr-o altă
          sursă, avem obligația de vă informa cu privire la scopul utilizării
          acestor date și la drepturile pe care le aveți.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de acces la date. Aveţi dreptul de a solicita informații
          legate de datele personale pe care le deținem despre dumneavoastră,
          inclusiv informații legate de categoriile de date pe care le deținem
          sau pe care le controlăm, pentru ce sunt folosite acestea, sursa din
          care le-am colectat dacă le-am obţinut indirect, și cui sunt divulgate
          aceste date, dacă este cazul. Vă vom oferi o copie a datelor
          dumneavoastră personale la cerere. Dacă solicitați mai multe copii ale
          datelor dumneavoastră personale, vă putem percepe o taxă rezonabilă
          bazată pe costurile administrative.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul la rectificarea datelor. Aveţi dreptul de a obţine
          rectificarea datelor dumneavoastră pe care le prelucrăm sau le
          controlăm, dacă acestea nu sunt corecte.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul la ştergerea datelor („dreptul de a fi uitat”). Aveţi dreptul
          de a obţine de la noi ştergerea datelor dumneavoastră pe care le
          prelucrăm sau le controlăm. ”Euro Seven” urmărește să prelucreze și să
          păstreze datele dumneavoastră doar atât timp cât acest lucru este
          necesar. Trebuie să ne conformăm acestei cereri dacă prelucrăm datele
          dumneavoastră personale, și dacă:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          datele personale nu mai sunt necesare pentru îndeplinirea scopurilor
          pentru care au fost colectate;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          vă opuneți prelucrării din motive legate de situația dumneavostră
          particulară;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          datele dumneavoastră au fost prelucrate ilegal;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          datele personale trebuie șterse pentru respectarea unei obligații
          legale care ne revine;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          cu excepția cazului în care datele dumneavoastră sunt necesare în
          continuare:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          pentru exercitarea dreptului la liberă exprimare și la informare;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          pentru a ne conforma unei obligații legale pe care o avem;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          în scopuri de arhivare în interes public, științific sau pentru studii
          istorice sau în scopuri statistice; sau
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          pentru constatarea, exercitarea sau apărarea unui drept în instanță.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul la restricţionarea prelucrării datelor. Puteți obține din
          partea noastră restricționarea prelucrării datelor dumneavoastră
          personale, în cazul în care:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          contestați corectitudinea datelor dumneavoastră personale, pentru
          perioada de care avem nevoie pentru a verifica corectitudinea,
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          prelucrarea este nelegală, dar vă opuneţi ștergerii datelor cu
          caracter personal, solicitând în schimb restricționarea utilizării
          lor,
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          nu mai avem nevoie de datele dumneavoastră personale dar dumneavoastră
          le solicitați pentru stabilirea, exercitarea sau apărarea unui drept
          în instanţă, sau
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          vă opuneţi prelucrării, pentru intervalul de timp în care noi
          verificăm dacă interesele noastre legitime prevalează asupra alor
          dumneavoastră.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de opoziție la utilizarea datelor cu caracter personal. În
          anumite circumstanțe, aveţi dreptul de a obiecta la prelucrarea
          datelor dumneavoastră de către noi sau în numele nostru. Acolo unde
          prelucrarea nu se bazează pe consimțământul dumneavoastră, ci pe
          interesele noastre legitime sau pe cele ale unei terțe părți, puteți
          obiecta în orice moment la prelucrarea datelor dumneavoastră personale
          din motive legate de situația dumneavoastră particulară. În acest caz
          nu vom mai prelucra datele dumneavoastră personale, cu excepția
          cazului în care: (a) putem dovedi motive legitime și imperioase care
          justifică prelucrarea și care prevalează asupra intereselor,
          drepturilor și libertăților dumneavoastră sau (b) în cazul în care
          scopul este constatarea, exercitarea sau apărarea unui drept în
          instanță.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dacă obiectați la prelucrare, vă rugăm să specificați dacă doriți, de
          asemenea, ca datele dumneavoastră personale să fie șterse, în caz
          contrar noi doar le vom restricționa.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul la portabilitatea datelor. Aveți dreptul de a primi datele
          dumneavoastră personale pe care ni le-ați furnizat, iar în cazul în
          care este fezabil din punct de vedere tehnic, să solicitați ca noi să
          transmitem datele dumneavoastră personale (pe care ni le-ați furnizat)
          unei alte organizații/clinici.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Aceste două drepturi sunt drepturi pe care le aveți dacă, în mod
          cumulativ: (a) vă prelucrăm datele personale cu mijloace automate, (b)
          ne bazăm, în prelucrarea datelor dumneavoastră personale, pe
          consimțământul dumneavoastră sau prelucrarea de către noi a datelor
          dumneavoastră personale este necesară pentru încheierea sau executarea
          unui contract la care sunteţi parte;(c) datele dumneavoastră personale
          ne sunt furnizate de dumneavoastră, și (d) transmiterea datelor
          dumneavoastră personale nu are un efect negativ asupra drepturilor și
          libertăților altor persoane.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Aveți dreptul să primiți datele dumneavoastră personale într -un
          format structurat, folosit în mod curent și care poate fi citit
          automat.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul ca datele dumneavoastră personale să fie transmise de către
          noi unei alte organizații este un drept pe care îl aveți dacă această
          transmitere este fezabilă din punct de vedere tehnic.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          , de asemenea, transmiterea datelor personale altor persoane (care nu
          își dau consimțământul pentru acest transfer).
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul ca datele dumneavoastră personale să fie transmise de către
          noi unei alte organizații este un drept pe care îl aveți dacă această
          transmitere este fezabilă din punct de vedere tehnic.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de a nu fi supus unei decizii automate (inclusiv profiling).
          Aveți dreptul de a nu fi supus unei decizii bazate numai pe procesare
          automată (incluzand profiling-ul) și care să producă efecte legale
          față de dumneavoastră sau care să vă afecteze în mod semnificativ.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Acest drept nu se va aplica în următoarele situații de excepție, în
          care decizia automată:
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          a) este necesară pentru încheierea sau executarea unui contract între
          persoana vizată și Societate;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          b) este autorizată prin dreptul Uniunii sau dreptul intern care se
          aplică Societății și care prevede, de asemenea, măsuri corespunzătoare
          pentru protejarea drepturilor, libertăților și intereselor legitime
          ale persoanei vizate; sau
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          c) are la bază consimțământul dumneavoastră explicit;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În situațiile de la literele a) și c) din prezentul paragraf,
          Societatea are obligația de a implementa măsurile corespunzătoare
          pentru protejarea drepturilor, libertăților și intereselor legitime
          ale dumneavoastră, cel puțin dreptul dumneavoastră de a obține
          intervenție umană din partea Societății, de a vă exprima punctul de
          vedere și de a contesta decizia.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul la retragerea consimţământului. În situaţiile în care
          prelucrăm datele dumneavoastră în temeiul consimţământului
          dumneavoastră, aveţi dreptul de a vă retrage consimţământul; puteți
          face aceasta în orice moment, cel puţin la fel de uşor cum ne-aţi
          acordat iniţial consimţământul. Retragerea consimțământului nu va
          afecta legalitatea prelucrării datelor dumneavoastră pe care am
          realizat-o înainte de retragere.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de a depune o plângere la autoritatea de supraveghere. Dacă
          aveţi o nemulţumire cu privire la modul în care prelucrăm datele
          dumneavoastră, am prefera să ne contactaţi pe noi direct pentru a vă
          putea soluţiona problema. Cu toate acestea, dacă aveți în continuare
          nemulțumiri, puteți contacta Autoritatea Națională de Supraveghere a
          Prelucrării Datelor cu Caracter Personal (www.dataprotection.ro):
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Adresă: B-dul G-ral. Gheorghe Magheru, București, România;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Telefon: +40.318.059.211/ +40.318.059.212
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Fax: +40.318.059.602;
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          E-mail: anspdcp@dataprotection.ro
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Dreptul de a vă adresa justiției. Totodată, este important de reținut
          că, în ipoteza în care considerați că drepturile de care beneficiați
          potrivit Regulamentului au fost încălcate ca urmare a prelucrării
          datelor sale cu carcater personal cu nerespectarea prevederilor
          acestuia și fără a aduce atingere dreptului dumneavoastră de a formula
          plângere la autoritatea de supraveghere, aveți dreptul de a vă adresa
          instanțelor de judecată, prin formularea unei căi de atac împotriva
          Societății.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Pentru a exercita unul sau mai multe dintre aceste drepturi sau pentru
          a adresa orice întrebare despre oricare dintre aceste drepturi sau
          alte aspecte ale prelucrării datelor dumneavoastră de către noi, o
          puteți face prin transmiterea unei cereri scrise, semnate și datate la
          adresa de e-mail: office@euro7.ro. Totodată, la sediul nostru sunt
          disponibile și formulare imprimate pe care le puteți completa pentru a
          solicita exercitarea unuia sau a mai multor dintre drepturile de mai
          sus.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Vom încerca să răspundem solicitării dumneavoastră în termen de o lună
          de zile, perioadă care poate fi prelungită cu două luni din cauza unor
          motive specifice legate de dreptul specific invocat sau de
          complexitatea cererii dumneavoastră. În orice caz, dacă această
          perioadă este prelungită, vă vom informa în privința termenului de
          prelungire și a motivelor care au dus la această prelungire.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În anumite situații, s-ar putea să nu vă putem acorda accesul la toate
          sau o parte a datelor dumneavoastră personale din cauza unor
          restricţii legale. Dacă vă refuzăm cererea de acces, vă vom comunica
          motivul acestui refuz.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În anumite cazuri, s-ar putea să nu vă putem identifica datele
          personale din cauza elementelor de identificare pe care ni le
          furnizați în cerere. În astfel de cazuri, dacă nu vă putem identifica
          drept persoană vizată, nu putem da curs cererii dumneavoastră în
          conformitate cu această secțiune, cu excepția cazului în care ne
          oferiți informații suplimentare care să ne permită să vă identificăm.
          Vă vom informa și vă vom da posibilitatea de a ne oferi astfel de
          detalii suplimentare.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          IX. COOKIE-URI
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Un cookie este un șir de informații pe care un site web le stochează
          pe device-ul unui vizitator și pe care browser-ul vizitatorului le
          oferă site-ului de fiecare dată când vizitatorul se întoarce. Deoarece
          browser-ul oferă aceste informații cookie site-ului la fiecare vizită,
          cookie-urile servesc ca un fel de etichetă care permite unui site să
          „recunoască” un browser atunci când revine pe site. Pentru mai multe
          detalii, vă rugăm să accesați: http://ro.wikipedia.org/wiki/Cookie
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Cookie-urile stochează informații despre activitățile dvs. pe un site
          web sau pe o altă platformă. De exemplu, modulele cookie pot stoca
          informațiile despre sesiune pentru a vă conecta ușor la un site web
          sau la altă platformă pe care ați vizitat-o anterior.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Informațiile pe care le putem colecta cu aceste metode automate pot
          include, de exemplu, adresa IP, codul de utilizator, tipul de browser,
          tipul de sistem, conținutul și paginile pe care le accesați pe
          site-urile sau serviciile noastre, frecvența și durata vizitelor
          dumneavoastră pe site.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Site-ul Societății, https://www.euro7.ro utilizează module cookie
          pentru a menține autentificarea validă timp îndelungat, pentru a
          reține preferințele de navigare și utilizare ale site-ului, a
          monitoriza numărul total de vizitatori și paginile vizualizate, pentru
          a administra, utiliza și îmbunătăți site-ul și celelalte servicii și
          sisteme ale Societății și pentru a furniza servicii și conținut care
          vă sunt adaptate.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          În cazul în care ați fost de acord cu includerea modulelor cookie în
          browserul dumneavoastră prin intermediul banner-ului de pe prima
          pagină de pornire a site-ului web, vă rugam să țineți cont de faptul
          că acceptul dumneavoastră poate fi retras oricând doriți. Browserele
          actuale (navigatoarele web) oferă posibilitatea de a seta/dezactiva
          cookie-urile. Pentru a face acest lucru, în general, se accesează
          rubrica Opțiuni (Options) sau Preferințe (Preferences).
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Cu toate acestea, dacă nu acceptați cookie-urile, este posibil să nu
          puteți utiliza toate porțiunile site-ului nostru sau toate
          funcționalitățile acestuia.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Rețineți că dezactivarea acestor tehnologii poate interfera cu
          performanța și caracteristicile serviciilor oferite.
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1.2rem" fontWeight={"bold"}>
          X. MODIFICAREA ȘI ACTUALIZAREA POLITICII DE CONFIDENȚIALITATE
        </Typography>
        <Typography fontFamily="Catesque" fontSize="1rem">
          Ne rezervăm dreptul de a ne modifica, atunci când considerăm oportun,
          practicile de protecţie a datelor și de actualiza și modifica prezenta
          notă de informare în orice moment. Din acest motiv, vă încurajăm să
          verificaţi periodic prezenta notă de informare.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};
