import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import information from "../assets/information.jpg";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Title } from "../components/Title";
import { useState } from "react";
import { ConsumEntity } from "../types";
import axios from "axios";
import { Footer } from "../components/Footer";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ConsultareConsum: React.FC = () => {
  const [cod, setCod] = useState<string>("");
  const [result, setResult] = useState<ConsumEntity | null>(null);
  const [initial, setInitial] = useState<boolean>(true);

  const changeCod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCod(event.target.value);
  };

  const handleSearch = () => {
    axios
      .get(`https://secure.euro7.ro:8443/api/consum/find?cod=${cod}`)
      .then((response) => {
        setResult(response.data);
        setInitial(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const handlePdfClick = (event: any, path: string) => {
  //   event?.preventDefault();
  //   window.open(`${process.env.PUBLIC_URL}/documente/${path}`, "_blank");
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={information}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={4} specialOverlay={false} />
        <JumbotronTitle title="Consultare Consum" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography fontFamily="Catesque" fontSize="2rem" sx={{ mb: "25px" }}>
          Cod Localități
        </Typography>
        <Box
          sx={{
            width: "10%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          <Typography
            fontFamily="Catesque"
            component={"div"}
            display={"flex"}
            color={"#0054a6"}
            gap={"10px"}
          >
            BACU:
            <Typography fontFamily="Catesque" color={"black"}>
              1
            </Typography>{" "}
          </Typography>
          <Typography
            fontFamily="Catesque"
            component={"div"}
            display={"flex"}
            color={"#0054a6"}
            gap={"10px"}
          >
            COSOBA:
            <Typography fontFamily="Catesque" color={"black"}>
              2
            </Typography>{" "}
          </Typography>
          <Typography
            fontFamily="Catesque"
            component={"div"}
            display={"flex"}
            color={"#0054a6"}
            gap={"10px"}
          >
            JOITA:
            <Typography fontFamily="Catesque" color={"black"}>
              3
            </Typography>{" "}
          </Typography>
          <Typography
            fontFamily="Catesque"
            component={"div"}
            display={"flex"}
            color={"#0054a6"}
            gap={"10px"}
          >
            SABARENI:
            <Typography fontFamily="Catesque" color={"black"}>
              4
            </Typography>{" "}
          </Typography>
          <Typography
            fontFamily="Catesque"
            component={"div"}
            display={"flex"}
            color={"#0054a6"}
            gap={"10px"}
          >
            BOLINTIN-DEAL:
            <Typography fontFamily="Catesque" color={"black"}>
              5
            </Typography>{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0 auto",
            gap: "15px",
            mt: "25px",
            "@media (max-width: 1300px)": {
              width: "80%",
            },
            "@media (max-width: 600px)": {
              flexDirection: "column",
              gap: "15px",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              fontFamily="Catesque"
              fontSize="1rem"
              fontWeight={"bold"}
            >
              Cod localitate + Cod Client
            </Typography>
            <TextField
              name={"cod"}
              id={"cod"}
              size={"small"}
              value={cod}
              onChange={changeCod}
              sx={{
                "& .MuiInputBase-input": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                  mb: "225px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          </Box>

          <Button
            variant={"contained"}
            sx={{
              backgroundColor: "#0054a6",
              "&:hover": {
                backgroundColor: "#0054a6",
                color: "white",
              },
              fontFamily: "Catesque",
            }}
            type="submit"
            onClick={handleSearch}
          >
            Cauta
          </Button>
        </Box>
        <Box sx={{ width: "50%", margin: "0 auto" }}>
          {!result && initial === false ? (
            <Typography fontFamily="Catesque" sx={{ mt: "5vh" }}>
              Cod Client sau Adresa gresita.
            </Typography>
          ) : (
            <TableContainer
              component={Box}
              sx={{ mt: "5vh", "& *": { fontFamily: "Catesque" } }}
            >
              <Table
                sx={{ minWidth: 675 }}
                size="small"
                aria-label="payments table"
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "white" }}>
                    <StyledTableCell sx={{ fontWeight: "bold" }}>
                      Cod
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                      Nr. Factura
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="center">
                      De Plata
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                      Index Vechi
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                      Index Nou
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                      Consum
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                      Perioada
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result && (
                    <TableRow>
                      <StyledTableCell sx={{ fontWeight: "bold" }}>
                        {result.cod}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        {result.nrFactura}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontWeight: "bold" }}
                        align="center"
                      >
                        {result.dePlata}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        {result.indexVechi}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        {result.indexNou}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        {result.consumT}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        {result.perioada}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Catesque",
  },
}));
