import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import "./LoginPage.css";
import f1 from "../assets/f1.jpeg";
import logo1 from "../assets/logo1.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { forgotPassword } from "../services/AuthService";
import { navigateToPage } from "../utils";

export const ForgotPasswordPage: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const navigate = useNavigate();

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };
  const handleCloseSuccessSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (username) {
      await forgotPassword(username)
        .then((res) => {
          if (res === true) {
            setOpenSuccessSnackbar(true);
          } else {
            setOpenErrorSnackbar(true);
          }
        })
        .catch((e) => {
          setOpenErrorSnackbar(true);
        });
    }
  };

  return (
    <Box className="login-page">
      <Box className="login-left">
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ position: "absolute", bottom: 0, left: 0, padding: "20px" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            Ceva a mers gresit!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSuccessSnackbar}
          sx={{ position: "absolute", bottom: 0, left: 0, padding: "20px" }}
        >
          <Alert
            onClose={handleCloseSuccessSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Verificati emailul! Parola resetata.
          </Alert>
        </Snackbar>
        <Box>
          <img
            src={logo1}
            alt="login-logo"
            height={"50px"}
            onClick={() => {
              navigateToPage(navigate, "/");
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box className="login-header">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Resetare Parola MyEuroSeven
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "80%",
            textAlign: "center",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                margin="normal"
                id="username"
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                variant="standard"
                value={username}
                onChange={changeUsername}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Link
                href="/login"
                variant="body2"
                style={{
                  textDecoration: "none",
                  color: "#0054a6",
                  fontWeight: "bold",
                  marginTop: "2%",
                }}
              >
                {"Login"}
              </Link>
            </Box>
            <Button
              type="submit"
              variant="contained"
              style={{
                background: "#0054a6",
                textTransform: "none",
                fontSize: "15px",
              }}
              onClick={() => handleSubmit}
              sx={{ mt: "10%", mb: 2 }}
            >
              Resetare
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="login-right" sx={{ objectFit: "cover" }}>
        <img className="login-picture" src={f1} alt="login-page" />
      </Box>
    </Box>
  );
};
