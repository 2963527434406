import { useEffect, useState } from "react";
import { OfertaEntity } from "../../types";
import { DataGrid, GridColDef, roRO } from "@mui/x-data-grid";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { reverseArray } from "../../utils";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { OfertaService } from "../../services/OfertaService";
import { ModalOferta } from "../../components/admin/modal/ModalOferta";

export const AdminOferte: React.FC = () => {
  const [oferte, setOferte] = useState<OfertaEntity[]>([]);

  const [ofertaId, setOfertaId] = useState<number>();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "prenume",
      headerName: "Prenume",
      width: 150,
    },
    {
      field: "nume",
      headerName: "Nume",
      width: 150,
    },
    {
      field: "judet",
      headerName: "Județ",
      width: 150,
    },
    {
      field: "localitate",
      headerName: "Localitate",
      width: 150,
    },
    {
      field: "forWho",
      headerName: "Persoană",
      headerAlign: "center",
      width: 125,
      align: "center",
      renderCell: (params) => {
        const forWho = params.value;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {forWho === "fizica" ? (
              <PersonIcon sx={{ color: "#0054a6" }} />
            ) : (
              <ApartmentIcon sx={{ color: "#0054a6" }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 125,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <Button
            variant="contained"
            color="primary"
            sx={{
              height: "30px",
              fontFamily: "Catesque",
              backgroundColor: "#0054a6",
            }}
            onClick={() => {
              setOfertaId(id);
              handleOpenModal();
            }}
          >
            Detalii
          </Button>
        );
      },
    },
  ];

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const updateUserInUsersList = (ofertaId: number) => {
    try {
      const allUsers = oferte;
      if (allUsers) {
        const updatedUsers = [...allUsers];

        // Find the index of the user to replace
        const userIndex = updatedUsers.findIndex((u) => u.id === ofertaId);

        allUsers.splice(userIndex, 1);
        
        setOferte(allUsers);
        setOpenSnackbar(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOferte = async () => {
    await OfertaService.getAll()
      .then((res) => {
        setOferte(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchOferte();
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ position: "absolute", bottom: 0, left: 0, padding: "20px" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Oferta acceptata cu succes!
        </Alert>
      </Snackbar>
      {ofertaId !== undefined && (
        <ModalOferta
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          ofertaId={ofertaId}
          setOferte={setOferte}
          updateUserInUsersList={updateUserInUsersList}
          oferte={oferte}
        />
      )}
      <Box
        sx={{
          width: "100%",
          position: "relative",
          height: "8vh",
          textAlign: "left",
        }}
      >
        <Typography
          fontFamily={"Catesque"}
          fontWeight={"bold"}
          fontSize={"32px"}
        >
          Oferte
        </Typography>
      </Box>
      <Box sx={{ mb: "10vh" }}>
        <Box
          sx={{
            overflowX: "hidden",
            boxShadow: "0 0 16px -8px black",
            borderRadius: "25px",
          }}
        >
          <DataGrid
            rows={reverseArray(oferte)}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  accepted: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              fontFamily: "Catesque",
              backgroundColor: "transparent",
              borderRadius: "25px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E4E5E6",
              },
              "& .MuiDataGrid-columnsContainer": {
                marginLeft: "16px", // Adjust the value as needed
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
