import { Title } from "../components/Title";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  NativeSelect,
} from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import consultconsum from "../assets/consultconsum.jpg";
import { CardReview } from "../mui-treasury/card-review";
import tarife3 from "../assets/tarife3.jpg";
import clienticasnici from "../assets/clienticasnici.jpg";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

import reclamatii from "../assets/reclamatii.jpg";
import { useState } from "react";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ReclamatiiPage: React.FC = () => {
  const [val, setVal] = useState<string>("BD");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={reclamatii}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Reclamații" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1500px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box sx={{ width: "100%", height: `calc(30%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Ai o reclamație?
                </Typography>
              </Box>

              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                sx={{ mb: "10px" }}
              >
                Te rugăm să ne trimiți în mesaj detaliile necesare unde să ne
                spui cât mai detaliat problema pe care o întâmpini, inclusiv
                contextul și orice informații relevante, iar noi revenim către
                tine în cel mai scurt timp posibil cu un răspuns complet și
                soluții pentru situația ta, astfel încât să putem rezolva
                eficient și eficace orice dificultate sau întrebare ai putea
                avea, oferindu-ți suportul și asistența necesară pentru a te
                ajuta să depășești această situație și să îți recâștigi starea
                de bine și încrederea în serviciile noastre.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                sx={{ mb: "10px" }}
              >
                Vezi modalitatile de contact mai jos.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(45%)`,
            display: "flex",
            background: "#f2f2f2",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Intră în legatură cu noi
              </Typography>
            </Box>
            <Box sx={{ width: "100%", whiteSpace: "pre-wrap" }}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={consultconsum}
                    title={"Call Center"}
                    text={`Luni - Vineri: 9:00 - 16:00 ${"\n"}Telefon: 0374 627 726`}
                    location={"/contact"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={clienticasnici}
                    title={"Relații cu Clienții"}
                    text={`Vizitează-ne la una din locațiile noastre`}
                    location={"/contact"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={tarife3}
                    title={"Curier sau poștă"}
                    text={`Str. Horatiu nr. 11${"\n"}București, Sector 1,${"\n"}010833${"\n"}Luni - Vineri: 09:00 - 16:00`}
                    location={"/contact"}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: `calc(25%)`, display: "flex" }}>
          <Box
            sx={{
              width: "60%",
              margin: "0 auto",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              textAlign: "left",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <NotificationsActiveOutlinedIcon
                sx={{
                  color: "#0054a6",
                  height: "60px",
                  width: "60px",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Urgențe
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", mr: "25px" }}>
              <Typography fontFamily={"Catesque"} fontSize={"1.2rem"}>
                În cazul în care simți miros de gaze naturale sau observi
                defecțiuni tehnice în alimentarea cu gaze naturale sau
                electricitate, anunță operatorul de distribuție sau de rețea din
                zona ta.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "150px",
                "@media (max-width: 800px)": {
                  flexDirection: "column",
                  gap: "15px",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#0054a6",
                  color: "white",
                  fontFamily: "Catesque",
                }}
              >
                Gaze Naturale
              </Button>

              <FormControl sx={{ width: "30%" }}>
                <NativeSelect
                  value={val}
                  aria-label="Localitate"
                  onChange={(e) => setVal(e.target.value)}
                  inputProps={{
                    name: "localitate",
                  }}
                  sx={{ fontFamily: "Catesque" }}
                >
                  <option value={"BD"}>Bolintin-Deal, Giurgiu</option>
                  <option value={"SB"}>Săbăreni, Giurgiu</option>
                  <option value={"JO"}>Joița, Giurgiu</option>
                  <option value={"CO"}>Cosoba, Giurgiu</option>
                  <option value={"BA"}>Bâcu, Giurgiu</option>
                </NativeSelect>
              </FormControl>
              <Box sx={{ mt: "5px" }}>
                {val === "BD" ? (
                  <Typography fontFamily={"Catesque"} fontSize={"1.2rem"}>
                    0374 629 219
                  </Typography>
                ) : (
                  <Typography fontFamily={"Catesque"} fontSize={"1.2rem"}>
                    0374 628 672
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
