import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import CheckBox from "@mui/icons-material/CheckBox";
import dreptClientFinal from "../assets/dreptClientFinal.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const DrepturiObligatiiClientFinalPage: React.FC = () => {

  // const handlePdfClick = (event: any) => {
  //   event?.preventDefault();
  //   window.open(
  //     `${process.env.PUBLIC_URL}/Drepturi și obligații ale clientului final de gaze naturale.pdf`,
  //     "_blank"
  //   );
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={dreptClientFinal}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Drepturi și Obligații Clienți Finali" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1200px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Client de gaze naturale, beneficiezi de urmatoarele drepturi:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Posibilitatea de a-ti alege furnizorul;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              In cazul in care ai fost preluat in regim de ultima instanta, iti
              poti schimba tipul contractului la furnizarea gazelor in regim
              concurential;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Ai dreptul sa soliciti in mod gratuit o copie a contractului de
              furnizare a gazelor naturale înainte de încheierea sau confirmarea
              încheierii acestuia;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              La incheierea contractului de furnizare a gazelor naturale, este
              datoria noastra sa te informam cu datele de contact ale punctului
              unic de contact și ale punctului de lucru cel mai apropiat de
              respectivul loc de consum;
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Drept client de gaze naturale, ai urmatoarele obligatii:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să ne notifici de orice schimbare a datelor de identificare din
              contractul de furnizare a gazelor naturale, in cel mult 30 de zile
              calendaristice de la data modificarii;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să achiti integral factura scadentă reprezentând contravaloarea
              consumului de gaze naturale, în termenul și în condițiile
              prevăzute în contractul de furnizare a gazelor naturale;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să achiti integral factura scadentă reprezentând contravaloarea
              consumului de gaze naturale furnizate în regim de ultimă instanță;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să achite dobânzile penalizatoare pentru întârzierea în efectuarea
              plății facturii reprezentând contravaloarea consumului de gaze
              naturale în termenul și în condițiile prevăzute în contractul de
              furnizare a gazelor naturale;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să achiți tariful pentru activitatea de reluare a alimentării la
              locul de consum, în cazul în care ai fost deconectat;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Modalitățile de inițiere a procedurilor de soluționare a
              litigiilor și informații privind procedura de soluționare a
              plângerilor clienților finali;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Să permiti accesul reprezentantului distribuitorului pentru
              întreruperea/limitarea a parametrilor tehnici/reluarea a
              alimentării cu gaze naturale la locul de consum, montarea,
              demontarea, sigilarea, întreținerea, verificarea, înlocuirea sau
              citirea indexului inregistrat pe contor, precum și pentru a
              întreține, verifica sau remedia defecțiunile intervenite la
              instalații, când acestea se află amplasate pe proprietatea
              clientului final.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            color={"black"}
            component={"span"}
          >
            Detalii privind drepturile și obligațiile clientului de gaze
            naturale se pot regasi in Ord. 29/2016 sau{" "}
            <span
              onClick={() =>
                (window.location.href = `${process.env.PUBLIC_URL}/Drepturi și obligații ale clientului final de gaze naturale.pdf`)
              }
              rel="noopener noreferrer"
              style={{
                color: "#0054a6",
                textDecoration: "underline",
                cursor: "pointer",
                marginLeft: "1px",
              }}
            >
              aici
            </span>
            .
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
