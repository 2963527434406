import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { OfertaEntity } from "../../../types";
import { OfertaService } from "../../../services/OfertaService";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
export const ModalOferta = (props: {
  openModal: boolean;
  handleCloseModal: () => void;
  ofertaId: number;
  setOferte: React.Dispatch<React.SetStateAction<OfertaEntity[]>>;
  updateUserInUsersList: (ofertaId: number) => void;
  oferte: OfertaEntity[];
}) => {
  const { openModal, handleCloseModal, ofertaId, setOferte, updateUserInUsersList, oferte } = props;

  const [oferta, setOferta] = useState<OfertaEntity | null>();

  useEffect(() => {
    const fetchOferta = async () => {
      await OfertaService.findById(ofertaId).then((res) => {
        setOferta(res);
      });
    };
    if (ofertaId) {
      fetchOferta();
    }
  }, [ofertaId]);

  const handleAcceptOferta = async () => {
    try {
      if (ofertaId !== undefined) {
        const res = await OfertaService.acceptOferta(ofertaId);
        if (res === true) {
          setOferte(
            oferte.filter((obj) => {
              return obj !== oferta;
            })
          );
          updateUserInUsersList(ofertaId);
          handleCloseModal();
        }
      }
    } catch (error) {
      console.error("Error accepting oferta: ", error);
    }
  };

  const handleDenyOferta = async () => {
    if (ofertaId !== undefined) {
      await OfertaService.denyOferta(ofertaId).then((res) => {
        if (res === true) {
          setOferte(
            oferte.filter((obj) => {
              return obj !== oferta;
            })
          );
          handleCloseModal();
        }
      });
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "grid",
          gridRowGap: "20px",
          padding: "50px",
          margin: "10px 300px",
          position: "absolute" as "absolute",
          top: "50%",
          left: "40%",
          transform: "translate(-50%, -50%)",
          width: "100vh",
          minHeight: "50vh",
          bgcolor: "background.paper",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          fontWeight={"bold"}
          fontFamily={"Catesque"}
          sx={{ mb: "25px" }}
        >
          Detalii Oferta
        </Typography>
        {oferta && oferta.forWho === "fizica" && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                name={"prenume"}
                id={"prenume"}
                label={"Prenume"}
                value={oferta.prenume}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"nume"}
                id={"nume"}
                label={"Nume"}
                value={oferta.nume}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"email"}
                id={"email"}
                label={"Email"}
                value={oferta.email}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"telefon"}
                id={"telefon"}
                label={"Telefon"}
                value={oferta.telefon}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"adresa"}
                id={"adresa"}
                label={"Adresă"}
                value={oferta.adresa}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"detalii"}
                id={"nume"}
                label={"Detalii"}
                value={oferta.detalii}
                disabled
                multiline
                rows={8}
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black",
                    fontFamily: "Catesque",
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black",
                    fontFamily: "Catesque",
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
            </Box>
          </>
        )}
        {oferta && oferta.forWho === "juridica" && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                name={"numeCompanie"}
                id={"numeCompanie"}
                label={"Numele Companiei"}
                value={oferta.numeCompanie}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"cui"}
                id={"cui"}
                label={"CUI"}
                value={oferta.cui}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"domeniuActivitate"}
                id={"domeniuActivitate"}
                label={"Domeniu de Activitate"}
                value={oferta.domeniuActivitate}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
              <TextField
                name={"consumMediu"}
                id={"consumMediu"}
                label={"Consum Mediu"}
                value={oferta.consumMediu}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
              <TextField
                name={"prenume"}
                id={"prenume"}
                label={"Prenume"}
                value={oferta.prenume}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"nume"}
                id={"nume"}
                label={"Nume"}
                value={oferta.nume}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"email"}
                id={"email"}
                label={"Email"}
                value={oferta.email}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
              <TextField
                name={"telefon"}
                id={"telefon"}
                label={"Telefon"}
                value={oferta.telefon}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />

              <TextField
                name={"adresa"}
                id={"adresa"}
                label={"Adresă"}
                value={oferta.adresa}
                disabled
                sx={{
                  width: "50%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "25px",
            width: "50%",
            margin: "0 auto",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color="success"
            sx={{
              fontFamily: "Catesque",
              justifyContent: "flex-start",
              position: "relative",
              width: "125px",
            }}
            endIcon={
              <CheckIcon sx={{ position: "absolute", top: "20%", right: 10 }} />
            }
            onClick={() => {
              handleAcceptOferta();
            }}
          >
            Aprobă
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              fontFamily: "Catesque",
              justifyContent: "flex-start",
              position: "relative",
              width: "125px",
            }}
            endIcon={
              <DoNotDisturbIcon
                sx={{ position: "absolute", top: "20%", right: 10 }}
              />
            }
            onClick={handleDenyOferta}
          >
            Respinge
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
