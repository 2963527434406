import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import j5 from "../assets/j5.jpeg";
import CheckBox from "@mui/icons-material/CheckBox";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const TermeniSiConditiiPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={j5}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Termeni și Condiții" />
      </Box>
      <Box
        sx={{
          width: "60%",
          margin: "0 auto",
          height: "1250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          "@media (max-width: 1400px)": {
            width: "80%",
            height: "1200px",
          },
          "@media (max-width: 960px)": {
            height: "1700px",
            width: "80%",
          },
          "@media (max-width: 500px)": {
            height: "1800px",
            width: "90%",
          },
          textAlign: "left",
        }}
      >
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.7rem"}
          fontWeight={"bold"}
          sx={{ mb: "35px" }}
        >
          Termeni și Condiții
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.3rem"}
          fontWeight={"bold"}
          sx={{ mb: "15px" }}
        >
          1. DREPTURI DE AUTOR
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          Euro7 beneficiază de protecția legii drepturilor de autor. Conținutul
          site-ului este proprietatea EURO SEVEN INDUSTRY SRL informații, texte,
          imagini, grafică, simboluri, programe, scripturi, etc. Datele de pe
          site și de pe server nu pot fi folosite fără acordul scris al
          proprietarului. Folosirea acestora fără acordul Euro7 se pedepseste în
          conformitate cu legislația în vigoare.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Vă rugăm să raportați eventualele încălcări ale drepturilor de autor
          la adresa politex.sportswear@gmail.com
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.3rem"}
          fontWeight={"bold"}
          sx={{ mb: "15px" }}
        >
          2. MODALITĂȚI DE PLATĂ
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          Plata numerar: plata numerar, direct la unul dintre punctele de lucru.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          Plata POS: plata cu cardul bancar prin intermediu POS la unul dintre
          punctele de lucru.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Plata online prin transfer bancar: plata prin transfer bancar in unul
          dintre conturile noastre:
        </Typography>
        <Box sx={{ display: "flex" }}>
          <CheckBox sx={{ color: "green", mr: "10px" }} />
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            RO71 BACX 0000 0017 7375 2000 - UNICREDIT BANK
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <CheckBox sx={{ color: "green", mr: "10px" }} />
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            RO16 BRDE 426S V443 5866 4260 - BRD - Agentia Vergului
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mb: "25px" }}>
          <CheckBox sx={{ color: "green", mr: "10px" }} />
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            RO69TREZ7005069XXX005713 - TREZ OPER BUCURESTI
          </Typography>
        </Box>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.3rem"}
          fontWeight={"bold"}
          sx={{ mb: "15px" }}
        >
          3. EXONERARE DE RĂSPUNDERE
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Euro7 depune toate eforturile pentru a vă furniza prin acest website
          informații actualizate și exacte, prezentate în mod cât mai clar, însă
          nu garantează că website-ul nu conține erori. Euro7 nu este
          responsabilă pentru eventuale erori sau omisiuni, inadvertențe sau
          descriere eronată a informațiilor. În cazul în care apar eventuale
          erori sau neconcordanțe, Euro7 depune toate diligențele pentru a
          remedia.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Nu ne asumăm nicio responsabilitate și nu oferim nicio garanție pentru
          acuratețea sau conținutul paginilor web la care este conectat acest
          website. De asemenea, nu ne asumăm răspunderea pentru orice fel de
          daune sau pierderi suferite ca urmare a accesării sau folosirii
          oricărei informații furnizate pe acest website sau pe orice pagina web
          la care acest website este conectat.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Euro7 nu poate garanta securitatea traficului pe internet și
          confidențialitatea documentelor și materialelor transmise către noi
          prin intermediul internetului.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.3rem"}
          fontWeight={"bold"}
          sx={{ mb: "15px" }}
        >
          4. FORȚĂ MAJORĂ
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Nu suntem răspunzători în niciun fel în cazul unui eveniment de forță
          majoră, respectiv orice eveniment care excede controlul nostru, care
          ne împiedică să respectăm oricare dintre obligațiile ce decurg din
          acești Termeni & Condiții, inclusiv dar fără a se limita la probleme
          tehnice neprevăzute, greve, revolte etc.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.3rem"}
          fontWeight={"bold"}
          sx={{ mb: "15px" }}
        >
          5. ACTUALIZĂRI
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          Este posibil să fie necesar să revizuim în viitor Termenii &
          Condițiile.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          În cazul în care modificăm conținutul acestora, vom publica varianta
          revizuită/actualizată pe acest website.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "10px" }}
        >
          După data la care varianta actualizată a Termenilor & Condițiilor a
          fost publicată, accesarea website-ului nostru reprezintă acceptul
          dumneavoastră cu privirii la respectivii Termeni & Condiții.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};
