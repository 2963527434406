import lunr from "lunr";
import WindowIcon from "@mui/icons-material/Window";
import EmailIcon from "@mui/icons-material/Email";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import PaidIcon from "@mui/icons-material/Paid";
import React from "react";
import GasMeter from "@mui/icons-material/GasMeter";
import ContactPage from "@mui/icons-material/ContactPage";
import Speed from "@mui/icons-material/Speed";
import Help from "@mui/icons-material/Help";

type Page = {
  id: number;
  content: string;
  selectedTab: string;
  displayName: string;
  icon?: () => React.JSX.Element;
};

export const pagesAdmin: Page[] = [
  {
    id: 1,
    content: "De luna trecuta Facturi Restante Utilizatori Plati Profit Trafic",
    selectedTab: "acasa",
    displayName: "Acasă",
    icon: (): React.JSX.Element => <WindowIcon />,
  },
  {
    id: 2,
    content: "Clienți Cauta utilizator Cauta client clienti caută",
    selectedTab: "clienti",
    displayName: "Clienți",
    icon: (): React.JSX.Element => <PeopleAltIcon />,
  },
  {
    id: 3,
    content: "Anunțuri anunturi",
    selectedTab: "anunturi",
    displayName: "Anunțuri",
    icon: (): React.JSX.Element => <EmailIcon />,
  },
  {
    id: 4,
    content: "Facturi Cauta factura",
    selectedTab: "facturi",
    displayName: "Facturi",
    icon: (): React.JSX.Element => <PaidIcon />,
  },
  {
    id: 5,
    content: "Statistici",
    selectedTab: "statistici",
    displayName: "Statistici",
    icon: (): React.JSX.Element => <BarChartIcon />,
  },
  {
    id: 6,
    content: "Setări Incarca Imagine Detalii Cont Setari",
    selectedTab: "setari",
    displayName: "Setări",
    icon: (): React.JSX.Element => <SettingsIcon />,
  },
];

export const idxAdmin = lunr(function () {
  this.ref("id");
  this.field("content");

  pagesAdmin.forEach((page) => {
    this.add(page);
  });
});

export const pagesIncasari: Page[] = [
  {
    id: 1,
    content:
      "Facturi Restante Creeare Factura Creeare Factură Inregistreaza Plata Înregistrează Plată Email Facturi Ghid Utilizator Legal Setari Setări Acasa Acasă Deconecteazate Deconecteaza-te Deconectează-te",
    selectedTab: "acasa",
    displayName: "Acasă",
    icon: (): React.JSX.Element => <WindowIcon />,
  },
  {
    id: 2,
    content:
      "Plati Plați Cauta plata Caută plată Creeare Plata Creeare Plată Creeaza Plata Creează Plată",
    selectedTab: "plati",
    displayName: "Plăți",
    icon: (): React.JSX.Element => <PaidIcon />,
  },
  {
    id: 3,
    content:
      "Facturi Cauta factura Caută factură Creeaza Factura Creează Factură Inregistreaza Factura Înregistrează Factura Facturi",
    selectedTab: "facturi",
    displayName: "Facturi",
    icon: (): React.JSX.Element => <EmailIcon />,
  },
  {
    id: 4,
    content:
      "Contor Contoare Cauta contor Caută contor Creeaza Contor Creează Contor Aproba contor Aprobă contor",
    selectedTab: "contor",
    displayName: "Contoare",
    icon: (): React.JSX.Element => <GasMeter />,
  },
  {
    id: 6,
    content: "Setări Incarca Imagine Detalii Cont Setari",
    selectedTab: "setari",
    displayName: "Setări",
    icon: (): React.JSX.Element => <SettingsIcon />,
  },
];

export const idxIncasari = lunr(function () {
  this.ref("id");
  this.field("content");

  pagesIncasari.forEach((page) => {
    this.add(page);
  });
});

export const pagesClient: Page[] = [
  {
    id: 1,
    content:
      "rest de plata rest de plată facturi restante plati recente plați recente index vechi index nou acasa acasă",
    selectedTab: "acasa",
    displayName: "Acasă",
    icon: (): React.JSX.Element => <WindowIcon />,
  },
  {
    id: 2,
    content:
      "rest de plata rest de plată restul tău de plată restul tau de plata facturi restante facturi platite plateste factura platește factură plateste facturi factura descarca descarcă",
    selectedTab: "facturi",
    displayName: "Facturi",
    icon: (): React.JSX.Element => <PaidIcon />,
  },
  {
    id: 3,
    content:
      "contract contractul tău durată contract tip de contract dată început contract dată terminat contract data inceput data terminat",
    selectedTab: "contract",
    displayName: "Contract",
    icon: (): React.JSX.Element => <ContactPage />,
  },
  {
    id: 4,
    content:
      "citire contor citește contor citeste contor citire contoare serie contor inregistreaza citire contor înregistrează citire contor",
    selectedTab: "contor",
    displayName: "Contor",
    icon: (): React.JSX.Element => <GasMeter />,
  },
  {
    id: 5,
    content: "consum vezi consum",
    selectedTab: "consum",
    displayName: "Consum",
    icon: (): React.JSX.Element => <Speed />,
  },
  {
    id: 5,
    content: "ajutor intrebare întrebare intrebari întrebări faq",
    selectedTab: "ajutor",
    displayName: "Ajutor",
    icon: (): React.JSX.Element => <Help />,
  },
  {
    id: 6,
    content: "Setări Incarca Imagine Detalii Cont Setari",
    selectedTab: "setari",
    displayName: "Setări",
    icon: (): React.JSX.Element => <SettingsIcon />,
  },
];

export const idxClient = lunr(function () {
  this.ref("id");
  this.field("content");

  pagesClient.forEach((page) => {
    this.add(page);
  });
});

export const idxNormal = lunr(function () {
  this.ref("id");
  this.field("content");

  pagesClient.forEach((page) => {
    this.add(page);
  });
});

export const pagesNormal: Page[] = [
  {
    id: 1,
    content:
      "client casnic clienti casnici informatii plafonare clienți casnici informații informati",
    selectedTab: "/clienti-casnici",
    displayName: "Clienți Casnici",
  },
  {
    id: 2,
    content:
      "client business client juridic clienti casnici clienti business clienti juridici informatii plafonare clienți casnici informații informati",
    selectedTab: "/clienti-casnici",
    displayName: "Clienți Casnici",
  },
  {
    id: 3,
    content: "Servicii Online servicii online servici online",
    selectedTab: "/servicii-online",
    displayName: "Servicii Online",
  },
  {
    id: 4,
    content: "Consultare Consum consum consultare consum consumul meu",
    selectedTab: "/consultate-consum",
    displayName: "Consultare Consum",
  },
  {
    id: 5,
    content: "informatii utile informati informații",
    selectedTab: "/clienti-casnici",
    displayName: "Informatii Utile -> Clienti Casnici",
  },
  {
    id: 6,
    content: "informatii utile informati informații",
    selectedTab: "/clienti-business",
    displayName: "Informatii Utile -> Clienti Business",
  },
  {
    id: 7,
    content: "despre noi Despre Noi",
    selectedTab: "/despre",
    displayName: "Despre Noi",
  },
  {
    id: 8,
    content:
      "presa stiri noutati Presa Stiri Noutati Presă Știri Noutăți presă știri noutăți",
    selectedTab: "/noutati",
    displayName: "Noutăți",
  },
  {
    id: 9,
    content:
      "Contact contact puncte de lucru adresa telefon email Puncte De Lucru Adresa Telefon Email Adresă adresă call center Call Center",
    selectedTab: "/contact",
    displayName: "Contact/Puncte de Lucru",
  },
  {
    id: 10,
    content: "Gaze Naturale gaze naturale",
    selectedTab: "/gaze-naturale",
    displayName: "Gaze Naturale",
  },
  {
    id: 11,
    content:
      "intrebari frecvente ajutor Intrebari Întrebări Frecvente Ajutor întrebări frecvente",
    selectedTab: "/intrebari-frecvente",
    displayName: "Întrebări Frecvente",
  },
  {
    id: 12,
    content:
      "reclamatii Reclamatii Reclamații reclamații plangeri Plângeri plângeri solutionare Solutionare soluționare Soluționare plângere Plângere plângere",
    selectedTab: "/reclamatii",
    displayName: "Reclamații",
  },
  {
    id: 13,
    content: "Cont client Contul meu Contul Meu contul meu cont client",
    selectedTab: "/contul-meu",
    displayName: "Contul Meu",
  },
  {
    id: 14,
    content:
      "Plată Online plată online plata online Plata Online modalități de plată modalitati de plata Modalități de Plată",
    selectedTab: "/modalitati-de-plata",
    displayName: "Modalități de Plată",
  },
  {
    id: 15,
    content:
      "Factura factura factură Factură electronica factura mea explicata factura explicata",
    selectedTab: "/factura-electronica",
    displayName: "Factura Electronică",
  },
  {
    id: 16,
    content: "Tarife Reglementate tarife reglementate reglementare tarif",
    selectedTab: "/reglementare-tarife",
    displayName: "Reglementare Tarife",
  },
  {
    id: 17,
    content:
      "Reluarea Furnizarii reluarea furnizarii reluarea furnizării Reluarea Furnizării Preaviz deconectare preaviz Deconectare",
    selectedTab: "/reluare-furnizare-gaze",
    displayName: "Reluare Furnizare Gaze",
  },
  {
    id: 18,
    content: "lege Lege legislatie Legislatie legislație Legislație",
    selectedTab: "/legislatie",
    displayName: "Legislație",
  },
  {
    id: 20,
    content:
      "Furnizor De Ultima Instanță Furnizor De Ultima Instanță furnizor de ultima instanta Furnizor de Ultima Instanță FUI fui",
    selectedTab: "/fui",
    displayName: "Furnizor De Ultima Instanță",
  },
  {
    id: 21,
    content: "ANRE anre",
    selectedTab: "/anre",
    displayName: "ANRE",
  },
  {
    id: 22,
    content: "POSF posf",
    selectedTab: "/posf",
    displayName: "POSF",
  },
  {
    id: 23,
    content: "Comparator Preț comparator preț comparator pret",
    selectedTab: "/c",
    displayName: "Comparator Preț",
  },
  {
    id: 24,
    content:
      "Contract contract Contract și Condiții Generale contract si conditii generale contract și condiții generale contractul meu Contractul Meu",
    selectedTab: "/contract-si-conditii",
    displayName: "Contract și Condiții Generale",
  },
  {
    id: 25,
    content: "Firme Autorizate firme autorizate",
    selectedTab: "/firme-autorizate",
    displayName: "Firme Autorizate",
  },
  {
    id: 26,
    content:
      "Termeni Si Conditii Termenii Si Conditiile termeni si conditii termenii si condițiile termeni și condiții",
    selectedTab: "/termeni-si-conditii",
    displayName: "Termeni Și Condiții",
  },
  {
    id: 27,
    content:
      "Cookies cookies politica cookies Politică Cookies politică cookies Polotica Cookies",
    selectedTab: "/politica-cookies",
    displayName: "Politică Cookies",
  },
  {
    id: 28,
    content:
      "protectia datelor gdpr protecția datelor GDPR Protectia Datelor Protecția Datelor",
    selectedTab: "/protectia-datelor",
    displayName: "Protecția Datelor",
  },
];
