import { useEffect, useState } from "react";
import { AnuntEntity } from "../types";
import { AnuntService } from "../services/AnuntService";
import { Box } from "@mui/material";
import { Footer } from "../components/Footer";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Title } from "../components/Title";
import information from "../assets/information.jpg";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const NoutatePage: React.FC = () => {
  const [noutate, setNoutate] = useState<AnuntEntity | null>(null);

  useEffect(() => {
    const url = window.location.href;
    const segments = url.split("/");
    const lastSegment = segments.pop() || segments.pop();
    const noutateId = Number(lastSegment);

    if (noutateId !== null && noutateId !== undefined) {
      AnuntService.findById(noutateId).then((res) => {
        setNoutate(res);
      });
    }
  }, []);

  const getMarkdownText = (markdownText: string) => {
    const rawMarkup = marked(markdownText) as string;
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={
            noutate?.picture
              ? `data:image/jpeg;base64,${noutate?.picture}`
              : information
          }
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title={noutate?.title!} />
        
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        {noutate && (
          <Box
            sx={{
              height: "100%",
              width: "60%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              padding: "55px",
            }}
          >
            <div dangerouslySetInnerHTML={getMarkdownText(noutate.content)} style={{fontSize: "1.25rem", fontFamily: "Catesque"}} />
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  );
};
