import React, { useEffect, useState } from "react";
import { InvoiceEntity } from "../../types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Divider, MenuItem } from "@mui/material";
import { InvoiceService } from "../../services/InvoiceService";
import { StyledMenu } from "../admin/StyledMenu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalEditFactura } from "./Modals/ModalEditFactura";
import DownloadIcon from "@mui/icons-material/Download";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";

export const InvoiceMenu: React.FC<{
  id: number;
  invoices?: InvoiceEntity[] | null;
  handleOpenSnackbar: () => void;
  setInvoices?: React.Dispatch<React.SetStateAction<InvoiceEntity[]>> | null;
  fetchInvoices: () => Promise<void>;
}> = ({ id, invoices, handleOpenSnackbar, setInvoices, fetchInvoices }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [invoice, setInvoice] = useState<InvoiceEntity | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (invoices) {
      try {
        await InvoiceService.deleteInvoice(id);
        setInvoices!(invoices.filter((invoice) => invoice.id !== id));
        handleOpenSnackbar();
        handleClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleStornare = async () => {
    if (invoice) {
      try {
        await InvoiceService.storneazaInvoice(id, invoice?.codClient!).then(
          (res) => {
            console.log(res);
            fetchInvoices();
            handleOpenSnackbar();
            handleClose();
          }
        );
      } catch (error: any) {
        console.error("Error downloading the invoice:", error);
        if (error.response && error.response.status === 404) {
          alert("Invoice not found");
        }
      }
    }
  };

  const handleDownload = async (nrFactura: string, localitate: string) => {
    try {
      const axiosResponse = await InvoiceService.downloadInvoice(
        `factura-${localitate}-${nrFactura}`
      );
      const blobData = axiosResponse.data;
      // Create a blob URL
      const blob = new Blob([blobData], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Use a temporary <a> element to initiate the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `factura-${localitate}-${nrFactura}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error: any) {
      console.error("Error downloading the invoice:", error);
      if (error.response && error.response.status === 404) {
        alert("Invoice not found");
      }
    }
  };

  const fetchInvoice = async () => {
    try {
      const fetchedInvoice = await InvoiceService.getInvoiceById(id);
      setInvoice(fetchedInvoice);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchInvoice();
    if (localStorage.getItem("role") === "ROLE_ADMIN") {
      setIsAdmin(true);
    }
  }, [id]);

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          height: "30px",
          fontFamily: "Catesque",
          backgroundColor: "#0054a6",
        }}
      >
        Opțiuni
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {invoice && (
          <React.Fragment>
            <MenuItem
              onClick={() =>
                handleDownload(invoice.nrFactura!, invoice.location!)
              }
              disableRipple
              sx={{ fontFamily: "Catesque" }}
            >
              <DownloadIcon />
              Descarcă
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
          </React.Fragment>
        )}
        {isAdmin && (
          <React.Fragment>
            <MenuItem
              onClick={handleOpenModal}
              disableRipple
              sx={{ fontFamily: "Catesque" }}
            >
              <EditIcon />
              Editează
            </MenuItem>
            <ModalEditFactura
              invoice={invoice!}
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              handleOpenSnackbar={handleOpenSnackbar}
              setInvoices={setInvoices}
              invoices={invoices!}
            />
            <Divider sx={{ my: 0.5 }} />
          </React.Fragment>
        )}

        <MenuItem
          onClick={handleDelete}
          disableRipple
          sx={{ fontFamily: "Catesque" }}
        >
          <DeleteIcon />
          Stergere
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={handleStornare}
          disableRipple
          sx={{ fontFamily: "Catesque" }}
        >
          <AssistantPhotoIcon />
          Storneaza
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
