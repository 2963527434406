import { Title } from "../components/Title";

import { Box, Grid, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import j5 from "../assets/j5.jpeg";
import platafacturii from "../assets/platafacturii.jpg";
import restdeplata from "../assets/restdeplata.jpg";
import consultconsum from "../assets/consultconsum.jpg";
import { CardReview } from "../mui-treasury/card-review";
import furnizor from "../assets/furnizor.jpg";
import preaviz from "../assets/preaviz.jpg";
import questions1 from "../assets/questions1.jpg";
import autocitire from "../assets/autocitire.jpg";
import alerte from "../assets/alerte.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ServiciiOnlinePage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={j5}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={3} specialOverlay={false} />
        <JumbotronTitle title="Servicii Online" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          "@media (max-width: 1536px)": {
            height: "1600px",
          },
          "@media (max-width: 1200px)": {
            height: "2000px",
          },
          "@media (max-width: 600px)": {
            height: "3800px",
          },
        }}
      >
        <Box
          sx={{
            height: "1000px",
            width: "90%",
            background: "#f2f2f2",
            borderRadius: "25px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            padding: "25px",
            "@media (max-width: 1536px)": {
              height: "1400px",
            },
            "@media (max-width: 1200px)": {
              height: "1900px",
            },
            "@media (max-width: 600px)": {
              height: "3650px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "25px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Servicii Online
            </Typography>
          </Box>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={5}
            sx={{ margin: "0 auto" }}
          >
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={consultconsum}
                title={"Consultare Consum"}
                text={
                  "Verificați istoricul consumului de gaze naturale direct de pe contul dumneavoastră online. Această funcție vă permite să monitorizați și să analizați consumul, oferindu-vă o perspectivă clară asupra utilizării gazelor."
                }
                location={"/consultare-consum"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={platafacturii}
                title={"Plata Facturii"}
                text={
                  "Efectuați plăți rapide și sigure pentru facturile de gaze naturale. Platforma noastră online vă oferă informații despre achitarea facturilor comod și eficient, economisind timp și resurse."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={restdeplata}
                title={"Rest de Plată Total"}
                text={
                  "Vizualizați suma totală datorată și detaliile restanțelor, dacă există. Acest serviciu vă ajută să rămâneți la curent cu obligațiile financiare și să evitați întârzierile în plăți."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={furnizor}
                title={"Detalii Contract"}
                text={
                  "Accesați și revizuiți detalii ale contractului dumneavoastră de furnizare a gazelor naturale. Acest serviciu vă oferă posibilitatea de a verifica termenii și condițiile contractuale în orice moment."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={preaviz}
                title={"Detalii Personale"}
                text={
                  "Actualizați și gestionați informațiile personale asociate contului dumneavoastră. Schimbările de adresă, contact sau alte detalii pot fi efectuate rapid și ușor."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={questions1}
                title={"Informații Utile"}
                text={
                  "Accesați o gamă largă de informații și sfaturi despre utilizarea eficientă a gazelor naturale, siguranță și alte subiecte relevante."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={autocitire}
                title={"Citire Contor"}
                text={
                  "Transmiteți citirile contorului de gaze naturale online, asigurându-vă astfel că facturarea se bazează pe consumul real, nu pe estimări."
                }
                location={"/login"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardReview
                image={alerte}
                title={"Alertări Personalizate"}
                text={
                  "Sunteți întotdeauna informat cu privire la aspectele cele mai importante pentru afacerea dumneavoastră."
                }
                location={"/login"}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
