import {
  Box,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import plafonare from "../assets/plafonare.jpg";
import facturaelec from "../assets/facturaelec.jpg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import furnizor from "../assets/furnizor.jpg";
import deconectare from "../assets/deconectare.jpg";
import { useState } from "react";
import preaviz from "../assets/preaviz.jpg";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";
import cumsefac1 from "../assets/cumsefac1.png";
import payment from "../assets/payment.png";

export const InfoUtile: React.FC<{ forBusiness: boolean }> = (props: {
  forBusiness: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { forBusiness } = props;

  const navigate = useNavigate();

  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: forBusiness ? "3050" : "2100px",
        mt: "50px",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        overflowY: "hidden",
        overflowX: "hidden",
        gap: "35px",
        "@media(max-width: 1300px)": {
          height: "4100px",
          margin: "0 auto",
          width: "120%",
          gap: "unset",
        },
        "@media(max-width: 500px)": {
          height: "5500px",
        },
      }}
    >
      {!forBusiness && (
        <Box
          sx={{
            width: "83%",
            margin: "0 auto",
            height: forBusiness ? "1450px" : "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#f2f2f2",
            borderRadius: "25px",
            "@media(max-width: 1300px)": {
              height: forBusiness ? "1600px" : "900px",
              width: "100%",
            },
            "@media(max-width: 500px)": {
              height: forBusiness ? "2000px" : "1000px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              textAlign: "left",
              justifyContent: "left",
              padding: "20px",
              gap: "10px",
              "@media(max-width: 1300px)": {
                width: "100%",
                padding: "25px",
                mt: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "25px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                {`Plafonare`}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Plafonarea prețurilor de furnizare a gazelor naturale în
                perioada 1 aprilie 2022 - 31 martie 2025 reprezintă o măsură
                care asigură stabilitate și predictibilitate pentru consumatorii
                casnici. Conform prevederilor OUG nr.27/2022, prețurile la
                gazele naturale pentru clienții casnici sunt limitate la un
                nivel maxim, oferind astfel protecție împotriva fluctuațiilor
                bruște ale pieței energetice. Prețul final facturat al gazelor
                naturale va fi plafonat la cel mult:
              </Typography>

              <Box sx={{ display: "flex", mb: "15px", mt: "15px" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  0,31 lei/kWh (TVA inclus) – Clientii casnici, indiferent de
                  consumul acestora;
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: "15px" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  0,37 lei/kWh (TVA inclus) – Clienții noncasnici al căror
                  consum în anul precedent nu a depășit 50.000 MWh;
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Echipa noastră este aici pentru a-ți oferi servicii de calitate
                și pentru a te sprijini în orice fel. Dacă ai întrebări sau
                nelămuriri, nu ezita să ne contactezi. Ne bucurăm să fim alături
                de tine în această perioadă!
              </Typography>
            </Box>
          </Box>
          <Box
            onMouseOver={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            sx={{
              position: "relative",
              width: forBusiness ? "50%" : "400px",
              height: forBusiness ? "100%" : "300px",
              display: "inline-block",
              transition: forBusiness ? "unset" : "width 0.5s ease",
              mr: forBusiness ? "unset" : "25px",
            }}
          >
            <Box
              component="img"
              src={forBusiness ? preaviz : plafonare}
              alt="plafonare"
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: forBusiness ? "0 25px 25px 0" : "unset",
                "@media(max-width: 1300px)": {
                  display: "none",
                },
              }}
            />

            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: isHovered ? "100%" : "40%",
                  borderTop: "5px solid #0054a6",
                  boxSizing: "border-box",
                  transition: "width 0.5s ease",
                  "@media(max-width: 1300px)": {
                    display: "none",
                  },
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  height: isHovered ? "100%" : "40%",
                  borderRight: "5px solid #0054a6",
                  boxSizing: "border-box",
                  transition: "height 0.5s ease",
                  "@media(max-width: 1300px)": {
                    display: "none",
                  },
                }}
              />
            </>
          </Box>
        </Box>
      )}

      {/*Modalitatii De Plata Tab */}
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "425px",
          padding: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#dee2e6",
          borderRadius: "25px",
          gap: "25px",
          "@media (max-width: 1300px)": {
            flexDirection: "column",
            justifyContent: "unset",
            alignItems: "flex-start",
            textAlign: "left",
            height: "350px",
            mb: "25px",
          },
          "@media (max-width: 600px)": {
            width: "50%",
            height: "450px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            gap: "10px",
            "@media (max-width: 1300px)": {
              margin: "0 auto",
              gap: "unset",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Modalități de Plată
            </Typography>
          </Box>
          <Box
            onMouseOver={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            sx={{
              position: "relative",
              width: "470px",
              height: "300px",
              display: "inline-block",
              transition: "width 0.5s ease",
              mt: "10px",
              "@media(max-width: 1300px)": {
                display: "none",
                mt: "unset",
              },
            }}
          >
            <img
              src={payment}
              alt="modalitati-de-plata"
              style={{ width: "100%", height: "100%" }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: isHovered ? "100%" : "40%",
                borderTop: "5px solid #0054a6",
                boxSizing: "border-box",
                transition: "width 0.5s ease",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: isHovered ? "100%" : "40%",
                borderRight: "5px solid #0054a6",
                boxSizing: "border-box",
                transition: "height 0.5s ease",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            height: "30%",
            "@media(max-width: 1300px)": {
              width: "80%",
              margin: "0 auto",
            },
          }}
        >
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            sx={{ mb: "10px" }}
          >
            Datorita platformei MySeven destinata clientilor, iti punem la
            dispozitie mai multe variante pentru a-ți facilita procesul de plată
            a facturii de gaze naturale. Alege cu incredere modalitatea de plată
            care se potrivește cel mai bine nevoilor tale.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigateToPage(navigate, "/modalitati-de-plata");
              window.scrollTo(0, 0);
            }}
            sx={{
              height: "40px",
              fontFamily: "Catesque",
              mt: "10px",
              width: "200px",
            }}
          >
            Află mai Mult &gt;
          </Button>
        </Box>
      </Box>
      {/*Ce Inseamna Factura Electronica */}
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "425px",
          padding: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#dee2e6",
          borderRadius: "25px",
          gap: "25px",
          "@media (max-width: 1300px)": {
            flexDirection: "column",
            justifyContent: "unset",
            alignItems: "flex-start",
            textAlign: "left",
            height: "350px",
          },
          "@media (max-width: 600px)": {
            width: "50%",
            height: "500px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            gap: "25px",
            "@media (max-width: 1300px)": {
              margin: "0 auto",
              width: "100%",
            },
            "@media (max-width: 600px)": {
              height: "800px",
              width: "80%",
            },
          }}
        >
          <Box
            sx={{
              width: "120%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                mr: "15px",
                backgroundColor: "#0054a6",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"} sx={{}}>
              Cum se factureaza consumul de gaze naturale?
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: "30%",
              "@media(max-width: 1300px)": {
                width: "80%",
                margin: "0 auto",
              },
            }}
          >
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Euro7 iti citeste contorul lunar. Afla cum se face conversia din
              m³ (metri cubi) in kWh pe factura ta de gaze naturale.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigateToPage(
                  navigate,
                  "/cum-se-factureaza-consumul-gaze-naturale"
                );
              }}
              sx={{
                height: "40px",
                fontFamily: "Catesque",
                mt: "10px",
                width: "200px",
              }}
            >
              Află mai Mult &gt;
            </Button>
          </Box>
        </Box>

        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "400px",
            height: "350px",
            display: "inline-block",
            transition: "width 0.5s ease",
            mt: "10px",
            "@media(max-width: 1300px)": {
              display: "none",
              mt: "unset",
            },
          }}
        >
          <img
            src={cumsefac1}
            alt="questions1"
            style={{ width: "100%", height: "100%" }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
            }}
          />
        </Box>
      </Box>
      {/*Factura ta de gaze naturale explicata*/}
      <Box
        sx={{
          height: "600px",
          width: "82%",
          margin: "0 auto",
          display: "flex",
          gap: "25px",
          "@media (max-width: 1300px)": {
            flexDirection: "column",
            height: "auto",
            margin: "unset",
            display: "unset",
          },
        }}
      >
        <Card
          sx={{
            flex: 1,
            height: "600px",
            "@media(max-width: 1300px)": {
              margin: "0 auto",
            },
          }}
        >
          <CardActionArea
            sx={{ height: "100%", width: "100%" }}
            onClick={() => navigateToPage(navigate, "/factura-explicata")}
          >
            <CardMedia
              component="img"
              height="400px"
              image={facturaelec}
              alt="factura-explicata"
              sx={{
                objectFit: "cover",
              }}
            />
            <CardContent
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontFamily={"Catesque"}
                sx={{ mb: "20px" }}
              >
                Factura Explicată
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                fontFamily={"Catesque"}
              >
                Ghid explicativ al facturii de gaze naturale. Afla detalii
                despre fiecare sectiune a facturii tale pentru a găsi rapid și
                ușor informațiile de care ai nevoie.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ flex: 1, height: "600px" }}>
          <CardActionArea
            sx={{ height: "100%", width: "100%" }}
            onClick={() => navigateToPage(navigate, "/schimbarea-furnizorului")}
          >
            <CardMedia
              component="img"
              height="400px"
              image={furnizor}
              alt="factura-explicata"
            />
            <CardContent
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontFamily={"Catesque"}
                sx={{ mb: "25px" }}
              >
                Schimbarea Furnizorului
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                fontFamily={"Catesque"}
              >
                Furnizorul tau alegerea ta. Ai dreptul de a-ți schimba
                furnizorul prin încetarea contractului actual și încheierea unui
                nou contract cu furnizorul pe care îl preferi. Consultă aici
                pașii pentru a realiza schimbarea furnizorului de gaze naturale.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={{ flex: 1, height: "600px" }}>
          <CardActionArea
            sx={{ height: "100%", width: "100%" }}
            onClick={() => navigateToPage(navigate, "/reluare-furnizare-gaze")}
          >
            <CardMedia
              component="img"
              height="400px"
              image={deconectare}
              alt="factura-explicata"
            />
            <CardContent
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontFamily={"Catesque"}
                sx={{ mb: "20px" }}
              >
                Reluarea Furnizării și Preavizul de Deconectare
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                fontFamily={"Catesque"}
              >
                Afla mai multe despre preavizul de deconectare, cand poate fi
                emis și pașii pe care trebuie să-i urmezi pentru a-ți restabili
                furnizarea în cazul în care ai fost deconectat.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
};
