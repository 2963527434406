import { Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import legal from "../assets/legal.jpg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";

export const FacturaIntrebariFrecvente: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "25px",
          borderRadius: "25px",
          mb: "20px",
          mt: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Factura
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px" }}
            >
              Cum citesc factura?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Pe factura ta pentru gaze naturale, vei găsi mai multe secțiuni
              care oferă detalii esențiale despre cheltuielile tale, volumul de
              energie consumată, perioada de consum și alte informații
              importante.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Ne dăm seama că este esențial pentru tine să înțelegi factura
              pentru gaze naturale, așa că am elaborat un ghid detaliat pentru a
              explora fiecare secțiune a acesteia.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Cum îmi activez factura electronică?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Prin înregistrarea pe platforma MySeven, factura electronică este
              activată automat. Această opțiune facilitează gestionarea
              facturilor tale, oferindu-ți un nivel ridicat de comoditate și
              eficiență. După ce te-ai înscris pe platformă, vei primi automat
              facturile tale în format electronic, eliminând necesitatea de a le
              primi prin poștă.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Această metodă de primire a facturilor este nu numai mai
              prietenoasă cu mediul, dar și mai rapidă și mai sigură. În plus,
              vei avea acces ușor la istoricul facturilor tale și la alte
              caracteristici utile pentru monitorizarea consumului tău de gaze
              naturale. Înregistrarea pe platforma MySeven aduce numeroase
              beneficii în ceea ce privește gestionarea și urmărirea facturilor
              tale, toate acestea realizându-se automat și fără complicații.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              component={"span"}
              sx={{ display: "flex" }}
            >
              Dacă nu ai deja un cont de client, click{" "}
              <span
                onClick={() => navigate("/login")}
                style={{
                  color: "#0054a6",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                aici
              </span>{" "}
              și înregistrează-te.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Ce vom face noi?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Vei primi lunar factura și alte comunicări în format electronic pe
              adresa de e-mail menționată de tine.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Cum este facturat consumul meu?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Pe factura de consum, în secțiunea "Tip citire," poți identifica
              modul de determinare a consumului facturat. Acesta poate fi fie
              "citire de către operator," în cazul în care reprezentanții
              companiei de distribuție efectuează citirea directă a indexului de
              consum, fie "autocitire," atunci când furnizezi tu însuți indexul
              de consum. Dacă nu există un index citit sau furnizat, consumul
              este estimat în conformitate cu acordul de consum stabilit
              anterior cu tine. Dacă estimările nu sunt în concordanță cu
              consumul real, ai opțiunea de a ne furniza noi valori pentru a
              ajusta factura. În absența unui grafic de consum furnizat de tine,
              estimarea se va baza pe istoricul tău de consum înregistrat.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component={"span"}
              sx={{ mb: "15px" }}
              display={"flex"}
            >
              Mai multe detalii despre cum este facturat consumul tău, găsești{" "}
              <span
                onClick={() =>
                  navigate("/cum-se-factureaza-consum-gaze-naturale")
                }
                style={{
                  color: "#0054a6",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              >
                aici
              </span>{" "}
              .
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              De ce apare sold restant pe factură, deși am plătit integral?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              În cazul în care nu achiti suma totală datorată pe factura înainte
              de data scadenței, această sumă va fi înregistrată ca sold restant
              și va fi inclusă în calculul următoarei facturi.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              În situația în care ai plătit factura după data scadentă, iar
              soldul restant a fost raportat în momentul emiterii facturii
              următoare, nu trebuie să îți faci griji. Acest sold restant nu va
              mai fi inclus pe factura ulterioară, astfel încât să poți continua
              să plătești în mod regulat.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Te încurajăm întotdeauna să achiti facturile până la data
              scadenței pentru a evita astfel de situații și pentru a asigura un
              proces de facturare fără probleme.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Ce pot să fac pentru evitarea facturilor mari la regularizare?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Dacă vrei să eviți facturile mari la regularizare, îți recomandăm
              să ne trimiți lunar indexul autocitit pentru a fi facturat conform
              consumului tău exact.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "5px" }}
            >
              Ne poți transmite indexul autocitit:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
                component={"span"}
                display={"flex"}
              >
                Online{" "}
                <span
                  onClick={() => navigate("/login")}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  aici
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"blue"}
                component={"a"}
                href={"/contact"}
                sx={{ textDecoration: "none" }}
              >
                Contact
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ my: "10px" }}
            >
              Atenție! Te rugăm să te încadrezi în perioada de transmitere a
              indexului (perioada 26-30 a lunii), prin introducerea indexului
              înregistrat de contor (indexul înregistrat de contor, se va
              transmite doar până la separatorul de numere întregi, adică până
              la cifrele roșii sau semnul virgulă “,”).
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              În lunile în care reprezentanții companiei de distribuție
              efectuează citiri, indexul citit de către aceștia are prioritate!
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Nu am primit factura, cum pot obține o copie?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Dacă nu ai primit factura, o poți descărca direct de pe platforma
              MySeven, unde vei găsi toate facturile tale emise. În plus, poți
              să ne contactezi pentru a primi o copie a facturii prin e-mail sau
              poștă.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              De ce consum metri cubi și plătesc kwh?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Contorul tău înregistrează consumul tău în metri cubi, însă
              conform legislației în vigoare facturarea se face în kWh
              (kilowatt-oră), care reprezintă o unitate de energie.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Transformarea volumelor de gaze naturale în unități de energie se
              face aplicând formula:
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              fontWeight={"bold"}
              sx={{ mb: "10px" }}
            >
              E = V(B) x PCS
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
              component={"span"}
              display={"flex"}
            >
              Pentru mai multe detalii, click{" "}
              <span
                onClick={() =>
                  navigate("/cum-se-factureaza-consum-gaze-naturale")
                }
                style={{
                  color: "#0054a6",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              >
                aici
              </span>
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Cum pot afla suma pe care o am de plată?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Suma pe care o ai de plată o poți afla pe factura ta, în secțiunea
              "Total de plată" sau pe platforma MySeven, unde vei găsi toate
              facturile tale emise.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Cum și unde pot plăti factura Euro7?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Îți punem la dispoziție mai multe modalități de plată pentru ca
              ție să îți fie cât mai ușor să achiți factura ta de gaze naturale:
            </Typography>

            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Transfer Bancar catre conturile:
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", ml: "40px" }}>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO71 BACX 0000 0017 7375 2000 - UNICREDIT BANK
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO16 BRDE 426S V443 5866 4260 - BRD - Agentia Vergului
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "#0054a6", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO69 TREZ 7005 069X XX00 5713 - TREZ OPER BUCURESTI
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
                component={"span"}
              >
                Fizic la{" "}
                <span
                  onClick={() => navigate("/contact")}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  punctele de lucru
                </span>{" "}
                .
              </Typography>
            </Box>
          </>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={legal}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
