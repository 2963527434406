import { Title } from "../components/Title";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import information from "../assets/information.jpg";
import { AnuntEntity } from "../types";
import { AnuntService } from "../services/AnuntService";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const NoutatiPage: React.FC = () => {
  const [noutati, setNoutati] = useState<AnuntEntity[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    AnuntService.getAll().then((response) => {
      setNoutati(response);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={information}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Noutati" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "800px",
          background: "#0054a6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            maxHeight: "600px",
            margin: "auto",
            background: "white",
            overflowY: "auto",
            my: "25px",
            borderRadius: "25px",
            position: "relative",
            "&::-webkit-scrollbar": {
              position: "absolute",
              bottom: 0,
              right: 24,
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f0f0f0", // Scrollbar track color
              borderRadius: "10px", // Rounded corners for the scrollbar track
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#a0a0a0", // Scrollbar handle color
              borderRadius: "10px", // Rounded corners for the scrollbar handle
              border: "3px solid #f0f0f0", // Creates padding around the scrollbar handle
            },
          }}
        >
          <List>
            {noutati.map((anunt) => (
              <>
                <ListItem key={anunt.id}>
                  <ListItemButton
                    onClick={() =>
                      navigateToPage(navigate, "/noutati/" + anunt.id)
                    }
                  >
                    <ListItemIcon sx={{ color: "#0054a6" }}>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={anunt.title}
                      secondary={anunt.date}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
