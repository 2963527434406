import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ofertagaze from "../assets/ofertagaze.png";
import { navigateToPage } from "../utils";
import { useNavigate } from "react-router-dom";

export const OfertaGazeNaturale: React.FC<{ forBusiness: boolean }> = (props: {
  forBusiness: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { forBusiness } = props;
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  const navigate = useNavigate();

  const handleDownload = () => {
    const url = process.env.PUBLIC_URL + "/declaratie.docx";
    const a = document.createElement("a");
    a.href = url;
    a.download = "Declaratie Proprie Raspundere - EURO SEVEN INDSUTRY.docx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: forBusiness ? "1400px" : "750px",
        background: "linear-gradient(to bottom,#FFFFFF,#dee2e6)",
        mt: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        overflowY: "hidden",
        overflowX: "hidden",
        gap: "35px",
        "@media(max-width: 1300px)": {
          height: "auto",
          margin: "0 auto",
          width: "120%",
          gap: "unset",
        },
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          {forBusiness ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "25px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Oferta Gaze Naturale
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "15px" }}
              >
                La Euro7, înțelegem provocările și cerințele specifice ale
                mediului de afaceri. De aceea, suntem dedicați să oferim soluții
                de gaze naturale care să sprijine eficiența, sustenabilitatea și
                creșterea afacerii dumneavoastră. Descoperiți cum parteneriatul
                cu Euro7 poate transforma modul în care afacerea dumneavoastră
                utilizează energia.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                sx={{ mb: "10px" }}
              >
                Personalizarea Ofertei
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Oferte Personalizate
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Analizăm nevoile afacerii dumneavoastră și oferim tarife
                  personalizate, optimizate pentru consumul și cerințele
                  specifice ale locației dumneavoastră.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Consultanță în Eficiența Energetică
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Vă ajutăm să identificați modalități de reducere a costurilor
                  și consumului de gaz, contribuind astfel la eficientizarea
                  afacerii.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Asistență dedicată
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Echipa noastră de experți este disponibilă pentru a vă ghida
                  și răspunde la orice întrebări sau preocupări legate de oferta
                  noastră de gaze naturale.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                sx={{ mb: "10px", mt: "15px" }}
              >
                Beneficii pentru Afacerea Dumneavoastră
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  mb: "10px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Tarife Competitive
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Oferim prețuri avantajoase, adaptate la structura de costuri a
                  afacerii dumneavoastră.
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Fiabilitate și Siguranță
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Ne angajăm să oferim o furnizare continuă și sigură, pentru ca
                  afacerea dumneavoastră să nu întâmpine întreruperi.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                sx={{ mb: "10px", mt: "15px" }}
              >
                Pentru a încheia un contract de furnizare gaze naturale, ai
                nevoie să pregătești următoarele documente:
              </Typography>

              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Copia actului de identitateș
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Copia certificatului de înregistrare la Oficiul Național al
                  Registrului Comerțului;
                </Typography>
              </Box>
              <Box sx={{ display: "flex", position: "relative" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  component={"span"}
                >
                  Declarația pe propria răspundere din care să rezulte în ce
                  calitate folosești imobilul pentru care faci contractul sau
                  actul de proprietate al acestuia. Poți găsi modelul de
                  declarație
                  <span
                    onClick={() => handleDownload}
                    style={{
                      color: "#0054a6",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    aici
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Poza contorului de gaz efectuată la momentul solicitării, în
                  care să se vadă seria și indexul;
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Codul loclui de consum, se regăsește pe factura de gaze
                  naturale (CLC - cod unic alocat pentru fiecare adresă la care
                  ai un contract de furnizare gaze naturale);
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "20px" }}
              >
                Alegeți Euro7 pentru a asigura că afacerea dumneavoastră
                beneficiază de un partener de încredere în domeniul furnizării
                de gaze naturale. Ne angajăm să vă oferim soluții care să
                susțină creșterea durabilă și eficientă a afacerii
                dumneavoastră. Contactați-ne pentru a descoperi mai multe despre
                cum vă putem susține în atingerea obiectivelor energetice.
              </Typography>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "25px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Oferta Gaze Naturale
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "15px" }}
              >
                La Euro7, suntem dedicați să vă oferim cele mai competitive și
                personalizate soluții pentru necesitățile dumneavoastră
                energetice. Descoperiți beneficiile parteneriatului cu noi și
                alegeți o ofertă de gaze naturale care să vă satisfacă pe
                deplin.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1.2rem"}
                fontWeight={"bold"}
                fontStyle={"italic"}
                sx={{ mb: "10px" }}
              >
                Cum puteți beneficia de oferta noastră de gaze naturale?
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Solicitați o ofertă personalizată
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Contactați-ne pentru a primi o ofertă personalizată în funcție
                  de consumul dumneavoastră specific și cerințele locației.
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Analiză a eficienței energetice
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Oferim consultanță privind eficiența energetică, ajutându-vă
                  să identificați moduri de a reduce consumul și costurile.
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                  <Typography
                    fontFamily={"Catesque"}
                    fontSize={"1rem"}
                    color={"black"}
                  >
                    Asistență dedicată
                  </Typography>
                </Box>
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Echipa noastră de experți este disponibilă pentru a vă ghida
                  și răspunde la orice întrebări sau preocupări legate de oferta
                  noastră de gaze naturale.
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px", mt: "20px" }}
              >
                Alegeți Euro7 pentru a beneficia de servicii de înaltă calitate,
                tarife competitive și un parteneriat de lungă durată în domeniul
                furnizării de gaze naturale. Suntem aici pentru dumneavoastră,
                oricând aveți nevoie de energie de încredere.
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigateToPage(navigate, "/oferta")}
                sx={{
                  height: "40px",
                  fontFamily: "Catesque",
                  mt: "10px",
                  width: "200px",
                }}
              >
                Ofertă &gt;
              </Button>
            </>
          )}
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={ofertagaze}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
