import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import CheckBox from "@mui/icons-material/CheckBox";
import plangeri from "../assets/plangeri.jpg";

export const SolutionareaPlangerilorPage: React.FC = () => {
  // const handlePdfClick = (event: any) => {
  //   event?.preventDefault();
  //   window.open(
  //     `${process.env.PUBLIC_URL}/procedura-solutionare-plangeri.pdf`,
  //     "_blank"
  //   );
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={plangeri}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "28%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Adresarea Plângerilor
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "2100px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Modalități de soluționare a plângerilor
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Definim plângerea ca fiind orice formă de sesizare, reclamație,
            petiție sau altă modalitate prin care îți exprimi nemulțumirea față
            de serviciile noastre, așteptând un răspuns sau o soluție adecvată.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Ai dreptul să ne adresezi o plângere în cazul în care ai nemulțumiri
            referitoare la următoarele aspecte legate de activitatea noastră:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Încheierea unui contract nou pentru furnizarea de gaze naturale
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Facturarea contravalorii gazelor naturale utilizate
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Oferta actuală de prețuri pentru furnizarea de gaze naturale și
              tarifele în vigoare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Continuitatea în alimentarea cu gaze naturale
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Funcționarea contorului tău de măsurare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Funcționarea grupurilor de măsurare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Schimbarea furnizorului
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Informarea clienților conform cerințelor legislative în vigoare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Modul de soluționare a plângerilor legate de nerespectarea
              legislației în vigoare
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Preluarea plângerilor se poate face prin următoarele modalități:
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", ml: "34px" }}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              • Prin intermediul punctelor noastre de lucru, la depunerea în
              scris
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              • La numărul de telefon: 0374 627 726
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              • Prin completarea formularului de contact disponibil
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              • Prin fax: 0374 092 081
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              • Prin poștă sau curier la adresa: Sediul Central, Strada Horatiu
              nr. 11, ap2, Sector 1, București
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Înregistrarea Plângerilor
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Respectând termenele impuse de standardele de furnizare și
            distribuție, plângerea ta va fi preluată de unul dintre colegii
            noștri, parcursul urmând următoarele etape:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Identificarea profilului tău
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Încadrarea corectă a reclamației în funcție de problema semnalată
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Verificarea aspectelor reclamate de tine
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Trimiterea răspunsului la plângerea ta
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Încheierea cazului
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Soluționarea și Informarea Ta Privind Modul de Rezolvare a
              Plângerilor
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            După analizarea plângerii tale, vei primi următoarele informații:
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            1. Dacă plângerea este întemeiată și soluționarea este în favoarea
            ta, vei fi informat în scris cu privire la concluziile analizei și
            la modul de soluționare. În acest caz, se vor lua toate măsurile
            necesare pentru implementarea soluției, conform legislației în
            vigoare, inclusiv eventuale rambursări sau compensații.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"} color={"black"}>
            2. Dacă plângerea este neîntemeiată sau soluționarea nu este în
            favoarea ta, vei primi un răspuns oficial din partea noastră. În
            cazul în care nu ești de acord cu soluția propusă sau nu primești
            răspuns în termenul legal, ai posibilitatea să te adresezi
            Autorității Naționale de Reglementare în Domeniul Energiei, cu
            sediul în Str. Constantin Nacu, nr.3, București, sector 2. Poți lua
            legătura cu aceasta la numărul de telefon: 021.327.81.74, prin fax
            la: 021.312.43.65, sau prin e-mail la adresa: anre@anre.ro.
          </Typography>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            color={"black"}
            component={"span"}
          >
            Mai multe detalii privid procedura, etapele și documentele necesare
            procesului de soluționare a plângerilor clienților finali puteti
            afla{" "}
            <span
              onClick={() =>
                (window.location.href = `${process.env.PUBLIC_URL}/procedura-solutionare-plangeri.pdf`)
              }
              rel="noopener noreferrer"
              style={{
                color: "#0054a6",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              aici.
            </span>
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
