import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Title } from "../components/Title";
import { Footer } from "../components/Footer";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputFormAutocomplete } from "../components/admin/modal/InputFormAutocomplete";
import { useState } from "react";
import { OfertaEntity } from "../types";
import axios from "axios";

export interface IFormProps {
  prenume: string;
  nume: string;
  email: string;
  telefon: string;
  judet: string;
  localitate: string;
  adresa: string;
  detalii?: string;
}

const defaultValues = {
  prenume: "",
  nume: "",
  email: "",
  telefon: "",
  judet: "",
  localitate: "",
  adresa: "",
  detalii: "",
};

const createOferta = async (oferta: OfertaEntity): Promise<OfertaEntity> => {
  return new Promise((resolve) => {
    axios
      .post("https://secure.euro7.ro:8443/api/oferte/create", oferta)
      .then((response) => {
        resolve(response.data);
      });
  });
};

const validationSchema = yup
  .object({
    prenume: yup.string().required("Prenumele trebuie completat!"),
    nume: yup.string().required("Numele trebuie completat!"),
    email: yup.string().required("Email-ul trebuie completat!"),
    telefon: yup.string().required("Numarul de telefon trebuie completat!"),
    judet: yup.string().required("Judetul trebuie completat!"),
    localitate: yup.string().required("Localitatea trebuie completata!"),
    adresa: yup.string().required("Adresa trebuie completata!"),
    detalii: yup.string().optional(),
  })
  .required();

export const OfertaPersoanaFizicaPage: React.FC = () => {
  const { handleSubmit, reset, control, setValue } = useForm<IFormProps>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const onSubmit = async (data: IFormProps) => {
    await createOferta({
      forWho: "fizica",
      prenume: data.prenume,
      nume: data.nume,
      email: data.email,
      telefon: data.telefon,
      judet: data.judet,
      localitate: data.localitate,
      adresa: data.adresa,
      detalii: data.detalii,
    }).then((res) => {
      setOpenSnackbar(true);
      reset({
        prenume: "",
        nume: "",
        email: "",
        telefon: "",
        judet: "",
        localitate: "",
        adresa: "",
        detalii: "",
      });
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1250px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ position: "absolute", bottom: 0, left: 0, padding: "20px" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Ofertă trimisă. O sa primiți raspunsul pe email căt mai curănd!
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" component="h2" fontFamily={"Catesque"}>
            Vreau o Oferta Personalizată
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "40px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              FORMULAR PERSOANĂ FIZICĂ
            </Typography>
          </Box>
        </Box>
        <Controller
          name={"prenume"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              name={"prenume"}
              id={"prenume"}
              label={"Prenume"}
              error={!!error}
              helperText={error ? error.message : null}
              required
              onChange={(data) => {
                onChange(data);
              }}
              sx={{
                width: "30%",
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "black", // This will make the label color black when the TextField is disabled
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
        <Controller
          name={"nume"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              name={"nume"}
              id={"nume"}
              label={"Nume"}
              error={!!error}
              required
              helperText={error ? error.message : null}
              onChange={(data) => {
                onChange(data);
              }}
              sx={{
                width: "30%",
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "black", // This will make the label color black when the TextField is disabled
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
        <Controller
          name={"email"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              name={"email"}
              id={"email"}
              label={"Email"}
              error={!!error}
              helperText={error ? error.message : null}
              required
              onChange={(data) => {
                onChange(data);
              }}
              sx={{
                width: "30%",
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "black", // This will make the label color black when the TextField is disabled
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
        <Controller
          name={"telefon"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              name={"telefon"}
              id={"telefon"}
              label={"Telefon"}
              error={!!error}
              helperText={error ? error.message : null}
              required
              onChange={(data) => {
                onChange(data);
              }}
              sx={{
                width: "30%",
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "black", // This will make the label color black when the TextField is disabled
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
        <InputFormAutocomplete
          name1={"judet"}
          name2={"localitate"}
          control={control}
          label1={"Județ"}
          label2={"Localitate"}
          setValue2={setValue}
        />
        <Controller
          name={"adresa"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              name={"adresa"}
              id={"adresa"}
              label={"Adresă"}
              error={!!error}
              required
              helperText={error ? error.message : null}
              onChange={(data) => {
                onChange(data);
              }}
              sx={{
                width: "30%",
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "black", // This will make the label color black when the TextField is disabled
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
       <Box
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <Controller
            name={"detalii"}
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error },
              formState,
            }) => (
              <TextField
                {...props}
                name={"detalii"}
                id={"nume"}
                label={"Detalii"}
                error={!!error}
                multiline
                rows={8}
                helperText={error ? error.message : null}
                onChange={(data) => {
                  onChange(data);
                }}
                sx={{
                  width: "30%",
                  "& input:disabled": {
                    color: "black", // Text color
                    fontFamily: "Catesque", // Font family
                    WebkitTextFillColor: "black",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "black", // This will make the label color black when the TextField is disabled
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                    fontFamily: "Catesque", // Font family
                    fontSize: "18px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#0054a6", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#0054a6", // Hover border color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                    "&.Mui-disabled fieldset": {
                      borderColor: "#0054a6", // Focused border color
                    },
                  },
                }}
              />
            )}
            defaultValue={""}
          />
          <Typography fontFamily={"Catesque"} color={"#434343"}>
            * Daca esti client Euro7, scrie codul client in detalii
          </Typography>
        </Box>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant={"contained"}
          sx={{
            backgroundColor: "#0054a6",
            fontFamily: "Catesque",
            width: "30%",
          }}
        >
          Trimite Ofertă
        </Button>
        <Button
          onClick={() => {
            reset({
              prenume: "",
              nume: "",
              email: "",
              telefon: "",
              judet: "",
              localitate: "",
              adresa: "",
              detalii: "",
            });
          }}
          variant={"outlined"}
          sx={{
            borderColor: "#0054a6",
            color: "#0054a6",
            fontFamily: "Catesque",
            width: "30%",
          }}
        >
          Resetare
        </Button>
      </Box>
      <Footer />
    </Box>
  );
};
