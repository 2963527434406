import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import CheckBox from "@mui/icons-material/CheckBox";
import "../pages/incasari/IncasariHomePage.css";
import card2 from "../assets/card2.jpg";
import consultconsum from "../assets/consultconsum.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";
import mdp from "../assets/mdp.jpg";
import { useNavigate } from "react-router-dom";

export const ModalitatiDePlataPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={card2}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Modalități de Plată" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1750px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box sx={{ width: "100%", height: `calc(30%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "75%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "9px",
                    height: "100px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Plătește factura prin transfer bancar în unul dintre
                  următoarele conturi:
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO16 BRDE 426S V443 5866 4260 - BRD - Agentia Vergului
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO69TREZ7005069XXX005713 - TREZ OPER BUCURESTI
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  RO71 BACX 0000 0017 7375 2000 - UNICREDIT BANK
                </Typography>
              </Box>

              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Pentru a asigura procesarea corectă a plăților efectuate prin
                transfer bancar, vă rugăm să furnizați următoarele informații
                esențiale: codul de client, numărul facturii, numele și
                prenumele clientului exact așa cum apar pe factură.
                Neînregistrarea acestor detalii importante poate duce la
                întârzierea sau imposibilitatea prelucrării plății în mod
                adecvat și poate conduce la suspendarea furnizării serviciilor.
              </Typography>
            </Box>
            <Box
              component={"img"}
              src={mdp}
              height={"300px"}
              width={"350px"}
              alt={"tarife1"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(30%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Plată Numerar
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Puteți plăti numerar la orice punct de lucru de al nostru.
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "20%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "25%",
                    background: "#0054a6",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "10%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      color={"white"}
                      sx={{ ml: "5px" }}
                    >
                      Punct de Lucru
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "40%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      color={"white"}
                      sx={{ ml: "100px" }}
                    >
                      Adresa
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      color={"white"}
                      sx={{ ml: "60px" }}
                    >
                      Program
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      "@media (max-width: 600px)": {
                        display: "none",
                      },
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      color={"white"}
                      sx={{ ml: "60px" }}
                    >
                      Intervenții (24/7)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "37.5%",
                    display: "flex",
                    background: "white",
                  }}
                >
                  <Box
                    sx={{
                      width: "10%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "5px" }}
                    >
                      Bolintin-Deal
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "40%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "100px" }}
                    >
                      Com. Bolintin Deal , Jud. Giurgiu
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "60px" }}
                    >
                      L - V 08:00 - 16:00
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      "@media (max-width: 600px)": {
                        display: "none",
                      },
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "60px" }}
                    >
                      0752 219 562
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "37.5%",
                    display: "flex",
                    background: "#DCDCDC",
                  }}
                >
                  <Box
                    sx={{
                      width: "10%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "5px" }}
                    >
                      Sabareni
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "40%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "100px" }}
                    >
                      Com. Sabareni , Jud. Giurgiu
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "60px" }}
                    >
                      L - V 08:00 - 16:00
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                      minHeight: "100%",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      "@media (max-width: 600px)": {
                        display: "none",
                      },
                    }}
                  >
                    <Typography
                      fontFamily={"Catesque"}
                      fontSize={"1rem"}
                      sx={{ ml: "60px" }}
                    >
                      0757 578 892
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              component={"img"}
              src={consultconsum}
              height={"300px"}
              width={"380px"}
              alt={"tarife2"}
            />
          </Box>
        </Box>
        {/* <Box sx={{ width: "100%", height: `calc(20%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={consultaconsum}
              height={"250px"}
              width={"380px"}
              alt={"tarife3"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Plată Online
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Vă informăm că vă oferim o modalitate sigură și convenabilă de a
                efectua plățile facturilor dumneavoastră online prin intermediul
                cardului. Prin intermediul platformei noastre de plată online
                securizată, puteți introduce detaliile cardului dumneavoastră
                într-un mediu sigur, cu protecție avansată împotriva fraudei și
                a accesului neautorizat.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
                component={"div"}
                display={"flex"}
              >
                Această opțiune rapidă și eficientă vă permite să evitați orice
                întârzieri potențiale sau dificultăți asociate plăților
                tradiționale, permițându-vă să vă mențineți conturile la zi și
                să vă bucurați de serviciile noastre fără griji suplimentare.
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            width: "100%",
            height: `calc(40%)`,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Cererea de restituire și cererea de transfer
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Cererea de restituire a soldului este utilizată atunci când
                dețineți un avans în contul dumneavoastră și doriți să îl
                recuperați în loc să îl păstrați pentru acoperirea viitoarelor
                facturi. Pentru a completa această cerere, trebuie să furnizați
                următoarele informații esențiale: numele și prenumele, adresa,
                codul numeric personal, codul de client, numărul de telefon și
                contul IBAN în care doriți să fie restituită suma.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Pe de altă parte, cererea de transfer a soldului este folosită
                atunci când intenționați să mutați fondurile de la un loc de
                consum la altul. Pentru a completa această cerere, este necesar
                să furnizați următoarele informații importante: numele și
                prenumele, adresa, codul numeric personal, codul de client,
                numărul de telefon, codul locului de consum, numele clientului
                căruia doriți să transferați suma, adresa, codul numeric
                personal, codul de client și adresa locului de consum
                corespunzător.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                fontWeight={"bold"}
                sx={{ mb: "10px" }}
              >
                Acte necesare:
              </Typography>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  cererea de restituire sau cererea de transfer
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  copia cărtii de identitate a solicitantului
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  copia extrasului de cont a solicitantului
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                În funcție de situație se pot solicita documente suplimentare.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
                component={"span"}
              >
                Trimite-ne cererea de restituire sau de transfer completată și
                semnată, împreună cu documentele menționate mai sus prin email{" "}
                <span
                  onClick={() => navigate(`/contact`)}
                  rel="noopener noreferrer"
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  aici.
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
