import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { UserService } from "../../../services/UserService";

const defaultValues = {
  emailValue: "",
};

export const ModalActivate: React.FC<{
  open: boolean;
  handleClose: () => void;
  userId: number;
}> = ({ open, handleClose, userId }) => {
  const { handleSubmit, reset, control } = useForm<{
    emailValue: string;
  }>({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: { emailValue: string }) => {
    const email = data.emailValue;
    await UserService.toggleUser(userId, email).then((res) => {
      handleClose();
    });
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "grid",
          gridRowGap: "20px",
          padding: "25px",
          margin: "10px 300px",
          position: "absolute" as "absolute",
          top: "40%",
          left: "40%",
          transform: "translate(-50%, -50%)",
          width: "100vh",
          minHeight: "25vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="h2">
          Completare Email
        </Typography>
        <Controller
          name={"emailValue"}
          control={control}
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              {...props}
              required
              name={"emailValue"}
              id={"emailValue"}
              size={"small"}
              label={"Email"}
              type={"email"}
              onChange={onChange}
              error={!!error}
              sx={{
                "& .MuiInputBase-input": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                },
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
              }}
            />
          )}
          defaultValue={""}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          variant={"contained"}
          sx={{ backgroundColor: "#0054a6", fontFamily: "Catesque" }}
        >
          Salvează
        </Button>
        <Button
          onClick={() => {
            reset({
              emailValue: "",
            });
          }}
          variant={"outlined"}
          sx={{
            borderColor: "#0054a6",
            color: "#0054a6",
            fontFamily: "Catesque",
          }}
        >
          Resetare
        </Button>
      </Box>
    </Modal>
  );
};
