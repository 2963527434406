import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import CheckBox from "@mui/icons-material/CheckBox";
import shutterstock_1449771545 from "../assets/shutterstock_1449771545.jpg";
import { useNavigate } from "react-router-dom";

export const SchimbareaFurnizoruluiPage: React.FC = () => {

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={shutterstock_1449771545}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "35%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Schimbarea Furnizorului
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1000px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Schimbarea furnizorului de gaze naturale reprezintă o opțiune
            personală prin care decizi să închei contractul cu furnizorul actual
            și să optezi pentru un nou furnizor.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Este esențial să știi că acest proces este gratuit și poate fi
            inițiat în orice moment, respectând însă condițiile și clauzele
            contractuale precum și regulamentul POSF.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Atunci când decizi să schimbi furnizorul de gaze naturale și să
            închei un nou contract, este important să fii atent la toate
            aspectele, inclusiv prețul, durata contractului, condițiile de
            plată, etc.
          </Typography>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            component={"div"}
            display={"flex"}
          >
            Pentru a schimba furnizorul, ai la dispoziție două modalități:
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component={"span"}
            >
              Intra in contact cu{" "}
              <span
                onClick={() => (navigate("/contact"))}
                style={{
                  color: "#0054a6",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                noi
              </span>{" "}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component={"span"}
            >
              Poți utiliza platforma online{" "}
              <span
                onClick={() => (window.location.href = "https://posf.ro/")}
                style={{
                  color: "#0054a6",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                ANRE
              </span>{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Documentele necesare pentru încheierea noului contract includ:
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Copia actului de identitate;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Declarația pe proprie răspundere referitoare la calitatea
              folosirii imobilului pentru care se încheie noul contract sau
              actul de proprietate al acestuia;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Fotografia contorului de gaz care să evidențieze seria și indexul
              corespunzător;
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Codul locului de consum (CLC) sau o copie a ultimei facturi emise
              de furnizorul actual (cu excluderea informațiilor confidențiale).
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Pentru detalii suplimentare privind procedura de schimbare a
              furnizorului, te invităm să consulți Ordinul nr. 3/2022
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
