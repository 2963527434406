import { OfertaEntity } from "../types";
import axios from "../axios";
const URL = "https://secure.euro7.ro:8443/api/oferte";

export abstract class OfertaService {
  public static async findById(id: number): Promise<OfertaEntity> {
    return new Promise((resolve) => {
      axios.get(URL + "/" + id).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async acceptOferta(id: number): Promise<Boolean> {
    return new Promise((resolve) => {
      axios
        .post(URL + "/accept/" + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    });
  }

  public static async denyOferta(id: number): Promise<Boolean> {
    return new Promise((resolve) => {
      axios.post(URL + "/deny/" + id).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static async getAll(): Promise<OfertaEntity[]> {
    return new Promise((resolve) => {
      axios.get(URL).then((response) => {
        resolve(response.data);
      });
    });
  }
}
