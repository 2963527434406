import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputDropdown, FormInputMultiCheckbox, FormInputText } from ".";
import { UserService } from "../../../services/UserService";
import { IFormInputNew } from "./FormInputProps";
import { InvoiceEntity, UserEntity } from "../../../types";
import { FormInputUsername } from "./FormInputUsername";
import { DropdownAllInvoices } from "./DropdownAllInvoices";
const paymentOptions: string[] = [
  "Numerar",
  "OP",
  "POS-SB",
  "POS-BD",
  "Card Energie",
  "Mandat Postal",
];
const defaultValues = {
  codClientValue: 0,
  amountValue: 0,
  userNameValue: "",
  invoice: null,
  paymentMethodValue: "",
  locationValue: null,
  nrFactura: null,
  dropdownValue: "",
};

const validationSchema = yup
  .object({
    codClientValue: yup.number().required(),
    amountValue: yup.number().required(),
    userNameValue: yup.string().required("Numele clientului trebuie completat"),
    invoice: yup.string().nullable(),
    paymentMethodValue: yup.string().required("Metoda de plata trebuie aleasa"),
    locationValue: yup.string().nullable(),
    nrFactura: yup.string().nullable(),
    dropdownValue: yup.string().required("Codul client trebuie ales!"),
  })
  .required();

export const ModalAddSold: React.FC<{
  openModal: boolean;
  handleCloseModal: () => void;
}> = ({ openModal, handleCloseModal }) => {
  const { handleSubmit, reset, control, setValue, getValues } =
    useForm<IFormInputNew>({
      resolver: yupResolver(validationSchema),
      defaultValues: defaultValues,
    });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [nrFacturi, setNrFacturi] = useState<string[]>([]);
  const [ids, setIds] = useState<number[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [sume, setSume] = useState<string[]>([]);
  const [user, setUser] = useState<UserEntity | undefined>();
  const [dates, setDates] = useState<string[]>([]);
  const [invoice, setInvoice] = useState<InvoiceEntity | null>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const onSubmit = async (data: IFormInputNew) => {
    if (data.locationValue !== null && data.nrFactura !== null) {
      await UserService.addSaldoToUserRaw({
        suma: data.amountValue,
        codClient: parseInt(data.dropdownValue),
        location: data.locationValue,
        nrFactura: parseInt(data.nrFactura!),
        metoda: data.paymentMethodValue,
      }).then((res) => {
        console.log(res);
        setOpenSnackbar(true);
        reset({
          codClientValue: 0,
          amountValue: 0,
        });
      });
    }
  };

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setValue("userNameValue", user?.name!);
  }, [user, setValue]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "grid",
          gridRowGap: "20px",
          padding: "25px",
          margin: "10px 300px",
          position: "absolute" as "absolute",
          top: "40%",
          left: "40%",
          transform: "translate(-50%, -50%)",
          width: "100vh",
          minHeight: "50vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          textAlign: "center",
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Sold adaugat!
          </Alert>
        </Snackbar>
        <Typography variant="h4" fontFamily="Catesque" sx={{ mb: "5vh" }}>
          Înregistrează Sold
        </Typography>
        <FormInputDropdown
          name="dropdownValue"
          control={control}
          label="Dropdown Input"
          nrFacturi={nrFacturi}
          setNrFacturi={setNrFacturi}
          setIds={setIds}
          setLocations={setLocations}
          sume={sume}
          setSume={setSume}
          user={user}
          setUser={setUser}
          dates={dates}
          setDates={setDates}
          custom={true}
        />
        {nrFacturi.length > 0 && (
          <DropdownAllInvoices
            name={"invoice"}
            control={control}
            user={user}
            label="Facturi"
            setValue1={setValue}
            setInvoice={setInvoice}
            ids={ids}
            nrFacturi={nrFacturi}
            locations={locations}
            sume={sume}
            dates={dates}
          />
        )}
        {nrFacturi.length === 0 && user !== undefined && (
          <Typography fontFamily={"Catesque"}>Nu există facturi.</Typography>
        )}
        <FormInputText name={"amountValue"} control={control} label={"Sumă"} />
        <FormInputUsername
          name="userNameValue"
          control={control}
          label="Nume"
        />
        <Controller
          render={({
            field: { onChange, ...props },
            fieldState: { error },
            formState,
          }) => (
            <Autocomplete
              {...props}
              options={paymentOptions}
              freeSolo
              filterOptions={filterOptions}
              ListboxProps={{
                style: {
                  maxHeight: "135px",
                },
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  required
                  name={"paymentMethodValue"}
                  size={"small"}
                  id={"paymentMethodValue"}
                  type="text"
                  error={!!error}
                  helperText={error ? error.message : null}
                  label={"Metodă de plată"}
                  sx={{
                    "& input:disabled": {
                      color: "black", // Text color
                      fontFamily: "Catesque", // Font family
                      WebkitTextFillColor: "black",
                    },
                    "& input::-webkit-outer-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input::-webkit-inner-spin-button": {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& .MuiInputBase-input": {
                      color: "black", // Text color
                      fontFamily: "Catesque", // Font family
                    },

                    "& .MuiInputLabel-root": {
                      color: "black", // Label color
                      fontFamily: "Catesque", // Font family
                      fontSize: "18px",
                      "&.Mui-focused fieldset": {
                        borderColor: "#0054a6", // Focused border color
                      },
                      "&.Mui-disabled": {
                        color: "black", // Label color for disabled state
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0054a6", // Border color for disabled input
                      },
                      "& fieldset": {
                        borderColor: "#0054a6", // Border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#0054a6", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0054a6", // Focused border color
                      },
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      "& .MuiButtonBase-root": {
                        color: "#0054a6",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      },
                    },
                    userSelect: "none",
                  }}
                />
              )}
              onChange={(_, data) => {
                onChange(data);
              }}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  sx={{ fontFamily: "Catesque", fontSize: "16px" }} // Apply the desired font family and font size
                >
                  {option}
                </MenuItem>
              )}
            />
          )}
          defaultValue={""}
          name={"paymentMethodValue"}
          control={control}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          variant={"contained"}
          sx={{ backgroundColor: "#0054a6", fontFamily: "Catesque" }}
        >
          Adauga Sold
        </Button>
        <Button
          onClick={() => {
            reset({
              codClientValue: 0,
              amountValue: 0,
            });
          }}
          variant={"outlined"}
          sx={{
            borderColor: "#0054a6",
            color: "#0054a6",
            fontFamily: "Catesque",
          }}
        >
          Resetare
        </Button>
      </Box>
    </Modal>
  );
};

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: string) => option,
});
