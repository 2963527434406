import { Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import legal from "../assets/legal.jpg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";

export const IndexIntrebariFrecvente: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          mb: "10px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Index
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px" }}
            >
              Cum și când pot transmite indexul?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Indexul autocitit poate fi transmis doar în perioada 26-30 a
              lunii. Vei avea nevoie de codul de autocitire și indexul
              înregistrat de contorul tău.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              *Indexul înregistrat de contor, se va transmite doar până la
              separatorul de numere întregi ( până la cifrele roșii sau semnul
              virgulă “,”)
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Pentru a ne transmite indexul autocitit, îți punem la dispoziție
              mai multe modalități, în funcție de preferințele tale:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                component={"span"}
                color={"black"}
              >
                Online
                <span
                  onClick={() => navigate("/login")}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  aici
                </span>
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Prin intermediul SMS la 0746 205 583.
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "25px", mt: "25px" }}
            >
              Atenție! Te rugăm să te încadrezi în perioada de transmitere a
              indexului indicată pe factură, folosind codul unic de autocitire,
              urmat de introducerea indexului înregistrat de contor.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Ce fac dacă am transmis indexul greșit?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "25px" }}
            >
              Poți modifica indexul greșit până la finalul perioadei active de
              transmitere (perioada 26-30), reluând pașii de transmitere a
              indexului, iar noi vom lua în considerare doar ultimul index
              transmis de tine.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ my: "15px" }}
            >
              Ce fac dacă am transmis indexul greșit și nu mai sunt în perioada
              activă de transmitere?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "25px" }}
            >
              În cazul în care ai trimis indexul incorect și nu mai ești în
              perioada activă de transmitere pentru a-l putea corecta, există
              două posibilități:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Dacă indexul actual este mai mic decât indexul precedent
                citit/autocitit, acesta nu va fi luat în considerare.
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Dacă ai transmis un index mai mare decât cel real, iar până la
                prima citire de către reprezentanții companiei de distribuție
                acel index nu a fost atins (nu ai atins consumul transmis), vei
                fi refacturat conform consumului real (facturile cu consum
                eronat vor fi stornate).
              </Typography>
            </Box>
          </>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={legal}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
