import { Title } from "../components/Title";

import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import CookiesTable from "../components/CookiesTable";
import j5 from "../assets/j5.jpeg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const CookiesPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden", // Prevent horizontal scrollbar
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={j5}
          alt="jumbotron"
          style={{
            width: "100%", // Maintain aspect ratio and cover the container
            height: "100%",
            objectFit: "cover",
            position: "absolute", // Ensure images are absolutely positioned
            top: 0, // Position images at the top
            left: 0, // Position images at the left
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Politica Cookies" />
       
      </Box>
      <Box
        sx={{
          width: "60%",
          margin: "0 auto",
          height: "2400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          "@media (max-width: 1400px)": {
            width: "80%",
            height: "2700px",
          },
          "@media (max-width: 960px)": {
            height: "3200px",
            width: "80%",
          },
          "@media (max-width: 500px)": {
            height: "4800px",
            width: "90%",
          },
        }}
      >
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.5rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          Informații despre cookies
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Stimate utilizator, în cele ce urmează îți vor fi prezentate
          informațiile referitoare la politica de cookie a site-ului
          www.euro7.ro. Așadar, vă rugăm să citiți cu atenție aceste informații.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.2rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          Ce se înțelege prin cookie-uri?
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Cookie-ul este un fişier text de mici dimensiuni pe care un site îl
          salvează în dispozitivul Utilizatorului (computer, laptop, tabletă sau
          telefon) atunci când Utilizatorul viziteaza un site. Datorită
          cookie-urilor, site-ul respectiv poate reţine, pe o anumita perioadă
          de timp, acţiunile şi preferinţele Utilizatorului (spre exemplu:
          dimensiunea caracterelor, culoarea, date referitoare la autentificare
          şi alte preferinţe de afişare). Astfel, Utilizatorul nu mai este
          nevoit să reintroducă asemenea informații ori de câte ori revine la
          site sau navigheaza de pe o pagină pe alta. Majoritatea site-urilor
          fac acest lucru. Acest fișier NU este un virus sau un fișier de tip
          spyware, malware, web bugs sau hidden identifiers și NU accesează
          documente sau informații stocate pe dispozitivele Utilizatorului.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.2rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          De ce sunt necesare cookie-urile?
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "8px" }}
        >
          Aceste fișiere asigură buna funcționare a site-ului www.politex.ro,
          precum și o experiență plăcută de navigare pentru Utilizator. De
          asemenea, cookie-urile sunt utilizate în cadrul unor statistici
          anonime agregate care ne ajută să înțelegem modul în care Utilizatorul
          folosește acest site, astfel încât să putem îmbunătăți structura și
          conținutul acestuia, excluzând identificarea personală a
          Utilizatorului.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "8px" }}
        >
          Astfel, vă informăm că acest site utilizeaza cookie-uri pentru a vă
          oferi cea mai bună experiență posibilă, urmând a fi utilizate doar
          acele cookie-uri pe care Poli Tex le consideră necesare și utile.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Acest site nu foloseste cookie-uri pentru a colecta informații
          personale, cu toate acestea, utilizarea acestuia poate permite
          efectuarea de legături între informațiile conținute într-un cookie și
          informațiile Utilizatorului cu caracter personal colectate prin alte
          mijloace (de exemplu, formularele de creare cont).
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.2rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          Ce tip de cookie-uri sunt folosite pe acest site?
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          fontWeight={"bold"}
          component={"div"}
          sx={{ mb: "10px" }}
        >
          • Cookie-urile strict necesare
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Reprezinta cookie-urile esentiale care permit Utilizatorului sa
            navigheze pe acest site si sa foloseasca caracteristicile acestuia
            (spre exemplu: grafica, design, culori). Aceste cookie-uri nu
            colecteaza informatii despre Utilizator care ar putea fi folosite
            pentru publicitate sau pentru a inregistra activitatea
            Utilizatorului pe internet.
          </Typography>
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          fontWeight={"bold"}
          component={"div"}
          sx={{ mb: "10px" }}
        >
          • Cookie-urile de performanță
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Reprezinta cookie-urile care colecteaza informatii anonime despre
            modul in care Utilizatorul foloseste acest site, de exemplu, care
            sunt paginile vizualizate cel mai des si/sau daca Utilizatorul
            intampina mesaje de eroare. Aceste cookie-uri nu colecteaza
            informatii care pot identifica Utilizatorul, toate datele
            colectate/procesate de aceste cookie-uri sunt anonime, fiind
            utilizate exclusiv de către Euro7 sau în numele Euro7. Cookie-urile
            de performanta sunt folosite doar pentru a imbunatati modul in care
            functioneaza acest site.
          </Typography>
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          fontWeight={"bold"}
          component={"div"}
          sx={{ mb: "10px" }}
        >
          • Cookie-uri privind functionalitatea
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Reprezinta cookie-urile care inregistreaza alegerile pe care
            Utilizatorul le face (cum ar fi: dimensiunea caracterelor) si ofera
            Utilizatorului caracteristici personalizate de folosire a site-ului.
            Acest informatii se referă in mod strict la acest site, neputand
            urmari/verifica alte site-uri pe care Utilizatorul le viziteaza.
          </Typography>
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          fontWeight={"bold"}
          component={"div"}
          sx={{ mb: "15px" }}
        >
          • Cookie-urile de directionare
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Reprezinta cookie-urile care colecteaza informatii despre navigarea
            Utilizatorului în sopul de a afișa anunturi sau mesaje relevante si
            in conformitate cu interesele acestuia. Uneori cookie-urile de
            directionare sunt legate de alte site-uri (spre exemplu, dar fara a
            se limita la: retele de socializare, furnizori/prestatori de bunuri
            si servicii).
          </Typography>
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Aceste categorii de cookie-uri sunt clasificate fie ca temporare
          (“cookie-uri de sesiune”), fie pe termen mai lung (“cookie-uri
          permanente”).
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          “Cookie-urile de sesiune” inregistreaza actiunile Utilizatorului
          intr-o singura sesiune de navigare pe site. Aceasta “sesiune” de
          navigare incepe atunci cand pagina site-ului este deschisa si se
          termina atunci cand navigatorul de Internet este închis. Practic, la
          terminarea sesiunii, cookie-urile sunt sterse pentru totdeauna.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          “Cookie-urile permanente” sunt acele cookie-uri cele care sunt stocate
          in dispozitivul Utilizatorului până când sunt șterse sau își ating
          data de expirare. Aceste cookie-uri sunt stocate pentru o durată mai
          mare de timp și sunt activate automat atunci cand Utilizatorul
          viziteaza din nou un anumit site. Cookie-urile persistente pot furniza
          informații referitoare la numărul de vizitatori/utilizatori ai unui
          site, o medie a timpului petrecut pe o anumită pagină, performanțele
          unui site web, etc. Chiar daca aceste cookie-uri au o durată de viață
          îndelungată, pot fi șterse oricând din dispozitivele Utilizatorului
          prin modificarea setărilor Browser-ului folosit.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          O a treia clasificare a cookie-urilor se face in functie de sursa
          acestora: “cookies-uri primare” sau “cookies-uri terte parti”.
          “Cookies-urile primare” sunt cookies-urile create de acest site, in
          timp ce “cookies-urile terte parti” sunt create de catre un alt site
          decat cel vizitat. Acest site poate permite utilizarea/instalarea de
          “cookies-uri terte parti” doar din partea site-urilor aprobate, in
          prealabil, de catre politex.ro.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Informaţiile asociate cookie-urilor utilizate pe acest site nu sunt
          utilizate pentru a vă identifica personal. În plus, ţinem sub control
          datele referitoare la preferinţele Utilizatorului de navigare.
          Cookie-urile nu sunt folosite în alte scopuri decât cele descrise
          anterior.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "25px" }}
        >
          Activarea cookie-urilor nu este strict necesară pentru funcţionarea
          site-ului, dar vă poate îmbunătăţi experienţa de navigare. Puteţi
          şterge sau bloca cookie-urile însă, dacă faceţi acest lucru, s-ar
          putea ca anumite caracteristici ale site-ului să nu funcţioneze
          corespunzător.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.2rem"}
          fontWeight={"bold"}
          sx={{ mb: "20px" }}
        >
          Care sunt cookie-urile utilizate pe acest site?
        </Typography>
        <CookiesTable />
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Euro7 folosește serviciile următoarelor terțe părți în scopul oferirii
          de funcționalități, pentru analiză și pentru publicitate. Este posibil
          ca aceste terțe părți să seteze și să folosească cookie-uri și/sau
          tehnologii similare pe website-urile și în aplicațiile operate de
          Euro7 , precum și pe website-uri și în aplicații altele decât cele
          operate de Euro7.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Pentru mai multe detalii, consultați politicile de protecție a datelor
          precum și ghidurile de administrare a preferințelor utilizatorilor și
          posibilitățile de dezactivare oferite de companiile care gestionează
          aceste servicii, după caz.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.5rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          Cookie-urile prelucreaza date personale?
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          Datele cu caracter personal colectate prin utilizarea cookie-urilor
          pot fi colectate numai pentru a facilita anumite funcționalități
          pentru Utilizator. Aceste date sunt criptate astfel încât accesul
          persoanelor neautorizate la acestea este securizat.
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1.5rem"}
          fontWeight={"bold"}
          sx={{ mb: "10px" }}
        >
          Ștergerea cookie-urilor
        </Typography>
        <Typography
          fontFamily={"Catesque"}
          fontSize={"1rem"}
          sx={{ mb: "15px" }}
        >
          În general, o aplicație folosită pentru accesarea paginilor web
          permite salvarea cookie-urilor pe terminal în mod implicit. Aceste
          setări pot fi schimbate în așa fel încât administrarea automată a
          cookie-urilor să fie blocată de browser-ul web sau Utilizatorul sa fie
          informat de fiecare dată când cookie-uri sunt trimise către terminalul
          său. Informații detaliate despre posibilitățile și modurile de
          administrare a cookie-urilor pot fi găsite în zona de setări a
          aplicației (browser-ului web). Limitarea folosirii cookie-urilor poate
          afecta anumite funcționalități esențiale ale paginii web.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
};
