import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(
  nume: string,
  categorie: string,
  tip: string,
  scop: string,
  necesar: string
) {
  return { nume, categorie, tip, scop, necesar };
}

const rows = [
  createData(
    "ext_ci_session",
    "Funcționalitate",
    "Sesiune",
    "cod general random pentru identificare anonima sesiune acces",
    "Da"
  ),
  createData(
    "GeneralCookieState",
    "Funcționalitate",
    "Sesiune",
    "retine „Sunt de acord” la Cookie bar",
    "Da"
  ),
  createData(
    "euro7_uis_id_client",
    "Funcționalitate",
    "Permanent",
    "ID pentru identificare client dupa autentificare (criptat)",
    "Nu"
  ),
];

export default function CookiesTable() {
  return (
    <TableContainer component={Paper} sx={{ mb: "20px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nume Cookies</TableCell>
            <TableCell align="left">Categorie</TableCell>
            <TableCell align="left">Tip</TableCell>
            <TableCell align="left">Scop</TableCell>
            <TableCell align="left">Strict Necesar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.nume}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.nume}
              </TableCell>
              <TableCell align="left">{row.categorie}</TableCell>
              <TableCell align="left">{row.tip}</TableCell>
              <TableCell align="left">{row.scop}</TableCell>
              <TableCell align="left">{row.necesar}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
