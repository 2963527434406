import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FormInputProps } from "./FormInputProps";
import { InvoiceService } from "../../../services/InvoiceService";
import { Controller } from "react-hook-form";
import { MenuItem } from "@mui/material";
import { InvoiceEntity } from "../../../types";

type Option = {
  id: number;
  value: InvoiceEntity;
  text: string;
};

export const DropdownAllInvoices: React.FC<FormInputProps> = ({
  name,
  control,
  nrFacturi,
  setInvoice,
  locations,
  setValue1,
  ids,
  sume,
  dates,
}) => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    console.log(nrFacturi);
    buildOptions();
  }, [nrFacturi]);

  const getInvoiceEntity = async (id: number) => {
    const invoice = await InvoiceService.getInvoiceById(id);
    return invoice;
  };
  const buildOptions = async () => {
    if (nrFacturi && ids && locations && sume) {
      // Use Promise.all to wait for all promises to resolve
      const promises = nrFacturi.map(async (item: string, index: number) => {
        const invoice = await getInvoiceEntity(ids[index]);
        return {
          id: index,
          value: invoice,
          text: `${locations[index]}${item} - ${sume[index]} RON`,
        };
      });

      const newOptions: Option[] = await Promise.all(promises);
      setOptions(newOptions);
    }
  };

  return (
    <Controller
      rules={{ required: true }}
      render={({
        field: { onChange, ...props },
        fieldState: { error },
        formState,
      }) => (
        <Autocomplete
          {...props}
          options={options.map((option) => option.text)}
          noOptionsText={"Nimic găsit."}
          ListboxProps={{
            style: {
              maxHeight: "100px",
            },
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              name={"invoice"}
              size={"small"}
              id={"invoice"}
              error={!!error}
              helperText={error ? error.message : null}
              label={"Factura"}
              sx={{
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "& input:disabled": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                  WebkitTextFillColor: "black",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& .MuiInputBase-input": {
                  color: "black", // Text color
                  fontFamily: "Catesque", // Font family
                },

                "& .MuiInputLabel-root": {
                  color: "black", // Label color
                  fontFamily: "Catesque", // Font family
                  fontSize: "18px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                  "&.Mui-disabled": {
                    color: "black", // Label color for disabled state
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0054a6", // Border color for disabled input
                  },
                  "& fieldset": {
                    borderColor: "#0054a6", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#0054a6", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0054a6", // Focused border color
                  },
                },
                "& .MuiAutocomplete-endAdornment": {
                  "& .MuiButtonBase-root": {
                    color: "#0054a6",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                },
                userSelect: "none",
              }}
            />
          )}
          onChange={(_, data) => {
            onChange(data);
            const option = options.find(
              (option) => option.text === data
            )!.value;
            setInvoice!(option);
            setValue1!("locationValue", option.location);
            setValue1!("nrFactura", option.nrFactura);
          }}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              sx={{ fontFamily: "Catesque", fontSize: "16px" }} // Apply the desired font family and font size
            >
              {option}
            </MenuItem>
          )}
        />
      )}
      defaultValue={""}
      name={name}
      control={control}
    />
  );
};

function transformDateFormat(dateStr: string): string {
  const [year, month, day] = dateStr.split("-");
  return `${day}/${month}/${year}`;
}
