import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import mdp from "../assets/mdp.jpg";
import CheckBox from "@mui/icons-material/CheckBox";
import reluare1 from "../assets/reluare1.jpg";
import reluare2 from "../assets/reluare2.jpg";
import reluare4 from "../assets/reluare4.jpg";
import { NavCard } from "../components/incasari/NavCard";
import { useNavigate } from "react-router-dom";
import "../pages/incasari/IncasariHomePage.css";
import { navigateToPage } from "../utils";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import preaviz from "../assets/preaviz.jpg";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const ReluareFurnizariGazePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={reluare1}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Reluare Furnizare Gaze" />
      </Box>
      <Box
        sx={{
          width: "82%",
          height: "1100px",
          display: "flex",
          margin: "0 auto",
          mt: "50px",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          borderRadius: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            textAlign: "left",
            justifyContent: "left",
            padding: "20px",
            mt: "50px",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "25px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              {`Preavizul de Deconectare`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              La Euro7, ne angajăm să furnizăm gaze naturale într-un mod sigur
              și eficient tuturor clienților noștri. Cu toate acestea, există
              situații în care poate fi necesară deconectarea serviciului de
              gaze naturale. Această secțiune vă va informa despre procesul de
              deconectare și cum puteți evita această situație.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Preavizul de deconectare îl poți primi atunci când ai una sau mai
              multe facturi restante. O factură restantă inseamnă când scandența
              ei a fost depășită. În situația în care ai mai multe facturi
              neachitate, este posibilă primirea a mai multor preavize de
              deconectare. Dacă nu plătești toate facturile restante, preavizele
              corespunzătoare facturilor rămase neachitate își vor menține
              valabilitatea.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              sx={{ mb: "10px", textDecoration: "underline" }}
            >
              Motive pentru Deconectare
            </Typography>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Neplată
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"0.9rem"}
                color={"black"}
              >
                Dacă factura de gaze naturale nu este achitată în termenul
                stabilit, acesta poate fi un motiv de deconectare. Oferim un
                termen de grație și notificări pentru a evita această situație.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Siguranța
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"0.9rem"}
                color={"black"}
              >
                Deconectarea poate fi necesară pentru a asigura siguranța
                clienților și a comunității. Aceasta include situații în care
                instalațiile de gaz sunt deteriorate sau există o scurgere de
                gaz.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Lucrări de întreținere
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"0.9rem"}
                color={"black"}
              >
                Ocazional, pentru lucrări de întreținere sau upgrade ale
                rețelei, poate fi necesară întreruperea temporară a furnizării
                gazului.
              </Typography>
            </Box>

            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              sx={{ mb: "10px", textDecoration: "underline", mt: "15px" }}
            >
              Cum să Evitați Deconectarea?
            </Typography>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
              }}
            >
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Plata la Timp
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
              }}
            >
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Întreținerea Instalațiilor
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mb: "15px",
                mt: "15px",
              }}
            >
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Răspunsul la Notificări
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px", mt: "15px" }}
            >
              La Euro7, scopul nostru este să menținem o relație solidă și de
              încredere cu clienții noștri. Comunicarea eficientă și promptă
              este cheia în prevenirea deconectărilor neplăcute. Pentru orice
              întrebări sau îngrijorări, echipa noastră de servicii pentru
              clienți este întotdeauna disponibilă să vă ajute.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "50%",
            height: "100%",
            display: "inline-block",
          }}
        >
          <Box
            component="img"
            src={preaviz}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "0 25px 25px 0",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1700px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: `calc(27.6%)`,
            display: "flex",
            background: "#bdbdbd",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "82%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={reluare2}
              height={"250px"}
              width={"380px"}
              alt={"tarife1"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Reluarea furnizării
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Pentru a putea relua furnizarea gazelor naturale la locul tău de
                consum, te sfătuim să:
              </Typography>
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Achiți integral toate facturile neachitate până în prezent și
                  dobânzile penalizatoare pentru întârzierea în realizarea
                  plății facturilor.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  component={"span"}
                >
                  Să iei legătura cu noi{" "}
                  <span
                    onClick={() => (navigate("/contact"))}
                    style={{
                      color: "#0054a6",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    aici.
                  </span>{" "}
                  prin email sau telefon
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                După ce ne trimiți dovada plății, noi vom face verificările
                necesare și dacă totul este în regulă, colegii noștri te vor
                reconecta. Pe următoarea factură, pe lângă totalul de plată
                pentru consumul de gaze naturale, vei avea de achitat și taxele
                pentru deconectare și reconectare.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", height: `calc(27.6%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "82%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={reluare4}
              height={"250px"}
              width={"380px"}
              alt={"tarife3"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Deconectare și reconectare
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Întreruperea serviciului reprezintă suspendarea alimentării cu
                gaze naturale la adresa unde există întârzieri în achitarea
                facturilor.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Restabilirea serviciului presupune reluarea livrării de gaze
                naturale, ceea ce se poate face numai după ce sunt achitate
                toate datoriile restante.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                După ce alimentarea este restabilită, factura următoare va
                include, pe lângă costurile pentru consumul de gaze, și taxele
                pentru întrerupere și restabilire, conform tarifelor stabilite
                de distribuitorul tău.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(27.6%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "82%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Unde și cum pot plăti restanțele?
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Opțiunile de plată pentru facturile neachitate și cele curente
                sunt identice cu cele descrise pe spatele facturii, unde vei
                găsi instrucțiuni detaliate.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "10px" }}
              >
                Facturile pot fi achitate:
              </Typography>
              
              <Box sx={{ display: "flex" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  component={"span"}
                >
                  Ordin de plată, trimite-ne dovada plății prin email
                  <span
                    onClick={() => (navigate("/contact"))}
                    style={{
                      color: "#0054a6",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    aici
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <CheckBox sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  component={"span"}
                  display={"flex"}
                >
                  Numerar la orice{" "}
                  <span
                    onClick={() => (navigate("/contact"))}
                    style={{
                      color: "#0054a6",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    punct de lucru
                  </span>{" "}
                  .
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Te rugăm să ne trimiți confirmarea ordinului de plată prin
                email, alături de informațiile tale personale: nume, prenume,
                cod client și codul punctului de consum, pentru a ne permite să
                confirmăm plata și să rezolvăm orice probleme de plată.
              </Typography>
            </Box>
            <Box
              component={"img"}
              src={mdp}
              height={"250px"}
              width={"380px"}
              alt={"tarife2"}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(20%)`,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "82%",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <NavCard
              text={"Ajutor"}
              boxColor={"red"}
              path1={
                "M12 16V15.1432C12 14.429 12.357 13.762 12.9512 13.3659L13.5497 12.9669C14.4558 12.3628 15 11.3459 15 10.2569V10C15 8.34315 13.6569 7 12 7V7C10.3431 7 9 8.34315 9 10V10"
              }
              circle={{ cx: "12", cy: "20", r: "0.5" }}
              circle2={{ cx: "12", cy: "13", r: "10" }}
              onClick={() => navigateToPage(navigate, "/intrebari-frecvente")}
            />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
