import logo2 from "../assets/logo2.png";
import anpc1 from "../assets/anpc1.png";
import anpc2 from "../assets/anpc2.png";
import {
  Box,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToPage } from "../utils";

export const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "650px",
        backgroundColor: "#282424",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        overflow: "hidden",
        position: "relative",
        "@media (max-width: 1344px)": {
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
        },
      }}
    >
      <Box
        sx={{
          width: "75%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            mb: "3vh",
            "@media (max-width: 1344px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <img alt="logo-footer" src={logo2} width={"200px"} height={"100px"} />
          <Typography
            fontSize={"1.5rem"}
            sx={{ color: "white", mt: "3vh", mr: "4vh" }}
            fontFamily={"Catesque"}
          >
            {"Comfortul casei tale ca tu să încălzești lumea din jur."}
          </Typography>
        </Box>
        <Divider variant="middle" sx={{ background: "gray" }} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            ml: "4vh",
            mt: "6vh",
            textAlign: "left",
            gap: "10vh",
            whiteSpace: "nowrap",
            "@media (max-width: 1344px)": {
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              ml: "unset",
            },
          }}
        >
          <Stack spacing={1} direction={"column"}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              color={"#0054a6"}
              sx={{ pb: "2vh" }}
            >
              Euro Seven
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/despre"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Despre noi
            </Typography>
            {/* <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/noutati")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Presa
            </Typography> */}
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/contact")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Contact
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/contact")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Puncte de Lucru
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/gaze-naturale")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Gaze Naturale
            </Typography>
          </Stack>
          <Stack spacing={1} direction={"column"}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              color={"#0054a6"}
              sx={{ pb: "2vh" }}
            >
              Utile
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/intrebari-frecvente")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Intrebari Frecvente
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/reclamatii")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Reclamatii
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/cont-client")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Cont Client
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/modalitati-de-plata"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Plata Online
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/factura-explicata"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Factura Ta Explicată
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/reglementare-tarife"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Tarife Reglementate
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/reluare-furnizare-gaze"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Reluarea Furnizarii
            </Typography>
          </Stack>
          <Stack spacing={1} direction={"column"}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              color={"#0054a6"}
              sx={{ pb: "2vh" }}
            >
              Informatii Legislative
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/legislatie"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Legislatie
            </Typography>

            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => (window.location.href = "https://anre.ro/")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              ANRE
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => (window.location.href = "https://posf.ro/")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              POSF
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() =>
                (window.location.href =
                  "https://anre.ro/comparator-gaze-naturale/")
              }
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Comparator Preț
            </Typography>
          </Stack>
          <Stack spacing={1} direction={"column"}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              color={"#0054a6"}
              sx={{ pb: "2vh" }}
            >
              Distribuție
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() => navigateToPage(navigate, "/noutati")}
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Anunțuri
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              href="/servicii-online"
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Servicii
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"white"}
              component="a"
              onClick={() =>
                (window.location.href =
                  "https://portal.anre.ro/PublicLists/AtestatGN")
              }
              sx={{
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Firme Autorizate
            </Typography>
          </Stack>
          <Stack spacing={2} direction={"column"}>
            {/* <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              color={"#0054a6"}
              sx={{ pb: "2vh" }}
            >
              Abonare la Newsletter
            </Typography>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 225,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Adresa Email"
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <ArrowForwardIcon sx={{ color: "#0054a6" }} />
              </IconButton>
            </Paper> */}
            <Box
              component="img"
              alt="anpc"
              src={anpc1}
              height={"65px"}
              width={"235px"}
              onClick={() =>
                (window.location.href = "https://anpc.ro/ce-este-sal/")
              }
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
            <Box
              component="img"
              alt="anpc1"
              src={anpc2}
              onClick={() =>
                (window.location.href =
                  "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO")
              }
              height={"65px"}
              width={"235px"}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Stack>
        </Box>
        <Divider variant="middle" sx={{ background: "gray", mt: "6vh" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "2vh",
            mb: "2vh",
            "@media (max-width: 1344px)": {
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            },
          }}
        >
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            sx={{ color: "white", ml: "4vh", mt: "2vh" }}
          >
            ©EuroSeven
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "1vh",
              mr: "4vh",
              "@media (max-width: 1344px)": {
                mb: "unset",
              },
            }}
          >
            
            <Typography
              fontFamily={"Catesque"}
              component="a"
              onClick={() => navigateToPage(navigate, "/termeni-si-conditii")}
              fontSize={"1rem"}
              sx={{
                color: "white",
                ml: "4vh",
                mt: "2vh",
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
                "@media (max-width: 1344px)": {
                  ml: "unset",
                },
              }}
            >
              Termeni și Condiții
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component="a"
              onClick={() => navigateToPage(navigate, "/protectia-datelor")}
              sx={{
                color: "white",
                ml: "4vh",
                mt: "2vh",
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              GDPR
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              component="a"
              onClick={() => navigateToPage(navigate, "/politica-cookies")}
              sx={{
                color: "white",
                ml: "4vh",
                mt: "2vh",
                textDecoration: "none",
                ":hover": {
                  cursor: "pointer",
                  color: "#0054a6",
                },
              }}
            >
              Cookies
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
