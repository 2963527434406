import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import card2 from "../assets/card2.jpg";
import CheckBox from "@mui/icons-material/CheckBox";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const DesprePage: React.FC = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={card2}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Despre Noi" />
       
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1000px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Cine Suntem?
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            EURO SEVEN INDUSTRY SRL este o companie romaneasca privata,
            infiintata in anul 2000, avand ca obiect principal de activitate
            instalarea, furnizarea si distributia de gaze naturale.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            In urma castigarii licitatiilor, am incheiat cu Ministerul Economiei
            si Comertului contracte pentru concesionarea serviciului public
            privind distributia de gaze naturale, pe o perioada de 49 de ani,
            pentru 4 comune din judetul Giurgiu :
          </Typography>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Bolintin-Deal
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Cosoba
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Joita
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Sabareni
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Furnizam gaze naturale pentru peste 10.000 de case, spitale, scoli,
            parcuri industriale.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Istorie
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            In baza acestor contracte de concesiune societatea noastra a
            efectuat investitii pentru realizarea a peste 125 de km de retea de
            distributie a gazelor naturale si a doua statii de reglare masurare,
            una in comuna Bolintin Deal iar cealalta in comuna Joita, necesare
            in vederea interconectarii cu sistemul national de transport si a
            comercializarii gazelor naturale pentru comunele mai sus mentionate.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Datorita investitiei societatii noastre comunele pentru care detinem
            concesiunile a crescut nivelul de trai si s-au dezvoltat anual in
            ultimii zece ani astfel ca investiile au depasit peste 800 milioane
            de euro si creând un numar de aproximativ 5000 de locuri de munca.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
