import { Title } from "../components/Title";
import { Box, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import "../pages/incasari/IncasariHomePage.css";
import CheckBox from "@mui/icons-material/CheckBox";
import cumsefac from "../assets/cumsefac.jpg";

export const CumSeFactureazaConsumulDeGazePage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={cumsefac}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "20%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontFamily={"Catesque"}
            color="white"
          >
            Cum se facturează consumul?
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "2200px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Cum se factureaza consumul de gaze naturale?
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Modul de facturare a consumului se va modifica dupa cum urmeaza: pe
            factura nu vor mai aparea mc de gaze consumati, ci kwh, care vor
            exprima energia produsa prin arderea gazelor consumate.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Noua factură - de la m³ la kwh
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Începând cu data de 1 iulie 2008, Euro Seven Industry SRL a introdus
            sistemul de facturare a consumului de gaze naturale exprimat în
            unităţi de energie (lei / kwh), ca şi în cazul energiei electrice,
            în conformitate cu prevederile Legii gazelor nr. 351/2004.Astfel,
            modul de facturare a consumului se modifica după cum urmează: pe
            factură nu vor mai apărea mc de gaze consumaţi, ci kwh, care vor
            exprima energia produsă prin arderea gazelor consumate.Măsura este
            impusă de reglementările adoptate de România în scopul alinierii
            serviciilor publice la nivelul celor din Comunitatea Europeană.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Care sunt actele normative ce motivează schimbarea?
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Directiva Parlamentului European şi a Consiliului 2003/55/CE -
              piaţa internă în sectorul gazelor naturale.
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Legea gazelor nr. 351/2004
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Decizia ANRE 62/24.06.2008 privind Regulamentul de măsurare a
              gazelor naturale tranzacţionate în România.
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <CheckBox sx={{ color: "green", mr: "10px" }} />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Codul Fiscal
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Avantajele facturării în kwh:
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Comparaţia între diferitele sisteme de încălzire (ex: cu gaze
            naturale, cu energie electrică) se va face mult mai uşor, de către
            consumator.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Cum se determină cantitatea de energie consumată?
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Pentru a determina cantitatea de energie consumată în urma
            utilizării gazelor naturale, volumul în mc este înmulţit cu puterea
            calorifică superioară, conform formulei:
          </Typography>
          <Box sx={{ my: "10px", position: "relative", ml: "20px" }}>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              color={"#0054a6"}
            >
              E = V x PCs
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ position: "absolute", bottom: -15, left: -12 }}
            >
              (kwh) (mc) (kwh/mc)
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Energia consumată, în kwh, exprimă energia produsă prin arderea
            gazelor naturale consumate.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            PCS : Puterea Calorifică Superioară a gazelor naturale (kwh / mc),
            indică energia totală degajată în aer prin arderea completă a unui
            mc de gaz .
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "auto",
            padding: "35px",
            background: "#f2f2f2",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "25px",
            gap: "25px",
            mt: "50px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "35px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Determinarea Puterii Calorifice Superioare (PCS)
            </Typography>
          </Box>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Valorile PCS vor fi determinate pentru fiecare zonă şi se vor afisa
            lunar pe site in directorul de baza Home – Putere Calorifica Lunara
            , în conformitate cu Regulamentul de măsurare a gazelor naturale
            tranzacţionate în România.
          </Typography>
          <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
            Criteriile pe baza cărora este calculată PCS au fost reglementate de
            Autoritatea Naţională de Reglementarea în Domeniul Energiei.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
