import AddIcon from "@mui/icons-material/Add";
import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Modal,
  styled,
} from "@mui/material";
import { UserMenu } from "../../components/admin/UserMenu";
import { useEffect, useState } from "react";
import { UserEntity } from "../../types";
import { UserService } from "../../services/UserService";
import axios from "../../axios";
import debounce from "lodash.debounce";
import { Euro7DataGrid } from "../../components/admin/Euro7DataGrid";
import { SearchBar } from "../../components/SearchBar";
import { ModalActivate } from "../../components/admin/modal/ModalActivate";
import { Controller, useForm } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<UserEntity[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [userId, setUserId] = useState<number>(0);

  const [anchorElActivate, setAnchorElActivate] = useState<null | HTMLElement>(
    null
  );
  const openActivate = Boolean(anchorElActivate);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const defaultValues = {
    fileValue: undefined,
  };

  const { handleSubmit, control } = useForm<{
    fileValue: string | undefined;
  }>({
    defaultValues: defaultValues,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    setFile(selectedFile);
  };

  const onSubmit = async (data: { fileValue: string | undefined }) => {
    if (!file) {
      setErrorSnackbarOpen(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    // Simulating an async operation (e.g., file upload)

    try {
      await axios
        .post("https://secure.euro7.ro:8443/api/users/uploadExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setSuccessSnackbarOpen(true);
          setLoading(false);
        });
    } catch (error) {
      setErrorSnackbarOpen(true);
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "codClient",
      headerName: "Cod Client",
      width: 175,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Nume",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <span style={{ color: params.row.inactive ? "grey" : "inherit" }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "address",
      headerName: "Adresă",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "restDePlataTotal",
      headerName: "Rest De Plata",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const restDePlata: number = Number(params.value);
        return (
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {restDePlata.toFixed(2) + " RON"}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <UserMenu
            id={id}
            users={users.length > 0 ? users : []}
            handleOpenSnackbar={handleOpenSnackbar}
            setUsers={setUsers}
            handleClick={handleClick}
            setUserId={setUserId}
          />
        );
      },
    },
  ];

  const fetchSearchResults = async (query: string) => {
    if (query.trim() !== "") {
      const response = await axios.get(
        "https://secure.euro7.ro:8443/api/users/search?keyword=" + query
      );
      setSearchedUsers(response.data);
    } else {
      setSearchedUsers([]);
    }
  };

  useEffect(() => {
    const debouncedFetchSearchResults = debounce(fetchSearchResults, 1);
    debouncedFetchSearchResults(searchText);
  }, [searchText]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  const fetchUsers = async () => {
    await UserService.getAllUsers()
      .then((res) => {
        setUsers(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorElActivate(event.currentTarget);
    setUserId(id);
  };

  const handleClose = () => {
    setAnchorElActivate(null);
  };

  return (
    <Box sx={{ width: "80%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ position: "absolute", bottom: 0, left: 0, padding: "20px" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Utilizatorul a fost actualizat cu succes!
        </Alert>
      </Snackbar>
      <ModalActivate
        open={openActivate}
        handleClose={handleClose}
        userId={userId}
      />
      <Box
        sx={{
          width: "100%",
          position: "relative",
          height: "8vh",
          textAlign: "left",
        }}
      >
        <Typography
          fontFamily={"Catesque"}
          fontWeight={"bold"}
          fontSize={"32px"}
        >
          Clienți
        </Typography>
        <Button
          onClick={handleOpenModal}
          startIcon={<AddIcon />}
          sx={{
            fontFamily: "Catesque",
            backgroundColor: "#0054a6",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#0054a6",
              color: "white",
            },
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          Adauga
        </Button>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "grid",
            gridRowGap: "20px",
            padding: "50px",
            margin: "10px 300px",
            position: "absolute" as "absolute",
            top: "40%",
            left: "30%",
            transform: "translate(-50%, -50%)",
            width: "70vh",
            minHeight: "20vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" fontFamily="Catesque" sx={{ mb: "5vh" }}>
            Uploadeaza Useri
          </Typography>

          <Controller
            name={"fileValue"}
            control={control}
            render={({
              field: { onChange, ...props },
              fieldState: { error },
              formState,
            }) => (
              <Button
                component="label"
                variant="text"
                startIcon={<CloudUploadIcon />}
                sx={{ width: "50%", color: "#0054a6", margin: "0 auto" }}
              >
                Incarca Excel Useri
                <VisuallyHiddenInput
                  {...props}
                  onChange={(e) => {
                    handleFileChange(e);
                    onChange(e);
                  }}
                  required
                  value={""}
                  type="file"
                  disabled={loading}
                />
              </Button>
            )}
            defaultValue={""}
          />
          {loading && (
            <CircularProgress sx={{ margin: "0 auto", width: "50%" }} />
          )}
          <Snackbar
            open={successSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSuccessSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSuccessSnackbarOpen(false)}
              severity="success"
            >
              Useri incarcati cu success!
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => setErrorSnackbarOpen(false)}
          >
            <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
              A aparut o eroare. Va rugam sa incercati din nou!
            </Alert>
          </Snackbar>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant={"contained"}
            sx={{
              backgroundColor: "#0054a6",
              fontFamily: "Catesque",
              width: "20%",
              margin: "0 auto",
            }}
            disabled={loading || !file}
          >
            Uploadeaza
          </Button>
        </Box>
      </Modal>
      <Box sx={{ mb: "10vh" }}>
        <SearchBar
          handleSearchInputChange={handleSearchInputChange}
          searchText={searchText}
          forWho="utilizator"
          users={searchedUsers.length > 0 ? searchedUsers : users}
        />
        <Euro7DataGrid
          searchText={searchText}
          users={users}
          columns={columns}
          searchedUsers={searchedUsers}
        />
      </Box>
    </Box>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
