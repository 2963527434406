import { AccordionDetails, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { navigateToPage } from "../utils";
import { useNavigate } from "react-router-dom";

export const DrepturiSiObligatii: React.FC<{
  handleChange: (newValue: number) => void;
}> = ({ handleChange }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "auto",
        width: "80%",
        margin: "0 auto",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          width: "100%",
          height: "90%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: "25px",
            mb: "25px",
            ml: "3px",
          }}
        >
          <Box
            sx={{
              width: "6px",
              height: "50px",
              backgroundColor: "#0054a6",
              mr: "15px",
            }}
          />
          <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
            Drepturi și Obligații
          </Typography>
        </Box>
        <Box
          sx={{
            height: "auto",
            width: "100%",
          }}
        >
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Drepturile și obligațiile Clientului Final
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Punem la dispoziția ta toate informațiile necesare pentru ca tu
                să fii mereu bine informat cu privire la drepturile și
                responsabilitățile tale astfel încat să beneficiezi pe deplin de
                serviciile noastre.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(
                    navigate,
                    "/drepturi-si-obligatii-client-final"
                  )
                }
                sx={{
                  mt: "15px",
                  color: "white",
                  margin: "0 auto",
                  fontFamily: "Catesque",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Drepturile și obligațiile Furnizorului
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                paddingBottom: "25px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Pentru a iți asigura cele mai bune servicii de furnizare a
                gazelor naturale, avem o serie de drepturi și obligații pe care
                ne angajăm să le respectăm. Poți consulta aici lista detaliată a
                drepturilor și obligațiilor noastre în calitate de furnizor de
                gaze naturale.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(navigate, "/drepturi-si-obligatii-furnizor")
                }
                sx={{
                  mt: "15px",
                  pr: "25px",
                  fontFamily: "Catesque",
                  color: "white",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Verificare și revizie
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "25px",
                gap: "15px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Fii precaut pentru a preveni accidentele! Verificarea si
                reviziile sunt proceduri obligatorii pentru a asigura siguranța
                casei tale si buna funcționare a instalațiilor de utilizare.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => handleChange(4)}
                sx={{
                  mt: "15px",
                  fontFamily: "Catesque",
                  pr: "25px",
                  color: "white",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Dreptul la Informare
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                paddingBottom: "25px",
                gap: "15px",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                In calitate de client, ai dreptul sa fii informat in relația cu
                noi.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(navigate, "/dreptul-la-informare")
                }
                sx={{
                  mt: "15px",
                  pr: "25px",
                  color: "white",
                  fontFamily: "Catesque",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Adresarea plângerilor
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "25px",
                gap: "15px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                În situatia în care nu ești mulțumit de activitatea noastra, ne
                poti sesiza oricand. Descoperă modalitătile de comunicare, cum o
                înregistrăm, cum o analizăm și modul în care o soluționăm.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(navigate, "/solutionarea-plangerilor")
                }
                sx={{
                  mt: "15px",
                  pr: "25px",
                  color: "white",
                  fontFamily: "Catesque",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>Legislație</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "25px",
                gap: "15px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Furnizarea de gaze naturale este o activitate reglementată de
                către instituțiile statului. Consultă legislația sub care ne
                desfășurăm activitatea.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigateToPage(navigate, "/legislatie")}
                sx={{
                  mt: "15px",
                  pr: "25px",
                  color: "white",
                  fontFamily: "Catesque",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Soluționarea Neînțelegerilor Precontractuale
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                paddingBottom: "25px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Aflați mai multe informații despre soluționarea neînțelegerilor
                precontractuale și procedurile alternative de rezolvare a
                disputelor accesând secțiunea dedicată pe site-ul nostru.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(
                    navigate,
                    "/solutionarea-neintelegilor-precontractuale"
                  )
                }
                sx={{
                  mt: "15px",
                  pr: "25px",
                  color: "white",
                  fontFamily: "Catesque",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded sx={{ mt: "25px", borderRadius: "25px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ textAlign: "left", borderRadius: "25px" }}
            >
              <Typography fontFamily={"Catesque"}>
                Schimbarea Furnizorului
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                paddingBottom: "25px",
                justifyContent: "space-between",
              }}
            >
              <Typography fontFamily={"Catesque"}>
                Aflați mai multe informații despre schimbarea furnizorului
                accesând secțiunea dedicată pe site-ul nostru.
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigateToPage(
                    navigate,
                    "/schimbarea-furnizorului"
                  )
                }
                sx={{
                  mt: "15px",
                  pr: "25px",
                  color: "white",
                  fontFamily: "Catesque",
                  margin: "0 auto",
                  background: "#0054a6",
                  ":hover": {
                    color: "white",
                    background: "#0054a6",
                  },
                }}
              >
                Vezi mai multe informații...
              </Button>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", userSelect: "none" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    ":hover": {
      cursor: "default",
    },
  },
}));
