import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import autocitire from "../assets/autocitire.jpg";

export const AutocitireIndex: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        background: "linear-gradient(to bottom,#FFFFFF,#dee2e6)",
        mt: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        overflowY: "hidden",
        overflowX: "hidden",
        gap: "35px",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          "@media(max-width: 1300px)": {
            width: "100%",
          },
          "@media(max-width: 1800px)": {
            gap: "25px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              mb: "25px",
            }}
          >
            <Box
              sx={{
                width: "6px",
                height: "50px",
                backgroundColor: "#0054a6",
                mr: "15px",
              }}
            />
            <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
              Autocitire Index
            </Typography>
          </Box>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            sx={{ mb: "15px" }}
          >
            În cadrul Euro7, ne dorim să facilităm experiența clienților noștri
            și să le oferim control asupra consumului lor de gaze naturale. Cu
            serviciul nostru inovator de autocitire a indexului, vă oferim
            posibilitatea de a monitoriza și gestiona consumul dumneavoastră
            într-un mod simplu și eficient.
          </Typography>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1.2rem"}
            fontWeight={"bold"}
            fontStyle={"italic"}
            sx={{ mb: "10px" }}
          >
            Cum funcționează autocitirea indexului?
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Accesați portalul nostru online
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Conectați-vă la contul dumneavoastră Euro7 prin portalul nostru
              securizat pe site-ul web. Dacă nu aveți deja un cont, vă invităm
              să vă creați unul în câțiva pași simpli.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Selectați opțiunea de autocitire
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              În panoul de control al contului dumneavoastră, veți găsi opțiunea
              de autocitire a indexului. Accesați această funcție pentru a
              începe procesul de autocitire.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Introduceți datele citirii
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Vă rugăm să introduceți valorile citite direct de pe contorul
              dumneavoastră. Asigurați-vă că informațiile introduse sunt corecte
              pentru o facturare precisă.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Verificați și confirmați
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              Revizuiți datele introduse și confirmați autocitirea. Dacă
              întâmpinați dificultăți sau aveți întrebări, echipa noastră de
              asistență este întotdeauna disponibilă pentru a vă ajuta.
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              color={"black"}
            >
              O altă metodă de transmitere a indexului este prin intermediul SMS
              la 0746 205 573. Autocitirile se transmit în perioada 26-30 a
              lunii.
            </Typography>
          </Box>
          <Typography
            fontFamily={"Catesque"}
            fontSize={"1rem"}
            sx={{ mb: "10px", mt: "20px" }}
          >
            La Euro7, ne mândrim cu angajamentul nostru față de inovație și
            servicii de calitate. Descoperiți beneficiile autocitirii indexului
            și bucurați-vă de o experiență mai simplă și mai transparentă în
            gestionarea consumului dumneavoastră de gaze naturale. Pentru orice
            întrebări sau asistență, vă stăm la dispoziție. Mulțumim că sunteți
            parte din familia Euro7!
          </Typography>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={autocitire}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
