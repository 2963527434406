import { Title } from "../components/Title";
import { Box, Button, Grid, Typography } from "@mui/material";
import { JumbotronOverlay } from "../components/JumbotronOverlay";
import { Footer } from "../components/Footer";
import { useNavigate } from "react-router-dom";
import "../pages/incasari/IncasariHomePage.css";
import consultaconsum from "../assets/consultaconsum.jpg";
import tarife1 from "../assets/tarife1.jpg";
import clientibusiness from "../assets/clientibusiness.jpg";
import { CardReview } from "../mui-treasury/card-review";
import facturaelec from "../assets/facturaelec.jpg";
import furnizor from "../assets/furnizor.jpg";
import plafonare from "../assets/plafonare.jpg";
import { NewsSection } from "../components/NewsSection";
import { navigateToPage } from "../utils";
import { JumbotronTitle } from "../components/JumbotronTitle";

export const GazeNaturalePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
        }}
      >
        <Title role={null} />
      </Box>
      <Box
        className="jumbotron-container"
        sx={{
          width: "100%",
          minHeight: "711px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={tarife1}
          alt="jumbotron"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            animation: "zoom-in 20s ease-in-out",
            flexShrink: 0,
          }}
          draggable="false"
          unselectable="on"
        />
        <JumbotronOverlay page={0} specialOverlay={false} />
        <JumbotronTitle title="Gaze Naturale" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "2500px",
          position: "relative",
          "@media (max-width: 1500px)": { height: "auto" },
        }}
      >
        <Box sx={{ width: "100%", height: `calc(17%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              component={"img"}
              src={consultaconsum}
              height={"250px"}
              width={"380px"}
              alt={"tarife3"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Clienți Casnici
                </Typography>
              </Box>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "25px" }}
              >
                În exclusivitate pentru tine, am creat o secțiune specială unde
                poți găsi informații esențiale despre drepturile tale, sfaturi
                practice pentru reducerea costurilor la factura de energie
                electrică, precum și detalii despre plafonare și alte aspecte
                relevante.
              </Typography>
              <Button
                variant={"contained"}
                onClick={() => navigateToPage(navigate, "/clienti-casnici")}
                sx={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  color: "white",
                  fontFamily: "Catesque",
                }}
              >
                Vezi
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: `calc(25%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              flexDirection: "column",
              gap: "80px",
              "@media (max-width: 1500px)": {
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "6px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Utile
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={furnizor}
                    title={"Actualizează-ți Informațiile"}
                    text={`Înnoiește rapid și în siguranță datele personale pentru a fi mereu la curent cu cele mai recente informații.`}
                    location={"/contact"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={plafonare}
                    title={"Eficiența Utilizării Gazelor Naturale"}
                    text={`Consultă sfaturile noastre despre eficiența gazelor naturale și descoperă modalități de a economisi la factura ta de gaze.`}
                    location={"/contact"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <CardReview
                    image={facturaelec}
                    title={"Factura prin E-mail"}
                    text={`Cu ajutorul facturii electronice, scapi de haosul hârtiilor și ai acces oriunde la factura ta de gaze natural.`}
                    location={"/contact"}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: `calc(20%)`, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "60%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  mb: "35px",
                }}
              >
                <Box
                  sx={{
                    width: "7px",
                    height: "50px",
                    backgroundColor: "#0054a6",
                    mr: "15px",
                  }}
                />
                <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                  Clienți Business
                </Typography>
              </Box>
              <Typography fontFamily={"Catesque"} fontSize={"1rem"}>
                Te invităm să explorăm împreună modalitățile prin care putem
                contribui la creșterea afacerii tale.
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                sx={{ mb: "25px" }}
              >
                Cu o abordare personalizată, îți oferim soluții adaptate
                nevoilor specifice ale companiei tale, însoțite de informații
                legislative actualizate, clarificări privind plafonarea și alte
                sfaturi utile pentru a-ți maximiza potențialul și a obține
                succesul dorit în piață.
              </Typography>
              <Button
                variant={"contained"}
                onClick={() => navigateToPage(navigate, "/clienti-business")}
                sx={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  color: "white",
                  fontFamily: "Catesque",
                }}
              >
                Vezi
              </Button>
            </Box>
            <Box
              component={"img"}
              src={clientibusiness}
              height={"325px"}
              width={"550px"}
              alt={"tarife1"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: `calc(43%)`,
            background: "#dee2e6",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "70%",
              margin: "0 auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "80px",
              "@media (max-width: 1500px)": {
                flexDirection: "column",
                gap: "15px",
              },
            }}
          >
            <NewsSection background={true} />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
