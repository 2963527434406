import { Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import legal from "../assets/legal.jpg";

export const ContOnline: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          mb: "10px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Cont Online
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px" }}
            >
              Ce beneficii am dacă îmi creez un cont online?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Dacă îți creezi un cont online, ai acces rapid la facturile tale,
              istoricul de consum și sold.
            </Typography>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px", mt: "15px" }}
            >
              Cum mă pot înregistra?
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Îți explicăm mai jos pas cu pas cum trebuie să te înregistrezi în
              mai puțin de câteva minute:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mr: "25px",
                gap: "10px",
                mb: "40px",
              }}
            >
              <Typography fontSize="1rem" style={{ fontFamily: "Catesque" }}>
                1. Apasă pe butonul de înregistrare
              </Typography>
              <Typography fontSize="1rem" style={{ fontFamily: "Catesque" }}>
                2. Completează formularul cu datele tale personale
              </Typography>
              <Typography fontSize="1rem" style={{ fontFamily: "Catesque" }}>
                3. Acceptă termenii și condițiile/acordul cu privire la prelucrarea datelor cu caracter personal.
              </Typography>
              <Typography fontSize="1rem" style={{ fontFamily: "Catesque" }}>
                4. Vei primi pe email o parolă temporară pentru a intra în cont. 
              </Typography>
              <Typography fontSize="1rem" style={{ fontFamily: "Catesque" }}>
                5. Intră in cont folosind emailul si parola temporară primită.
              </Typography>
              
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.1rem"}
              fontWeight={"bold"}
              sx={{ mb: "15px", whiteSpace: "nowrap" }}
            >
              Adăugarea locului/locurilor tale de consum, te ajută să obții
              informații legate de facturile ta, sold și istoricul indexului
              tău.
            </Typography>
          </>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={legal}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
