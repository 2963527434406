import { Box, Typography } from "@mui/material";

export const JumbotronTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        textAlign: "center",
        "@media (max-width: 1000px)": {
          display: "none",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        fontFamily={"Catesque"}
        color="white"
      >
        {title}
      </Typography>
    </Box>
  );
};
