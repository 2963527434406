import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { LandingPageMenu } from "./LandingPageMenu";
import logo2 from "../assets/logo2.png";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { pagesNormal } from "../lunrjs/dataAdmin";

import { navigateToPage } from "../utils";

type Anchor = "top" | "left" | "bottom" | "right";

export const JumbotronOverlay = (props: {
  page: number;
  specialOverlay: boolean;
}) => {
  const { page, specialOverlay } = props;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<typeof pagesNormal>([]);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsBoxVisible(!isBoxVisible);
    setIsOpen(!isOpen);
  };

  const handleSearch = () => {
    if (query !== "" && query !== " ") {
      const matchedPages = pagesNormal.filter((page) =>
        page.content.toLowerCase().includes(query.toLowerCase())
      );

      setResults(matchedPages);
    } else {
      setResults([]);
    }
  };
  const clickButton = (url: string) => {
    navigateToPage(navigate, url);
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    // Remove the code that toggles the drawer here
    handleSearch();
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 500 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "3%",
          paddingTop: "13px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            type="text"
            value={query}
            size="small"
            onChange={handleSearchInputChange}
            placeholder="Caută..."
            sx={{
              "& .MuiInputBase-input": {
                color: "black", // Text color
                fontFamily: "Catesque", // Font family
              },
              "& input:disabled": {
                color: "black", // Text color
                fontFamily: "Catesque", // Font family
                WebkitTextFillColor: "black",
              },
              "& .MuiInputLabel-root": {
                color: "black", // Label color
                fontFamily: "Catesque", // Font family
                fontSize: "18px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#0054a6", // Border color
                },
                "&:hover fieldset": {
                  borderColor: "#0054a6", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0054a6", // Focused border color
                },
              },
            }}
          />
          <Typography
            fontFamily={"Catesque"}
            component={"button"}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            sx={{ color: "#0054a6" }}
          >
            Închide
          </Typography>
        </Box>
        <List>
          {query &&
            results.length > 0 &&
            results.map((page) => (
              <ListItem>
                <ListItemButton
                  key={page.id}
                  onClick={() => {
                    clickButton(page.selectedTab);
                  }}
                  sx={{ color: "#0054a6", fontFamily: "Catesque" }}
                >
                  {page.displayName}
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: specialOverlay ? "20%" : "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="overlay-logo"
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              padding: "10px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => navigateToPage(navigate, "/")}
          >
            <img
              height="100px"
              src={logo2}
              alt="logo2"
              className="overlay-logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            right: 0,
            top: 10,
          }}
        >
          <IconButton
            onClick={() => navigateToPage(navigate, "/login")}
            sx={{
              height: "30px",
              mr: "10px",
            }}
          >
            <PermIdentityIcon
              sx={{
                width: "30px",
                height: "35px",
                color: "#0054a6",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            sx={{
              height: "30px",
              mr: "10px",
            }}
          >
            <SearchIcon
              sx={{
                width: "30px",
                height: "35px",
                color: "#0054a6",
              }}
            />
          </IconButton>
          <Drawer
            anchor={"right"}
            open={isDrawerOpen}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
          <a
            id="menu-icon"
            className={isOpen ? "close" : ""}
            onClick={handleClick}
            style={{ zIndex: isOpen ? 2 : 1 }}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </a>
        </Box>
      </Box>
      {specialOverlay === false && (
        <Box sx={{ width: "178px", mt: "4vh", pl: "25px", textAlign: "left" }}>
          <Stack direction="column" spacing={5}>
            {[
              "Clienți Casnici",
              "Clienți Business",
              "Servicii Online",
              "Consultare Consum",
            ].map((buttonText, index) => (
              <AnimatedButton
                key={buttonText}
                variant="text"
                sx={{
                  color: page === index + 1 ? "#0075e8" : "white",
                }}
                onClick={() =>
                  clickButton(
                    `/${buttonText
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`
                  )
                }
              >
                {buttonText}
              </AnimatedButton>
            ))}
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Button
                    sx={{ fontFamily: "Catesque" }}
                    onClick={() => navigateToPage(navigate, "/clienti-casnici")}
                  >
                    Clienți Casnici
                  </Button>
                  <Button
                    sx={{ fontFamily: "Catesque" }}
                    onClick={() =>
                      navigateToPage(navigate, "/clienti-business")
                    }
                  >
                    Clienți Business
                  </Button>
                </React.Fragment>
              }
            >
              <AnimatedButton
                variant="text"
                sx={{
                  color: page === 5 ? "#0075e8" : "white",
                }}
              >
                Informații Utile
              </AnimatedButton>
            </HtmlTooltip>
          </Stack>
        </Box>
      )}
      {isBoxVisible && <LandingPageMenu boxRef={boxRef} isOpen={isOpen} />}
    </Box>
  );
};

const AnimatedButton = styled(Button)({
  "@keyframes flyInFromRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  ":hover": {
    color: "#0075e8",
  },
  fontWeight: "bold",
  color: "white",
  mr: "25px",
  animation: "flyInFromRight 1s ease-out forwards",
  fontFamily: "Arial Black",
  whiteSpace: "nowrap",
  justifyContent: "flex-start",
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
