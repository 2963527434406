import { Box, Typography, Button, styled, SxProps, Theme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { navigateToPage } from "../utils";

// Define the props for NewsCard
interface NewsCardProps {
  sx?: SxProps<Theme>; // This allows you to pass sx props
  date?: string;
  title?: string;
  content?: string;
  id?: number;
}

const StyledBox = styled(Box)<NewsCardProps>(({ theme, ...props }) => ({
  ...props.sx, // Apply the sx prop styles
}));

export const NewsCard: React.FC<NewsCardProps> = (props) => {
  const navigate = useNavigate();
  const [actualContent, setActualContent] = useState<string>("");

  useEffect(() => {
    if (props.content !== undefined) {
      if (props.content.length > 475) {
        setActualContent(props.content.slice(0, 475) + "...");
      } else {
        setActualContent(props.content);
      }
    }
  }, [props.content]);

  const getMarkdownText = (markdownText: string) => {
    const rawMarkup = marked(markdownText) as string;
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  return (
    <StyledBox
      sx={{
        width: "300px",
        height: "600px",
        backgroundColor: "white",
        mr: "5vh",
        borderBottom: "5px solid #0054a6",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
        textAlign: "left",
        alignItems: "center",
        position: "relative",
        transition: "transform 0.2s", // Add a smooth transition on hover
        "&:hover": {
          transform: "scale(1.05)", // Grow by 5% on hover
        },
        "@media (max-width: 1200px)": {
          mr: "unset",
        },
        ...props.sx,
      }}
    >
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          fontWeight: "bold",
          color: "#0054a6",
          padding: "25px",
          display: "flex",
          ":hover": {
            cursor: "pointer",
          },
        }}
      >
        Noutați
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ ml: "5px", color: "gray" }}
        >
          {`| ${props.date}`}
        </Typography>
      </Typography>
      <Typography
        variant="body1"
        component="div"
        sx={{
          pl: "25px",
          pr: "25px",
          fontWeight: "bold",
          pb: "25px",
          letterSpacing: "0px",
        }}
      >
        {props.title}
      </Typography>
      <Box
        component="div"
        dangerouslySetInnerHTML={getMarkdownText(actualContent)}
        sx={{
          pl: "25px",
          pr: "25px",
        }}
      />

      <Button
        variant="contained"
        sx={{ margin: "25px", position: "absolute", bottom: 0 }}
        onClick={() => {
          navigateToPage(navigate, "/noutati/" + props.id);
        }}
      >
        Detalii
      </Button>
    </StyledBox>
  );
};
