import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import {
  Box,
  Snackbar,
  Alert,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useState, useCallback } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MarkdownEditor from "react-markdown-editor-lite";
import { AnuntService } from "../../services/AnuntService";
import { marked } from "marked";
import "react-markdown-editor-lite/lib/index.css";
import "./AdminNoutati.css";

const defaultValues = {
  titleValue: "",
  contentValue: "",
  pictureValue: undefined,
};

const validationSchema = yup
  .object({
    titleValue: yup.string().required("Titlul trebuie completat!"),
    contentValue: yup.string().required("Conținutul trebuie completat!"),
    pictureValue: yup.string().optional(),
  })
  .required();

interface IFormInput {
  titleValue: string;
  contentValue: string;
  pictureValue: string | undefined;
}

export const AdminNoutati: React.FC = () => {
  const { handleSubmit, reset, control } = useForm<IFormInput>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: defaultValues,
  });

  const [file, setFile] = useState<File | null>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const [imageError, setImageError] = useState("");

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  const handleCloseSuccessSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  const renderHTML = useCallback((text: string) => {
    return marked(text);
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      // Check the file type
      if (!selectedFile.type.startsWith("image/")) {
        alert("File is not an image.");
        return;
      }
      // Check the file size
      if (selectedFile.size > 5242880) {
        // 5 MB in bytes
        alert("File size exceeds 5MB.");
        return;
      }
      setFile(selectedFile);
    }
  };

  const onSubmit = async (data: IFormInput) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Image = reader.result?.toString().split(",")[1]; // we only want the Base64 part, not the preceding MIME type
        if (base64Image) {
          AnuntService.createAnunt({
            title: data.titleValue,
            content: data.contentValue,
            picture: base64Image,
          })
            .then((res) => {
              if (!res) {
                setOpenErrorSnackbar(true);
              }
              setOpenSuccessSnackbar(true);
              reset({
                titleValue: "",
                contentValue: "",
                pictureValue: undefined,
              });
              setFile(null);
            })
            .catch((error) => {
              console.error("Error creating noutate", error);
              setOpenErrorSnackbar(true);
              setImageError("");
            });
        }
      };
    } else {
      AnuntService.createAnunt({
        title: data.titleValue,
        content: data.contentValue,
      })
        .then((res) => {
          if (!res) {
            setOpenErrorSnackbar(true);
          }
          setOpenSuccessSnackbar(true);
          reset({
            titleValue: "",
            contentValue: "",
            pictureValue: undefined,
          });
          setFile(null);
        })
        .catch((error) => {
          console.error("Error creating noutate", error);
          setOpenErrorSnackbar(true);
          setImageError("");
        });
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridRowGap: "20px",
        padding: "50px",
        width: "1200px",
        minHeight: "400px",
        bgcolor: "background.paper",
        boxShadow: 24,
        textAlign: "center",
      }}
    >
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
        sx={{ position: "absolute", bottom: 0, left: "50%", padding: "20px" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Ai trimis mai mult de 2 citiri in ultima lună!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSuccessSnackbar}
        sx={{ position: "absolute", bottom: 0, left: "50%", padding: "20px" }}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Citire înregistrată!
        </Alert>
      </Snackbar>
      <Typography variant="h4" fontFamily="Catesque" sx={{ mb: "5vh" }}>
        Creează Noutate
      </Typography>

      <Controller
        name={"titleValue"}
        control={control}
        render={({
          field: { onChange, ...props },
          fieldState: { error },
          formState,
        }) => (
          <TextField
            {...props}
            required
            name={"titleValue"}
            id={"titleValue"}
            size={"small"}
            label={"Titlu"}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            sx={{
              "& .MuiInputBase-input": {
                color: "black", // Text color
                fontFamily: "Catesque", // Font family
              },
              "& input:disabled": {
                color: "black", // Text color
                fontFamily: "Catesque", // Font family
                WebkitTextFillColor: "black",
              },
              "& .MuiInputLabel-root": {
                color: "black", // Label color
                fontFamily: "Catesque", // Font family
                fontSize: "18px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#0054a6", // Border color
                },
                "&:hover fieldset": {
                  borderColor: "#0054a6", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0054a6", // Focused border color
                },
              },
            }}
          />
        )}
        defaultValue={""}
      />
      <Controller
        name={"contentValue"}
        control={control}
        render={({
          field: { onChange, value, ...props },
          fieldState: { error },
          formState,
        }) => (
          <MarkdownEditor
            {...props}
            name={"contentValue"}
            value={value || ""}
            id={"contentValue"}
            style={{ height: "200px" }}
            onChange={(data) => {
              onChange(data.text);
            }}
            renderHTML={renderHTML}
          />
        )}
        defaultValue={""}
      />
      <Controller
        name={"pictureValue"}
        control={control}
        render={({
          field: { onChange, ...props },
          fieldState: { error },
          formState,
        }) => (
          <Button
            component="label"
            variant="text"
            startIcon={<CloudUploadIcon />}
            sx={{
              width: "50%",
              color: "#0054a6",
              margin: "0 auto",
              display: "flex",
            }}
          >
            Incarca Imagine
            <VisuallyHiddenInput
              {...props}
              onChange={(e) => {
                handleFileChange(e);
                onChange(e);
              }}
              value={""}
              type="file"
            />
          </Button>
        )}
        defaultValue={undefined}
      />
      {imageError !== "" && (
        <Typography color="error" fontFamily={"Catesque"}>
          {imageError}
        </Typography>
      )}

      {file && (
        <Box sx={{ margin: "0 auto", width: "50%" }}>
          <img
            alt="contor"
            src={URL.createObjectURL(file)}
            width={"200px"}
            height={"200px"}
          />
        </Box>
      )}

      <Button
        onClick={handleSubmit(onSubmit)}
        variant={"contained"}
        sx={{ backgroundColor: "#0054a6", fontFamily: "Catesque" }}
      >
        Înregistrează Noutate
      </Button>
      <Button
        onClick={() => {
          reset({
            titleValue: "",
            contentValue: "",
            pictureValue: undefined,
          });
          setFile(null);
          setImageError("");
        }}
        variant={"outlined"}
        sx={{
          borderColor: "#0054a6",
          color: "#0054a6",
          fontFamily: "Catesque",
        }}
      >
        Resetare
      </Button>
    </Box>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
