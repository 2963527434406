import { Box, Typography, Divider } from "@mui/material";
import React, { useState } from "react";
import legal from "../assets/legal.jpg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";

export const Contractare: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (hover: boolean) => {
    setIsHovered(hover);
  };

  const navigate = useNavigate();

  const handleDownload = () => {
    const url = process.env.PUBLIC_URL + "/declaratie.docx";
    const a = document.createElement("a");
    a.href = url;
    a.download = "Declaratie Proprie Raspundere - EURO SEVEN INDSUTRY.docx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#f2f2f2",
          padding: "20px",
          borderRadius: "25px",
          mb: "10px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            textAlign: "left",
            justifyContent: "left",
            gap: "10px",
            "@media(max-width: 1300px)": {
              width: "100%",
              padding: "25px",
              mt: "25px",
            },
          }}
        >
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mb: "35px",
              }}
            >
              <Box
                sx={{
                  width: "6px",
                  height: "50px",
                  backgroundColor: "#0054a6",
                  mr: "15px",
                }}
              />
              <Typography fontFamily={"Catesque"} fontSize={"2rem"}>
                Contractare
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              fontStyle={"italic"}
              fontWeight={"bold"}
              sx={{ mb: "25px" }}
            >
              Încheierea Contractului de Furnizare Gaze Naturale
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              sx={{ mb: "15px" }}
            >
              A. Pași pentru Încheierea Contractului
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Indiferent de tipul tău de client (casnic sau non-casnic), îți
              oferim modalități convenabile de a încheia contractul de furnizare
              a gazelor naturale:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Online: Rapid și simplu, direct de pe platforma noastră.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography
                fontFamily={"Catesque"}
                component="a"
                href={"/clienti-casnici"}
                fontSize={"1rem"}
                color={"blue"}
                sx={{ textDecoration: "none" }}
              >
                Client Casnic
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                component="a"
                href={"/clienti-business"}
                fontSize={"1rem"}
                color={"blue"}
                sx={{ textDecoration: "none" }}
              >
                Client Business
              </Typography>
            </Box>

            <Box sx={{ display: "flex", mb: "25px" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                component="span"
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Vizitând unul dintre
                <span
                  onClick={() => (navigate("/contact"))}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  punctele noastre de lucru.
                </span>
              </Typography>
            </Box>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.2rem"}
              sx={{ mb: "15px" }}
            >
              B. Documente Necesare
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Pentru a finaliza contractul, te rugăm să pregătești:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                mb: "15px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Copie a actului de identitate.
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                  component={"span"} // Make Typography act as an inline element
                >
                  Declarația pe propria răspundere din care să rezulte în ce
                  calitate folosești imobilul pentru care faci contractul sau
                  actul de proprietate al acestuia. Poți găsi modelul de
                  declarație{" "}
                  <span
                    onClick={handleDownload}
                    style={{
                      color: "#0054a6",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    aici.
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Împuternicire notarială și copie buletin titular contract
                  (dacă nu ești titularul contractului).
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Fotografie clară a contorului de gaze, cu seria și indexul
                  vizibile.
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
                <Typography
                  fontFamily={"Catesque"}
                  fontSize={"1rem"}
                  color={"black"}
                >
                  Codul locului de consum (CLC), disponibil pe factura de gaze.
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              fontWeight={"bold"}
              fontStyle={"italic"}
              sx={{ mt: "10px", mb: "10px" }}
            >
              Transcriere Contract (Schimbare Titularitate)
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "15px" }}
            >
              Dacă ai nevoie să schimbi titularul contractului (de exemplu, ca
              urmare a unei cumpărări, moșteniri etc.), vei încheia un contract
              nou:
            </Typography>
            <Box sx={{ display: "flex" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Online: Rapid și simplu, direct de pe platforma noastră.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Typography
                fontFamily={"Catesque"}
                component="a"
                href={"/clienti-casnici"}
                fontSize={"1rem"}
                color={"blue"}
                sx={{ textDecoration: "none" }}
              >
                Client Casnic
              </Typography>
              <Typography
                fontFamily={"Catesque"}
                component="a"
                href={"/clienti-business"}
                fontSize={"1rem"}
                color={"blue"}
                sx={{ textDecoration: "none" }}
              >
                Client Business
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mb: "25px" }}>
              <CheckBoxIcon sx={{ color: "green", mr: "10px" }} />
              <Typography
                component="span"
                fontFamily={"Catesque"}
                fontSize={"1rem"}
                color={"black"}
              >
                Vizitând unul dintre
                <span
                  onClick={() => (navigate("/contact"))}
                  style={{
                    color: "#0054a6",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  punctele noastre de lucru.
                </span>
              </Typography>
            </Box>
            <Divider />
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1.5rem"}
              fontWeight={"bold"}
              fontStyle={"italic"}
              sx={{ mb: "10px", mt: "10px" }}
            >
              Documente Necesare pentru Transcriere
            </Typography>
            <Typography
              fontFamily={"Catesque"}
              fontSize={"1rem"}
              sx={{ mb: "10px" }}
            >
              Documentația necesară pentru încheierea unui nou contract este
              identică cu cea menționată anterior.
            </Typography>
          </>
        </Box>
        <Box
          onMouseOver={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          sx={{
            position: "relative",
            width: "550px",
            height: "475px",
            display: "inline-block",
            transition: "width 0.5s ease",
          }}
        >
          <Box
            component="img"
            src={legal}
            alt="plafonare"
            sx={{
              width: "100%",
              height: "100%",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isHovered ? "100%" : "40%",
              borderTop: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "width 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: isHovered ? "100%" : "40%",
              borderRight: "5px solid #0054a6",
              boxSizing: "border-box",
              transition: "height 0.5s ease",
              "@media(max-width: 1300px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
